// Just a starter for each lesson

import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import api from '../../../utils/api';


const Abstinence = ({Slug, Day}) => {
    const history = useHistory();
    const title = 'Abstinence Type';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const abTypes = [
        {
            'type': 'easy',
            'title': `Game-Free Mode: Abstaining from Games`,
            'class': `has-text-success`,
            'btnclass': `is-success`
        },
        {
            'type': 'medium',
            'title': `Games and Streams Mode: Abstaining from Games and Streams`,
            'class': `has-text-info`,
            'btnclass': `is-info`
        },
        {
            'type': 'hard',
            'title': `Hard Mode: Abstaining from all recreational tech`,
            'class': `has-text-primary`,
            'btnclass': `is-primary`
        },
    ];

    const journals = [
        {
            'title': 'I am choosing this mode over the other ones because...',
            'value': ''
        },
        {
            'title': 'Now that I have chosen a mode I am feeling...',
            'value': ''
        },
    ]

    const [data, setData] = useState({
        answers: {
            selectedMode: null,
            journals: journals
        }
    });



    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
          }, (err) => {
            console.log(err)
          });
    }, []);

    const handleChange = (e) => {

        setUi({
            ...ui,
            saved: false
        })

        let index = e.target.dataset.idx;
        let value = e.target.value;

        let newArr = data.answers.journals;

        newArr[index].value = value;

        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: newArr
            }
        });
  
    };

    const selectMode = (e) => {

        setUi({
            ...ui,
            saved: false
        })
        
        setData({  
            ...data,
            answers: {
                ...data.answers,
                selectedMode: e.target.dataset.mode

            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if(done === false){

            let complete = {
              id: id,
              completed: true,
              timestamp: new Date()
            }
            console.log(complete);
        
            api.post(`/lessons/complete`, complete).then((res) => {
              console.log(res)
                history.push('/lessons/done')
        
            }, (err) => {
              console.log(err)
            });
            
        
          } else {
              console.log('ok');
            history.push('/lessons/done')
          };

    }


    const content = () => {

        return (
            <>
    <p>Welcome back to Respawn.</p>
        <p>So far we have identified the reasons why you like to play games, your motivations to take a break and the goals you will work to achieve. As you get closer to the day when you will begin your 30-day break from gaming, it&#39;s important that you define the parameters of what a &#39;gaming break&#39; means to you.</p>
        <p>As we shared yesterday, excessive gaming can <em>rewire the brain</em> in problematic ways, and research shows it takes about 90 days for these changes to reverse. Most users who take a break for 30-days experience benefits and that is why we will begin here.</p>
        <p>In the same way, it will take a different amount of time for some users to reduce their symptoms of problematic gaming, some users will be more sensitive to different aspects of gaming and technology. That is why we have created three different &#39;modes&#39; for you to choose from, including a recommended mode to start.</p>
        <blockquote>
          <p>Remember that this intentional 30-day break from gaming is an
          opportunity for you to build new habits and experience more of the
          life you desire. Choose the mode that will best support you and your
          long-term vision. You can always change or adjust your mode another
time. Our general recommendation is the Games and Streams Mode.</p>
        </blockquote>
        <h2 className="has-text-success" id="games-free-mode-abstaining-from-games">Games-Free Mode: Abstaining from Games</h2>
        <p>In this mode you will abstain from playing video games for at least 30-days. This includes all forms of gaming: PC, console, mobile and VR, and types of games: single-player and multi-player. You will also abstain from social and casual gaming (in-person or online) with friends and/or family.</p>
        <p>If you choose this mode you may allow yourself to still watch online content (including gaming streams), interact with gaming friends, and use other technology such as social media.</p>
        <p>Users who choose Games-Free Mode should be cautious of potential triggers, especially watching gaming streams, interacting with unsupportive gamer friends, and paying attention to gaming news.</p>
        <blockquote>
          <p>It is also recommended to limit or eliminate any substance-use during
your 30-day break from gaming.</p>
        </blockquote>
        <h2 className="has-text-info" id="games-and-streams-mode-abstaining-from-games-and-streams-recommended-">Games and Streams Mode: Abstaining from Games and Streams (Recommended!)</h2>
        <p>In this mode you will abstain from playing video games for at least 30-days. This includes all forms of gaming: PC, console, mobile and VR, and types of games: single-player and multi-player. This mode includes abstaining from social gaming (in-person or online) with friends and/or family and not interacting with unsupportive gaming friends and/or communities such as Discord, subreddits, Twitch, and Mixer.</p>
        <p>If you choose this mode you may allow yourself to still watch online (non-gaming) content such as YouTube, and use other technology such as social media.</p>
        <p>It is recommended to find new ways to interact with supportive gaming friends such as using WhatsApp or Skype.</p>
        <blockquote>
          <p>It is also recommended to limit or eliminate any substance-use during
your 30-day break from gaming.</p>
        </blockquote>
        <h2 className="has-text-primary" id="hard-mode-abstaining-from-all-recreational-tech">Hard Mode: Abstaining from all recreational tech</h2>
        <p>In this mode you will abstain from playing video games and recreational tech for at least 30-days. This includes all forms of gaming: PC, console, mobile and VR, and types of games: single-player and multi-player. It also includes abstaining from social gaming (in-person or online) with friends and/or family, gamified apps such as Tinder and DuoLingo, and not interacting with unsupportive gaming friends and/or communities such as Discord, subreddits, Twitch, and Mixer.</p>
        <p>If you choose this mode you will abstain from watching online content such as YouTube and Netflix, and will not listen to gaming soundtracks or gaming music.</p>
        <p>You will abstain from all recreational tech use (any tech you use for entertainment purposes such including PC, mobile and consoles) and only use tech as necessary to complete homework or work-related tasks.</p>
        <blockquote>
          <p>It is also recommended to limit or eliminate any substance-use during
your 30-day break from gaming.</p>
        </blockquote>

        <p><strong>Action step: Please complete the following questionnaire.</strong></p>

        <blockquote>
        {
            abTypes.map((item, index) => (
                <div className="mt-3" key={index}>
                   <button onClick={selectMode} data-mode={item.type} className={`button has-text-weight-bold ${item.btnclass} ${data.answers.selectedMode === item.type ? `` : `is-outlined`}`}>{item.title}</button>
                </div>
            ))
        }
        </blockquote>

        {
           data.answers.selectedMode !== null ?
           <>
            {data.answers.journals.map((item, index) => (
            <div key={index} className="field">
            <label className="label">{item.title}</label>
            <div className="control">
              <textarea data-idx={index} onChange={handleChange} className="textarea" value={item.value}></textarea>
            </div>
          </div>
           ))}

<blockquote className="mt-4">
              <p>Great job. As you wrap up today, it is important to remember that this
              is all a learning process and you may need to make adjustments as you
              go along. If, at any time, you are struggling with cravings to play,
              or finding the process of abstaining to be stressful, or you have
              relapsed, use our Emergency Button for next steps. We recommend to
              journal each day about your experience, and you can also contact us
              for support.
              </p>
            </blockquote>
{/* 
            {
                ui.saved === false ?
                <button onClick={ClickSubmit} className="button is-primary">Save Changes</button>
                :
                null
            } */}
              <button disabled={ui.saved} onClick={ClickSubmit} className="button is-primary">Save Changes</button>

              <br />
              {
                  ui.saved === true ?

                  <button className="button is-primary mt-3" onClick={FinishLesson}>Finish Lesson</button>

                  :
                  null

              }

           </>
           :
           <>
           </>
        }

            </>
        )
    }

    return (
        <>
        <LessonLayout Slug={Day} Title={title}>

        <div className="content content-text">
        {content()}

    </div>

        </LessonLayout>
        </>
    )

}

export default Abstinence;