import React from 'react';
import Logo from '../static/gq_logo_full.png';
import {Link} from 'react-router-dom';

const NotFound = () => {


    return (
        <section className="hero is-light is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5">
                <div className="has-text-centered">
                  <img className="mb-4" style={logoStyle} src={Logo} />
                  <p className="title">Oops, you hit a 404.</p>
                  <Link className="button is-primary" to='/'>Go back home</Link>
                </div>
                </div>
                </div>
                </div>
                </div>
                </section>
    )
}

const logoStyle = {
    maxWidth: "300px"
  }

export default NotFound;