import React, {useState, createRef} from 'react';
import API from '../../../utils/api';

const PictureUploads = ({ State, Dispatch }) => {

    const [uploadingPP, setUploadingPP] = useState(false);
    const [uploadingCP, setUploadingCP] = useState(false);

    const fileRef = createRef();

    const fileSelectHandler = (e) => {
        setUploadingPP(true);
        console.log('handler triggered');
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        API.post('/updateProfilePic',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                Dispatch({
                    type: 'UPDATEACCOUNT',
                    payload: response.data
                });
                setUploadingPP(false);




            }, (error) => {

                console.log(error)

                setUploadingPP(false);

            });
    };

    const fileSelectClick = () => {
        console.log('click triggered');
        fileRef.current.click();
    }


    // cover upload

    const CoverfileRef = createRef();

    const CoverfileSelectHandler = (e) => {
        setUploadingCP(true);
        console.log('handler triggered');
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        API.post('/updateCoverPic',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                Dispatch({
                    type: 'UPDATEACCOUNT',
                    payload: response.data
                });
                setUploadingCP(false);




            }, (error) => {

                console.log(error)

                setUploadingCP(false);

            });
    };

    const CoverfileSelectClick = () => {
        console.log('click triggered');
        CoverfileRef.current.click();
    }



    return (
        <>
        
                    <div className="field">
                <label className="label">Profile Picture</label>
                <div className="profile-pic-settings light-shadow" style={{ backgroundImage: `url(${State.user.profile.profilepic.small})` }}>

                </div>
                <br />
                <div className="pointer">
                    <input onChange={fileSelectHandler} ref={fileRef} className="file-input" type="file" name="resume" hidden={true} />
                    {
                        uploadingPP ?
                        <span className="has-text-weight-bold has-text-danger pointer">Upload in progress..</span>
                        :
                        <span onClick={fileSelectClick} className="has-text-weight-bold has-text-link pointer">Change profile picture</span>
                    }
                </div>

            </div>

<hr />

            <div className="field">
            <label className="label">Cover Picture</label>
                <div className="cover-pic-settings light-shadow" style={{ backgroundImage: `url(${State.user.profile.coverImg})` }}>
                </div>
                <br />
                <div className="pointer">
                    <input onChange={CoverfileSelectHandler} ref={CoverfileRef} className="file-input" type="file" name="resume" hidden={true} />
                    {
                        uploadingCP ?
                        <span className="has-text-weight-bold has-text-danger pointer">Upload in progress..</span>
                        :
                        <span onClick={CoverfileSelectClick} className="has-text-weight-bold has-text-link pointer">Change cover picture</span>
                    }
                </div>

            </div>
        </>
    )
}

export default PictureUploads;
