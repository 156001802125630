// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';


const Replacement = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Finding Replacement Activities';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const ActivitiesJournals = [
        {
            'title': 'The first step to choosing new activities is to brainstorm what they could possibly be. What did you enjoy doing when you were younger? What skills are you interested in learning? What is on your “bucket list”?',
            'value': ''
        },
        {
            'title': 'Next, what could you do when you’re tired and bored at home? What are some of your interests? What is something creative you’re interested in learning?',
            'value': ''
        },
        {
            'title': 'Next, what could you do when you’re tired and bored at home? What are some of your interests? What is something creative you’re interested in learning?',
            'value': ''
        },
    ];

    const GotoActitivies = [
        {
            'title': 'To be mentally engaged my go-to activity will be:',
            'value': ''
        },
        {
            'title': 'When I’m tired my go-to activity will be:',
            'value': ''
        },
        {
            'title': 'To make more friends my go-to activity will be:',
            'value': ''
        },
        {
            'title': 'When I feel bored my go-to activity will be:',
            'value': ''
        },
        {
            'title': 'When I’m feeling stressed my go-to activity will be:',
            'value': ''
        }
    ];

    const Backups = [
        {
            'title': 'Mentally Engaged Activity:',
            'value': ''
        },
        {
            'title': 'Resting Activity:',
            'value': ''
        },
        {
            'title': 'Social Activity:',
            'value': ''
        },

    ];

    const [data, setData] = useState({
        answers: {
            activitiesJournals: ActivitiesJournals,
            GotoActitivies: GotoActitivies,
            Backups: Backups,
            Alternatives: ''
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleChange = (e) => {
        setUi({
            ...ui,
            saved: false
        });
        let index = e.target.dataset.idx;
        let value = e.target.value;
        let section = e.target.dataset.section;

        if (section === 'activitiesJournals') {
            let newArr = data.answers.activitiesJournals;
            newArr[index].value = value;
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    activitiesJournals: newArr,
                }
            })
        };

        if (section === 'GoTo') {
            let newArr = data.answers.GotoActitivies;
            newArr[index].value = value;
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    GotoActitivies: newArr
                }
            })
        };

        if (section === 'Backups') {
            let newArr = data.answers.Backups;
            newArr[index].value = value;
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    Backups: newArr
                }
            })
        };

        if (section === 'Alternatives') {

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    Alternatives: value
                }
            })
        };

    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                // history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            // history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>When you stop playing video games you create a void in your life because gaming tends to occupy a large percentage of your free time. So today we need to choose new activities to replace gaming with and fill that free time, otherwise you’ll be bored at home and that may increase your craving to play.</p>
                <p>The key in finding new activities right now is to focus on finding replacement for the needs that gaming fulfilled for you, and then schedule these proactively throughout your day. One reason why gaming is so compelling is that it fulfils many needs through one activity — you can escape, be social and be competitive in the same activity for 15 hours straight — whereas other activities can be more limited.</p>
                <p>To counter this, find multiple activities to replace gaming. Focus on these three categories:</p>
                <p><strong>1. Mentally Engaging Activity</strong></p>
                <p>Games are mentally engaging due to their instant gratification. That’s why even though you only intend to play one game you end up playing many. So you will need to find at least one activity that is stimulating and immersive. Think of it like a new skill to develop.</p>
                <p><strong>Top Suggestions:</strong> Working out, coding, learning a new language or instrument, starting a side business.</p>
                <p><strong>2. Resting Activity</strong></p>
                <p>When you’re tired from the day what do you do? You game. This typically happens when you get home from work or school and you’ve met your obligations for the day. Maybe you don’t even have the energy to really focus on anything else, especially not a mentally engaging activity. So instead you will need to find activities that requires a low investment of energy.</p>
                <p><strong>Top Suggestions:</strong> Podcasts, Reading, Yoga, Cooking, Photography</p>
                <p>Note: Mindless browsing does not count as a positive resting activity. 😜</p>
                <blockquote>
                    <p>“I’ve picked up great habits. I journal, meditate, read, and try to be supportive of others as they quit. My anxiety has been getting better, and my attention span has been increasing. I feel more engaged in the world around me.”– Anonymous</p>
                </blockquote>
                <p><strong>3. Social Activity</strong></p>
                <p>Games are social and that means when you stop gaming you may experience changes to your social life. We will cover this more in-depth in a day to come, but for now you will need to find new social activities.</p>
                <p><strong>Top Suggestions:</strong> Volunteering, Meetups, Toastmasters, Dance Class</p>
                <blockquote>
                    <p>&quot;I decided to finally attend a Toastmasters meeting in my city. Something I wanted to do for like a year but never did.” - SB</p>
                </blockquote>
                <p>You can find many more suggestions by using our Hobby Tool</p>
                <p>So right now you will spend a few minutes picking new activities that fill the areas mentioned above. Remember you may need one or you may need a few. This depends on your situation. Try new things and don&#39;t give up on an activity too easily.</p>
                <blockquote>
                    <p>&quot;I didn&#39;t think that I&#39;d be interested in a lot of things from the 60+ hobby ideas list and many other activities, but when I finally said to myself &quot;ok, I need to try these or else I won&#39;t know for sure if I&#39;ll enjoy them&quot; I got started and really enjoyed the hobbies I tried out.&quot;</p>
                </blockquote>

                <p><strong>Come up with at least 5 activities for each</strong> (15 minutes)</p>

                {
                    data.answers.activitiesJournals.map((item, index) => (
                        <div className="field">
                            <label className="label">{item.title}</label>
                            <div className="control">
                                <textarea onChange={handleChange} data-section='activitiesJournals' data-idx={index} value={item.value} className="textarea" ></textarea>
                            </div>
                        </div>
                    ))
                }
                <p><p>So those are three different kind of activities that you want to focus on, but you also want to remember that gaming didn’t just fulfill needs you have, but it was your go-to activity during specific situations. When you’re bored, what do you do? You game. When you’re stressed, what do you do? You game. When you want to hang out with your friends, what do you do? You game.</p></p>
                <p><p>Although you will perform to the best of your ability, during this process you are going to experience boredom at times. You are going to be stressed at times. You are going to want to hang out with your old friends. If you don’t consciously choose new activities to do during these specific situations, you will always go to what you know, and what you know is gaming.</p></p>
                <p><p>Gaming was your go-to activity during <em>specific situations</em>, and as you move forward you need to choose not only new activities to do, but also ones for the specific situations you’ll be in. That way all you need to do is follow the system you’ve already created for yourself, instead of trying to make the right decision in that moment.</p></p>
                <blockquote>
                    <p>Note: You may not enjoy every activity right away and it may take time
                    before you develop a passion for it. Passion is developed through
        challenge and experience so don’t give up too quickly.</p>
                </blockquote>

                <p className="has-text-weight-bold">Choose your new “go-to” activity for each situation:</p>

                {
                    data.answers.GotoActitivies.map((item, index) => (
                        <div key={index} className="field">
                            <label className="label">{item.title}</label>
                            <div className="control">
                                <input data-section='GoTo' onChange={handleChange} data-idx={index} className="input" type="text" value={item.value} />
                            </div>
                        </div>
                    ))
                }

                <p>It's also good to have backups. For e.g. if you chose “working out” as your go-to activity whenever you feel stressed out, what happens when you’re stressed and you’ve already been to the gym that day? Having a backup that is different than the gym will be beneficial in this situation.</p>
                <p className="has-text-weight-bold">My backup(s) will be:</p>


                {
                    data.answers.Backups.map((item, index) => (
                        <div key={index} className="field">
                            <label className="label">{item.title}</label>
                            <div className="control">
                                <input data-section='Backups' onChange={handleChange} data-idx={index} className="input" type="text" value={item.value} />
                            </div>
                        </div>
                    ))
                }

                <p>Finally, sometimes you will be doing one activity for awhile and you’ll get tired of it. This is the perfect time to switch to different one. If switching to a new one doesn&#39;t work, change your environment instead!</p>
                <p>If you’re doing one activity in your room, try going outside for the next one, or to another location. Getting out of your room and/or getting out of your house can be a positive step throughout your recovery. Changing environments changes your energy.</p>
                <p>Being out of the house has three other benefits: you’ll be less tempted to game, it will help you meet new people (it’s more common to be in social environments if you’re not at home), and you’ll have less distractions (it’s easier to be productive.)</p>
                <p>The more you’re at home the easier it is for you to go on your computer and either game or just sit around mindlessly browsing the internet. Get out of the house more often, and anytime you’re feeling a bit <em>stuck</em>, change your environment.</p>
                <p className="has-text-weight-bold">Brainstorm the alternative environments you have available nearby:</p>

                <textarea onChange={handleChange} data-section='Alternatives' value={data.answers.Alternatives} className="textarea" ></textarea>

                <br/>
                <button disabled={ui.saved} onClick={ClickSubmit} className="button is-primary">Save Changes</button>

                <br />


                {
                    ui.saved === true ?
                    <Link className="button is-primary mt-2" to='/lessons/de-gaming'>Next</Link>
                    :
                    null
                }


            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}


                </div>

            </LessonLayout>
        </>
    )

}

export default Replacement;