import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import LessonsDefaultState from './LessonsDefaultState';
import {LessonsContext} from '../../context/LessonsContext';
import LoggedLayout from '../../components/LoggedLayout';
import Cover from '../Hub/Cover';
import FinishLesson from './FinishLesson';
import Welcome from './Day1/Welcome';
import Evaluation from './Day1/Evaluation';
import Expectations from './Day2/Expactations';
import Hobbyvsproblem from './Day2/Hobbyvsproblem';
import BenefitsOfNotGaming from './Day3/Benefitsofnotgaming';
import Discovery from './Day5/Discovery';
import Inventory from './Day5/Inventory';
import Whyyouplay from './Day6/WhyyouPlay';
import ProCon from './Day7/procon';
import GamerArchetypes from './Day8/gamerarchetypes';
import LifeAss from './Day9/lifeass';
import MotivationToQuit from './Day10/motivationtoquit';
import Goals from './Day11/goals';
import Abstinence from './Day13/abstinence';
import Replacement from './Day14/replacement';
import Degaming from './Day14/degaming';
import Threshold from './Day15/threshold';
import StumbleReady from './Day15/stumble-ready';

const LessonsRouting = ({State}) => {
    const {state, dispatch} = useContext(LessonsContext);
    let match = useRouteMatch();

    const [showCover, setShowCover] = useState(true);

    useEffect(() => {

        if(State.user.profile.coverImg === null || State.user.profile.coverImg === undefined || State.user.profile.coverImg === ""){

            setShowCover(false);
 
    
        } 

    }, [])

    
    return (
        <>
            <LoggedLayout>
                {
                    showCover ?
                    <Cover CoverImg={State.user.profile.coverImg || "https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2089&q=80"} />
:
null
                }
              
                <Switch>

                <Route path={`${match.path}/done`}>
        <FinishLesson />
</Route>
                    {/* Day1 - DONE */}

                    <Route path={`${match.path}/welcome/:id`}>
                        <Welcome State={state} Dispatch={dispatch} Day='1' Slug='welcome' />
                    </Route>
                    <Route path={`${match.path}/welcome`}>
                        <Welcome State={state} Dispatch={dispatch} Day='1' Slug='welcome' />
                    </Route>
                    <Route path={`${match.path}/evaluation`}>
                        <Evaluation State={state} Dispatch={dispatch} Day='1' Slug='evaluation' />
                    </Route>

                    {/* Day2 - DONE */}
                    <Route path={`${match.path}/expectations`}>
                        <Expectations State={state} Dispatch={dispatch} Day='2' Slug='expectations' />
                    </Route>
                    <Route path={`${match.path}/hobbyvsproblem`}>
                        <Hobbyvsproblem State={state} Dispatch={dispatch} Day='2' Slug='hobbyvsproblem' />
                    </Route>

                    {/* Day3 - DONE */}
                    <Route path={`${match.path}/benefits-of-not-gaming`}>
                        <BenefitsOfNotGaming State={state} Dispatch={dispatch} Day='3' Slug='benefits-of-not-gaming' />
                    </Route>

                    {/* Day4 is break */}

                    {/* Day5 - DONE*/}
                    <Route path={`${match.path}/discovery`}>
                        <Discovery State={state} Dispatch={dispatch} Day='5' Slug='discovery' />
                    </Route>
                    <Route path={`${match.path}/inventory`}>
                        <Inventory State={state} Dispatch={dispatch} Day='5' Slug='inventory' />
                    </Route>

                    {/* Day6 - DONE */}
                    <Route path={`${match.path}/why-you-play`}>
                        <Whyyouplay State={state} Dispatch={dispatch} Day='6' Slug='why-you-play' />
                    </Route>

                     {/* Day7 - DONE */}
                     <Route path={`${match.path}/pro-con`}>
                        <ProCon State={state} Dispatch={dispatch} Day='7' Slug='pro-con' />
                    </Route>
                    {/* Day8 - DONE*/}
                    <Route path={`${match.path}/gamer-archetypes`}>
                        <GamerArchetypes State={state} Dispatch={dispatch} Day='8' Slug='gamer-archetypes' />
                    </Route>

                    {/* Day9 - DONE */}
                    <Route path={`${match.path}/life-assessment`}>
                        <LifeAss State={state} Dispatch={dispatch} Day='9' Slug='life-assessment' />
                    </Route>

                    {/* Day10 - DONE */}
                    <Route path={`${match.path}/motivation-to-quit`}>
                        <MotivationToQuit State={state} Dispatch={dispatch} Day='10' Slug='motivation-to-quit' />
                    </Route>

                    {/* Day11 - DONE */}
                    <Route path={`${match.path}/goals`}>
                        <Goals State={state} Dispatch={dispatch} Day='11' Slug='goals' />
                    </Route>
                    {/* Day12 - BREAK */}

                    {/* Day13 - Done */}
                    <Route path={`${match.path}/abstinence-type`}>
                        <Abstinence State={state} Dispatch={dispatch} Day='13' Slug='abstinence-type' />
                    </Route>

                    {/* Day14 - Done */}
                    <Route path={`${match.path}/finding-replacement-activities`}>
                        <Replacement State={state} Dispatch={dispatch} Day='14' Slug='finding-replacement-activities' />
                    </Route>
                    <Route path={`${match.path}/de-gaming`}>
                        <Degaming State={state} Dispatch={dispatch} Day='14' Slug='de-gaming' />
                    </Route>

                    {/* Day15 - Done */}
                    <Route path={`${match.path}/threshold`}>
                        <Threshold State={state} Dispatch={dispatch} Day='15' Slug='threshold' />
                    </Route>
                    <Route path={`${match.path}/stumble-ready`}>
                        <StumbleReady State={state} Dispatch={dispatch} Day='15' Slug='stumble-ready' />
                    </Route>


                </Switch>
            </LoggedLayout>
        </>
    )
}

export default LessonsRouting;