const Study = [
    {
        'percentage': "+44%",
        'title': "Time Management",
        'emojis':"⏳"
    },
    {
        'percentage': "+29%",
        'title': "Concentration",
        'emojis':"📈"
    },
    {
        'percentage': "+28%",
        'title': "School / Work Performance",
        'emojis':"🏫"
    },
    {
        'percentage': "+27%",
        'title': "Intimate Relationships",
        'emojis':"💘"
    },
    {
        'percentage': "+16%",
        'title': "Family Relationships",
        'emojis':"👨‍👩‍👧‍👦"
    },
    {
        'percentage': "+35%",
        'title': "Relationships with Others",
        'emojis':"🤝"
    },
    {
        'percentage': "+21%",
        'title': "Psycological Health",
        'emojis':"🌱"
    },
    {
        'percentage': "+35%",
        'title': "Optimism",
        'emojis':"😊"
    },
    {
        'percentage': "+18%",
        'title': "Your Apprearance",
        'emojis':"🕺"
    },


]

export default Study;
