import React, { useContext, useState, useEffect } from 'react';
// import Logo from '../../static/gq_logo_full.png';
// import Icon from '../../static/gq_logo.png';
import Logo from '../../static/gq_logo_full.png';
import Icon from '../../static/gq_logo.png'
import { Helmet } from "react-helmet";

import { AuthContext } from '../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useHistory, Link, useParams, Redirect } from 'react-router-dom';

import api from '../../utils/api';

const Reset = () => {

    const params = useParams();
    let secret = params.secret;
    const [cookies, setCookie, removeCookie] = useCookies(['gq']);
    useEffect(() => {
        removeCookie(['gq'])
        api.get(`/reset/${secret}`).then((res) => {
            console.log(res)
            setUI({
                ...ui,
                valid: true,
                loadingPage: false
            })
        }, (err) => {
            console.log(err)
            setUI({
                ...ui,
                valid: false,
                loadingPage: false
            });
        });


    }, []);

    const [ui, setUI] = useState({
        loadingPage: true,
        valid: false,
        sent: false,
        loading: false,
        disabled: false,
        password: '',
        password2: '',
        noMatch: false,
        success: false
    });


    const handleSubmit = async(e) => {

        e.preventDefault();
        setUI({
            ...ui,
            loading: true,
            disabled: true,
        });

        


        if(ui.password === ui.password2 && ui.password.length > 3 && ui.password2.length > 3){
            await api.post(`/reset/${secret}`, {password: ui.password}).then((res, err) => {
                console.log('done')
                setUI({
                    ...ui,
                    loading: false,
                    sent: true,
                    disabled: false,
                    success: true
                })
            });
        } else {
            setUI({
                ...ui,
                loading: false,
                disabled: false,
                noMatch: true
            });
        }


    
    }

    const handleChange = (e) => {
        setUI({
            ...ui,
            [e.target.name]: e.target.value
        });
    };


    return (
        <>
        {ui.loadingPage ?
        <>
        loading...
        </>    
        :
        <>
        <section className="hero is-light is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">

                        <div className="column is-5">

                        {
                            ui.valid === true ?
                            <>
                            {
                                ui.success === false ?
                                <>
                                                                                          <div className="has-text-centered mb-3">
                                <img style={logoStyle} src={Logo} />
                            </div>

                            <div className="mb-4">
                                <p className="title has-text-centered is-4">Create a new password</p>
                            </div>
                            <div className="box">

                                <form onSubmit={handleSubmit}>
                                    <div className="field">
                                        <label className="label">New Password</label>
                                        <div className="control">
                                            <input disabled={ui.disabled} onChange={handleChange} name="password" className="input" type="password"/>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Confirm Password</label>
                                        <div className="control">
                                            <input disabled={ui.disabled} onChange={handleChange} name="password2" className="input" type="password"/>
                                        </div>
                                    </div>
                                    <div className="field">

                                        <div className="control has-text-centered">
                                            {
                                                ui.loading ?
                                                <button className={`button is-primary is-fullwidth ${ui.loading ? `is-loading` : ``}`}></button>
                                                :
                                                <input className={`button is-primary is-fullwidth`} type="submit" value="Create new password" />
                                            }
                                        </div>
                                    </div>
                                </form>


                            </div>
                                </>
                                :
                                <>
                                
                                   <div className="has-text-centered">
                                   <p className="title has-text-centered is-4">Password Changed Successfully</p>
                                   <Link to='/login' className="button is-primary">Login</Link>
                                   </div>
                                </>
                            }
                            </>
                            :
                          <Redirect to='/404' />
                        }

                        </div>

                    </div>

                </div>
            </div>
        </section>
    </>
    }
        </>

    )

}

const logoStyle = {
    maxWidth: "300px"
}

export default Reset;