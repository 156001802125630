import React, { useRef, useState, useEffect } from 'react';
import PictureUploads from './PictureUploads';

const ProfileSettings = ({ State, Dispatch }) => {

    return (
        <>
   
            <div>
            <PictureUploads State={State} Dispatch={Dispatch} />
            </div>
        </>
    )
}

export default ProfileSettings;
