// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';



const Discovery = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Discovery';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            journals:
            {
                benefits: '',
                cost: ''
            },
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: {
                    ...data.answers.journals,
                    [e.target.name]: e.target.value
                }
            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                // history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            // history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>Welcome to the beginning of discovery! Today, we&#39;ll introduce you to the Respawn discovery process.</p>
                <p>So far you&#39;ve learned a lot about gaming. How gaming works and how it impacts you. But you&#39;re much more than &#39;just a gamer&#39;. You&#39;re a complex human being with a constellation of motivations, life factors, goals and much more. We&#39;re going to do a deep dive to discover why you game, your reasons for quitting, your internal strengths and the parts of your life you&#39;d like to change. This self-discovery will help you unearth your personal foundation from which you can build a life beyond gaming.</p>
                <h2 id="gamer-discovery">Gamer Discovery</h2>
                <p>The first section goes deep into the reasons you play. We&#39;ve found that all gamers get many of their needs met through gaming. But everyone has slightly different motivations. Some gamers want to immerse themselves in a virtual world to escape their life problems. Others want to achieve and conquer a goal. And still others enjoy the social connections they feel while gaming. Some it&#39;s a mix of them all!</p>
                <p>During gamer discovery you&#39;ll identify those reasons you play and the pros and cons of continuing to game. Then we&#39;ll connect those to your motivations to press pause for 30 days.</p>
                <h2 id="your-life">Your Life</h2>
                <p>No matter what motivates you to game, you almost certainly have certain areas of your life that you&#39;d like to change. Almost no one makes a decision to pause gaming without acknowledging their own pain. As one saying goes,</p>
                <p>To pleasure we make promises but to pain we do obey.</p>
                <p>Getting honest about that pain provides clarity for pressing pause. It&#39;s one of your chief resources in the challenge ahead.</p>
                <p>Part of the discovery process is a broad life assessment where you&#39;ll look at different aspects of your life. It&#39;s a simple but revealing assessment of what&#39;s working and what&#39;s not. From there, we&#39;ll discover your goals &amp; intentions for those areas of your life you&#39;d like to change during your break from gaming.</p>
                <h2 id="gamer-archetype">Gamer Archetype</h2>
                <p>Respawn includes a custom designed &quot;Gamer Archetype&quot; tool that provides you insight to not only your gaming type but your overall personality, motivations &amp; purpose. Are you more a healer or warrior? Do you stay on the periphery like a ranger? Do you play esports like an athlete? Engineer entire worlds? Go rogue and play the assassin?</p>
                <p>Self-discovery might be painful, but avoiding those truths is usually even more painful. Respawn will guide you through discovery. We&#39;ll be there with you. If at any point you find it too difficult, then it&#39;s OK to give yourself a break. Each new step takes courage.</p>
                <p>We plunge into ourselves to discover our resources to emerge into our purpose.</p>



            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}
                <br />
                <Link className="button is-primary has-text-weight-bold" to="/lessons/inventory">Next</Link>
                </div>

            </LessonLayout>
        </>
    )

}

export default Discovery;