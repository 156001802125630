import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { JournalContext } from '../../context/JournalContext';
import api from '../../utils/api';

import JournalTable from '../../components/JournalTable';

const JournalIndex = () => {




    return (
        <>
            <div className="hero">
                <div className="hero-body">
                    <div className="container">
                        <nav className="level is-mobile">
                            <div className="level-left">
                                <div className="level-item">
                                    <p className="is-size-2 has-text-weight-bold">Entries</p>
                                </div>
                            </div>

                            <div className="level-right">
                                <div className="level-item">
                                    <Link to={`/journal/edit/${uuidv4()}`} className="button is-primary"><FontAwesomeIcon icon={faPlus} /> &nbsp; New Entry</Link>
                                </div>
                            </div>
                        </nav>

                        <JournalTable />

                    </div>
                </div>
            </div>
        </>
    )
}

export default JournalIndex;