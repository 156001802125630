import React, { useContext, useEffect, useState } from "react";
import 'bulma-slider/dist/css/bulma-slider.min.css';
import WarrImg from '../../../static/illustrations/Warrior.jpg'
import RangImg from '../../../static/illustrations/Ranger.jpg'
import HealImg from '../../../static/illustrations/Healer.jpg'
import AthImg from '../../../static/illustrations/Athlete.jpg'
import EngImg from '../../../static/illustrations/Engineer.jpg'
import SpellImg from '../../../static/illustrations/Spellcaster2.png'
import RogImg from '../../../static/illustrations/Rogue.jpg'



export const Warrior = ({ Value, SetValue }) => {

    console.log(Value)

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-warrior">The Warrior</h2>
                <p>
                    <img src={WarrImg} style={avatarStyle} />
    Warriors love the battle. They jump into the fray with strength and conviction. They&#39;re not afraid of getting dirty. Achieving goals, conquering foes and being seen as strong motivate warriors through games.

</p>
                <p>Warrior types typically play as melee fighters, knights, soldiers, in first person shooter or massive online battle arenas. Games like Fortnite, Counterstrike, and Call of Duty.</p>
                <p>Warriors get into conflicts with themselves and others by being aggressive, grandiose, expedient and excessively driven. They cause harm through domineering, forceful, confrontational or vengeful.</p>
                <p>At their best, Warriors become self-accepting, honorable &amp; empowered heroes that serve as leaders willing to go first into battle, personal growth and creation.</p>


            </div>
            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="warrior" className="is-large slider is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.warrior} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}


export const Healer = ({ Value, SetValue }) => {

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-healer">The Healer</h2>
                <p>
                    <img src={HealImg} style={avatarStyle} />
    A great ally, Healers are social, responsive and supportive. They don&#39;t like to be the center of attention, and will sacrifice for a group. They&#39;re well intentioned, empathetic members of the group who feel the pain of others. Often (but not always) affectionate, they provide generous comfort</p>
                <p>Healers search for their place within society by helping others. They want to know where they stand and the role they play in their peer group. Games like Overwatch, World of Warcraft, and Rainbow Six: Siege.</p>
                <p>Healers get into conflict by communicating indirectly (people-pleasing) and ingratiating. They may become clingy, possessive and passive-aggressive as they worry about their self-importance.</p>
                <p>At their best, Healers nurture others and themselves. They remember to take care of themselves so they can humbly share gracious compassion with their kin.</p>

            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="healer" className="is-large slider is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.healer} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}



export const Ranger = ({ Value, SetValue }) => {

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-ranger">The Ranger</h2>
                <p>
                    <img src={RangImg} style={avatarStyle} />
    Rangers are agile, speedy, cunning &amp; prefer ranged combat. Often prefer aloneness but group together for expeditions or journeys. They&#39;re motivated by the thrill of adventure and exploration to discover new environments and natural connections.</p>
                <p>Ranger types often play as snipers, explorers, scouts, pathfinders, bounty hunters and may have an animal companion. They like open world games, RPGs and adventure series.</p>
                <p>Rangers get into conflict by being passive-aggressive, avoiding people, impatient, judgmental and can become disconnected from society as loners in the wild.</p>
                <p>At their best, Rangers are self-supporting and self-sufficient. They&#39;re inspired and naturally attuned. They are emotionally strong, honest and forthright.</p>

            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="ranger" className="is-large slider is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.ranger} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}

export const Athlete = ({ Value, SetValue }) => {
    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-athlete">The Athlete</h2>
                <p>
                    <img src={AthImg} style={avatarStyle} />
    Athletes love the competition &amp; play to win. They&#39;re excitable, eager &amp; faaaast. They often spontaneously jump between different games seeking variety. They&#39;re happy-go-lucky and love to talk. Athlete type seeks the extremes and to break limits, forever searching for the next great achievement.</p>
                <p>Athletes get into conflict by being restless and impatient with people and play. They become scattered and distracted and over-extend themselves being demanding and irresponsible.</p>
                <p>At the best, Athletes are receptive and grateful for their accomplishments. They find humility in recognizing their success includes more than themselves.</p>

            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="athlete" className="is-large slider is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.athlete} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}


export const Engineer = ({ Value, SetValue }) => {

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-engineer">The Engineer</h2>
                <p>
                    <img src={EngImg} style={avatarStyle} />
    Steady, patient &amp; calculating, the Engineer sees the big picture. They love world building and strategy games where they can assert their will to manifest a vision. They&#39;re resourceful, assertive and independent with a shrewd determination based on pragmatic planning. They love the challenge of puzzles, construction, and taking responsibility. Examples of games might include Minecraft, Total War, Factorio, and Civilization.</p>
                <p>Engineers get into conflict by being bluntly confrontational and forceful with others. They may be overly defiant, bad-tempered and cynical.</p>
                <p>At their Best, Engineers are empowered creators who manifest complex plans. They work with a higher honorable purpose to generously serve collective growth. Engineers become gentle and empowering of others.</p>

            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="engineer" className="slider is-large is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.engineer} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}


export const Spellcaster = ({ Value, SetValue }) => {

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-spellcaster">The Spellcaster</h2>
                <p>
                    <img src={SpellImg} style={avatarStyle} />
    Wise &amp; studious, Spellcasters are independent, curious with a powerful range of emotions and abilities. They&#39;re focused, observant, and insightful. As they love to learn, they&#39;re studious, perceptive and exploratory in seeking knowledge. They feel a higher calling and spiritual purpose that often manifests as transcendence from the physical world. Spellcasters often play the wise elder role with whimsical flare as they mentor and teach. Think of wizards &amp; witches, mages, magi and Jedi.</p>
                <p>Spellcasters get into conflict by being arrogant about their abilities, disconnected and aloof from people and practical matters. They become contemptuous as they look down from their believed prestige. Spellcasters may become unnecessarily uncompromising, provocative, and high-strung.</p>
                <p>At their best... [missing section]</p>

            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="spellcaster" className="slider is-large is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.spellcaster} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}


export const Rogue = ({ Value, SetValue }) => {

    const Slider = (e) => {
        console.log(e.target.name)
        SetValue({
            ...Value,
            answers: {
                ...Value.answers,
                slideData: {
                    ...Value.answers.slideData,
                    [e.target.name]: e.target.value
                }
            }

        })
    }


    return (
        <>
            <div className="content is-medium">

                <h2 id="the-rogue">The Rogue</h2>
                <p>
                    <img src={RogImg} style={avatarStyle} />
    Practical outsiders, Rouges are patient and steady while staying on the outskirts of society. They&#39;re somewhat of a mix of the Warrior&#39;s power and the Ranger&#39;s cunning stealth. They counter authority as rebels and bond together in outcast tribes and guilds. They appreciate practicality as they meet their needs. They may champion the meek, but seek safety in anonymity. Rouges are assassins, spies, mercenaries &amp; societal black sheep.</p>
                <p>Rouges get into conflict by denying community through unconscious anger and unrealistic expectations. They become stubbornly resigned to their outcast status and emotionally unavailable, avoiding meaningful connection with society, and self-destructive in their righteousness.</p>
                <p>At their best, the Rogue is engaged with others and steadfast in their proactive actions. They&#39;re deeply self-aware and contemplative of the dynamic nature of life. Instead of running, they stay true to their role in society.</p>


            </div>


            <div className="field">
                <label className="label">Drag the slider</label>
                <div className="control">
                    <input onChange={Slider} name="rogue" className="slider is-large is-fullwidth is-danger" step="1" min="0" max="100" value={Value.answers.slideData.rogue} type="range" />
                    <div>
                        <p className="has-text-weight-bold">Not me at all <span className="is-pulled-right">I completely identify</span></p>
                    </div>
                </div>
            </div>

        </>
    )
}

let avatarStyle = {
    float: 'right',
    marginTop: '-45px',
    maxWidth: '40%'
}

