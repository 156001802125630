import React, { useState } from 'react';
import api from '../../../utils/api';

const Account = ({ State, Dispatch }) => {

    // const {user, setUser} = useContext(AuthContext);


    const [info, setInfo] = useState({
        firstname: State.user.firstname || "",
        lastname: State.user.lastname || "",
        username: State.user.username || "",
        email: State.user.email || ""

    });

    const [savingUi, setSavingUi] = useState({
        isLoading: false,
        isSaved: false
    })



    const handleinfoChange = (e) => {
        e.preventDefault();

        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    };

    const handleUpdateApi = async () => {
        setSavingUi({
            ...savingUi,
            isLoading: true
        });
        await api.post('/userData', info).then((res) => {
            console.log(res.data);

            Dispatch({
                type: 'UPDATEACCOUNT',
                payload: res.data
            })
            setSavingUi({
                ...savingUi,
                isLoading: false,
                isSaved: true
            });

        }, (err) => {
            console.log(err)
        })
    };

    const handleinfoSubmit = (e) => {
        e.preventDefault();
        handleUpdateApi();

    }




    return (
        <>
            <form onSubmit={handleinfoSubmit}>
                <div className="field">
                    <label className="label">First name</label>
                    <div className="control">
                        <input onChange={handleinfoChange} name="firstname" className="input" type="text" value={info.firstname} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Last name</label>
                    <div className="control">
                        <input onChange={handleinfoChange} name="lastname" className="input" type="text" value={info.lastname} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Username</label>
                    <div className="control">
                        <input onChange={handleinfoChange} name="username" className="input" type="text" value={info.username} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        <input disabled={true} className="input" type="text" value={info.email} />
                    </div>
                </div>

               

                <div className="field">
                    <div className="control">
                        <button className={`button is-primary is-fullwidth ${savingUi.isLoading ? `is-loading` : ``}`} type="submit" value="Save Changes">Save Changes</button>
                    </div>
                </div>
            </form>

            {
                savingUi.isSaved ?
                    <p className="has-text-success has-text-centered has-text-weight-bold">Saved</p>
                    :
                    <>
                    </>
            }

        </>
    )
}

export default Account;
