
    //     answers: {
    //         school: SchoolCareer,
    //         fun: FunRec,
    //         physical: Physical,
    //         growth: Growth,
    //         money: Money,
    //         health: Health,
    //         friends: Friends,
    //         family: Family,
    //         partner: Partner
    //     }
    // });


export const SchoolCareer = [
    {
        'value': 0,
        'key': "school",
        'title': "I love school/my work.",
        
    },
    {
        'value': 0,
        'key': "talents",
        'title': "I feel my talents and skills are well used/engaged."
    },
    {
        'value': 0,
        'key': "work",
        'title': "I enjoy my work/school environment and the people around me."
    },
    {
        'value': 0,
        'key': "growth",
        'title': "I see opportunity for growth and development."
    },
    {
        'value': 0,
        'key': "livelihood",
        'title': "I feel like I have found my right livelihood/academic path."
    }
   
]


export const Money = [
    {
        'value': 0,
        'key': "enough",
        'title': "I have enough money to do the things I want to do and to accomplish the things that are important to me."
    },
    {
        'value': 0,
        'key': "manage",
        'title': "I manage my money and financial affairs and records well."
    },
    {
        'value': 0,
        'key': "worry",
        'title': "I am free from worry and anxiety about money."
    },
    {
        'value': 0,
        'key': "future",
        'title': "My financial future feels robust and sustainable"
    },
   
]


export const FunRec = [
    {
        'value': 0,
        'key': "time",
        'title': "I regularly take the time I need to experience play, adventure & leisure outside of gaming and being online."
    },
    {
        'value': 0,
        'key': "activities",
        'title': "I know what activities renew me and bring me alive and I participate in them regularly."
    },
    {
        'value': 0,
        'key': "space",
        'title': "I create plenty of space in my life to relax and enjoy myself and others."
    },
    {
        'value': 0,
        'key': "fun",
        'title': "I create fun for myself and others."
    },
   

]


export const Physical = [
    {
        'value': 0,
        'key': "supported",
        'title': "I feel nourished and supported by my home/living space."
    },
    {
        'value': 0,
        'key': "meaning",
        'title': "I am surrounded by things that I love and have meaning to me."
    },
    {
        'value': 0,
        'key': "order",
        'title': "The level of order in my surroundings is appropriate to my needs (it serves me)."
    },
    {
        'value': 0,
        'key': "wardrobe",
        'title': "My wardrobe is a clear expression of who I am. I love being in the clothes I wear."
    },
   

]


export const Growth = [
    {
        'value': 0,
        'key': "belief",
        'title': "I have a belief system that sustains me no matter what circumstances life throws at me."
    },
    {
        'value': 0,
        'key': "engaged",
        'title': "I am engaged in the unfolding story of my life and approach each day as an adventure."
    },
    {
        'value': 0,
        'key': "life",
        'title': "I regularly experience living a life that I love and loving who I am becoming."
    },
    {
        'value': 0,
        'key': "activities",
        'title': "I regularly engage in activities and learning that grow and expand me."
    },
   

]


export const Health = [
    {
        'value': 0,
        'key': "approach",
        'title': "I approach my health in a proactive and generative way, rather than crisis management mode."
    },
    {
        'value': 0,
        'key': "vitality",
        'title': "I am satisfied with my level of vitality and well being."
    },
    {
        'value': 0,
        'key': "support",
        'title': "I have support systems and structures in place that allow me to easily maintain my health and well being."
    },
    {
        'value': 0,
        'key': "body",
        'title': "I am conscious of my body and fitness level and take responsibility for my physical well-being."
    },
    {
        'value': 0,
        'key': "health",
        'title': "I know what works for me to maintain my health and I consistently do it."
    },
]


export const Friends = [
    {
        'value': 0,
        'key': "friends",
        'title': "I have a sufficient number of great friends."
    },
    {
        'value': 0,
        'key': "sustain",
        'title': "My friendships nourish and sustain me."
    },
    {
        'value': 0,
        'key': "available",
        'title': "I am a good friend and I make myself available to my friendships."
    },
    {
        'value': 0,
        'key': "trust",
        'title': "I trust the relationships I have with my friends."
    },
    {
        'value': 0,
        'key': "time",
        'title': "I love and make the most of the time I spend with my friends."
    },
    
]

export const Family = [
    {
        'value': 0,
        'key': "contact",
        'title': "I am satisfied with the level of contact I have with my family."
    },
    {
        'value': 0,
        'key': "hidden",
        'title': "Nothing feels hidden or withheld in my relationships with my family members."
    },
    {
        'value': 0,
        'key': "satisfied",
        'title': "I am satisfied with the role I play and the level of contribution i have in my family."
    },
    {
        'value': 0,
        'key': "experience",
        'title': "I have created the experience of family in my life, whether or not it is with my biological relatives."
    },
    
    
]



export const Partner = [
    {
        'value': 0,
        'key': "open",
        'title': "I am open to creating an intimate loving relationship."
    },
    {
        'value': 0,
        'key': "free",
        'title': "I am free from past resentments or blame in the area of intimate relationships."
    },
    {
        'value': 0,
        'key': "risk",
        'title': "I am willing to risk myself for the sake of intimacy."
    },
    {
        'value': 0,
        'key': "romance",
        'title': "I create romance in my life. "
    },
    
    
    
]
