import React, { useState, useEffect } from 'react';
import JournalEditor from '../../components/JournalEditor';
import { useParams, Link } from 'react-router-dom';
import api from '../../utils/api';
import moment from 'moment';





const JournalEdit = () => {

    let params = useParams();

    const [journalData, setJournalData] = useState({
        key: '',
        title: '',
        prompt: null,
        saved: false,
        timestamp: null
    });

    const [ux, setUx] = useState({
        isLoading: false,
        isSaving: false,
        isSaved: false,
    });

    const [content, setContent] = useState("");


    const getJournal = async () => {

        let url = `/journal/${params.id}`;
        await api.get(url).then((res) => {
            console.log(res);
            let jd = res.data;
            setJournalData({
                ...journalData,
                key: params.id,
                title: jd.title,
                timestamp: jd.timestamp
            });
            setContent(jd.content);
        }, (err) => {
            console.log(err);
        });
    };


    useEffect(() => {

        if (params.id) {
            getJournal();
        }


    }, [params.id])


    const handleSaveBtn = () => {

        const payload = {
            key: params.id,
            title: journalData.title,
            content: content,
        }

        setUx({
            ...ux,
            isSaving: true
        });

        api.post('/journal', payload).then((res) => {
            console.log(res);
            let ts = new Date();
            console.log(ts);
            setJournalData({
                ...journalData,
                timestamp: ts
            })
            setUx({
                ...ux,
                isSaving: false,
            });
        }, (err) => {
            console.log(err);
            setUx({
                ...ux,
                isSaving: false,
            });
        });
    };

    const onChangeInput = (e) => {

        setJournalData({
            ...journalData,
            title: e.target.value
        })

    };


    return (
        <>
            <div className="container">
                <div className="section">
                    <nav className="level is-mobile">
                        <div className="level-left">
                        <div className="level-item">
                        <Link className="has-text-primary has-text-weight-bold" to="/journal">← Journals</Link>
                            </div>
                            {/* <div className="level-item">
                                <button className="button is-info">Get a prompt</button>
                            </div> */}
                        </div>

                        <div className="level-right">
                            {
                                journalData.timestamp !== null ?
                                    <div className="level-item">
                                        <p>Last saved: <strong>{moment(journalData.timestamp).fromNow()}</strong></p>
                                    </div>
                                    :
                                    null
                            }
                            <div className="level-item">
                                <button onClick={handleSaveBtn} className={`button is-primary ${ux.isSaving ? `is-loading` : null}`}>Save</button>
                            </div>
                        </div>

                    </nav>
                    <div className="field">
                        <label className="label mb-3">Title <span className="is-pulled-right"><button className="button is-info is-size-7 has-text-weight-bold">Autofill with a prompt</button></span></label>
                        <div className="control">
                            <input onChange={onChangeInput} name='title' className="input" type="text" placeholder="Placeholder" value={journalData.title} />
                        </div>
                    </div>
                    <JournalEditor
                        SaveData={handleSaveBtn}
                        Value={content}
                        SetValue={setContent}

                    />


                </div>
            </div>
        </>
    )

}

export default JournalEdit;