import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import { useParams, Link } from 'react-router-dom';
import api from '../../utils/api';
import Video from './methods/video';
import Audio from './methods/audio';
import Journal from './methods/journal';

const Emergency = () => {

    let img = "https://images.unsplash.com/photo-1490810194309-344b3661ba39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3390&q=80";

    let params = useParams();
    console.log(params.id);

    const [ui, setUi] = useState({
        'selected': null,
        'cope': null
    });

    const symptonsData = [
        {
            'keyword': 'cravings',
            'title': `I'm experiencing craving`,
        },
        {
            'keyword': 'escapism',
            'title': `I want to escape`,
        },
        {
            'keyword': 'boredom',
            'title': `I'm bored`,
        },
        {
            'keyword': 'relapse',
            'title': `I have relapsed or I want to relapse`,
        },
        {
            'keyword': 'nostalgia',
            'title': `I'm feeling nostalgic`,
        },
        {
            'keyword': 'friends',
            'title': `I feel lonely or I miss my friends`,
        },
    ];

    const copeMethods = [
        {
            'keyword': 'video',
            'title': 'Watch a video',
            'img': 'https://bulma.io/images/placeholders/1280x960.png'
        },
        {
            'keyword': 'audio',
            'title': 'Listen to an audio experience',
            'img': 'https://bulma.io/images/placeholders/1280x960.png'
        },
        {
            'keyword': 'prompt',
            'title': 'Do a guided journaling prompt',
            'img': 'https://bulma.io/images/placeholders/1280x960.png'
        },
    ]

    let selectSymp = async (e) => {

        let index = e.target.dataset.idx;

        let item = symptonsData[index]

        console.log(item)


        let Payload = {
            'id': params.id,
            'type': item.keyword
        };

        await api.put('/panicButton', Payload).then((res) => {
            console.log(res)
            setUi({
                ...ui,
                selected: item.keyword
            });

        }, (err) => {
            console.log(err)
        });

    };

    const selectCope = (e) => {
        console.log(e.target.dataset.tag);
        setUi({
            ...ui,
            cope: e.target.dataset.tag
        })
    };

    const DefaultSection = () => (
        <div className="columns">
            <div className="column is-half">
                <div className="py-6 px-6">
                    <h1 className="is-size-3">You hit the panic button. Don't worry, we'll walk you through this.</h1>
                    <p className="mt-5 mb-5 has-text-centered is-size-2 has-text-weight-bold">What is happening?</p>
                    <div className="has-text-centered">
                        {
                            symptonsData.map((item, index) => (
                                <div key={index} className="field has-addons has-addons-centered has-text-centered">
                                    <div className="control has-text-centered">
                                        <span style={{ cursor: 'text' }} className="button is-primary has-text-weight-bold is-size-5 is-size-6-mobile">
                                            {parseInt(index) + 1}
                                        </span>
                                    </div>
                                    <div style={{ width: '310px' }} className="control has-text-centered">
                                        <button onClick={selectSymp} data-idx={index} className={`button is-fullwidth has-text-weight-bold is-size-5 is-size-6-mobile`}>
                                            {item.title}
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: `url(${img})` }} className="column is-half is-hidden-mobile masthead">
            </div>
        </div>
    );

    const WaysToCope = () => {
        return (
            <section className="hero is-medium">
                        <div className="hero-body">
                            <div className="container">
                                <div className="columns is-centered">
                                    <div className="column is-8">
                                        <h1 className="title">Select a way to cope</h1>
                                        <div className="columns is-centered">
                                            {
                                                copeMethods.map((item, index) => (
                                                    <div key={index} className="column">
                                                        <div className="card">
                                                            <div className="card-image">
                                                                <figure className="image is-4by3">
                                                                    <img src={item.img} alt={item.title} /></figure>
                                                            </div>
                                                            <div className="card-footer">
                                                                <span onClick={selectCope} data-tag={item.keyword} className="card-footer-item title is-5 pointer">{item.title}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        )
    }

    return (
        <>
            <Navbar />
            {
                ui.selected === null ?
                    <section className="section">
                        <div className="container mt-5">
                            <div className="fullBox">
                                {DefaultSection()}
                            </div>
                        </div>
                    </section>
                    :
                    ui.selected !== null && ui.cope !== null ?
                    <>
                    {
                        ui.cope === 'video'? 
                        <>

                        <Video Symptom={ui.selected} />
                        
                        </>
                        : null
                    }
                    {
                        ui.cope === 'audio'? 
                        <>

                        <Audio Symptom={ui.selected} />

                        {/* <Video Symptom={ui.selected} /> */}
                        
                        </>
                        : null
                    }
                    {
                        ui.cope === 'prompt'? 
                        <>

                        <Journal Symptom={ui.selected} />

                        {/* <Video Symptom={ui.selected} /> */}
                        
                        </>
                        : null
                    }
                     <div className="has-text-centered">
                     <p className="has-text-weight-bold">Want to try a different cope method?</p>
                     <br />
                     <button onClick={() => setUi({...ui, cope: 'video'})} className="button is-primary has-text-weight-bold">Video</button>
                     <button onClick={() => setUi({...ui, cope: 'audio'})} className="button is-primary has-text-weight-bold ml-3">Audio</button>
                     <button onClick={() => setUi({...ui, cope: 'prompt'})} className="button is-primary has-text-weight-bold ml-3">Journal</button>

                        <br />
                    
                        <p className="mt-6">When you're done, head back to your home screen by clicking <Link className="has-text-weight-bold has-text-primary" to='/'>here</Link> and finish today's lesson if you haven't already. <br/>
                        Also make use of the journaling tool to get any thoughts and ideas out of your system.
                        <br/>
                        Alternatively, if you have more questions or if you need to talk about why you hit the Emergency Button, send me an email: <a href='mailto:cam@gamequitters.com'>cam@gamequitters.com</a> .
                        </p>
                        
                                    </div>
                                    <footer className="footer foot-position mt-6">
  <div className="content has-text-centered">
    <p>
Game Quitters is an educational resource. If you need medical support, please <strong>call 911</strong>, emergency services, or a crisis line immediately.
    </p>
  </div>
</footer>
                    </>
                    :

                    <>
                    {WaysToCope()}
                    </>
                    

            }

{/* 
            {
                ui.selected !== null && ui.cope !== null ?
                    <>
                    </>
                    :
                    null
            } */}


            {/* 
            <section className="section">
                <div className="container mt-5">
                                       <Cravings />
                </div>
            </section> */}
        </>
    )
}

export default Emergency;