import {useContext} from 'react';
import {AuthContext} from '../context/AuthContext';
import axios from 'axios';
import Cookies from 'js-cookie'

// var cookie_value = Cookies.get('gq');
// setInterval(() => {
//   var cookie_value = Cookies.get('gq');
// }, []);

// if(cookie_value === undefined || cookie_value === null){
//   setInterval(() => {
//   var cookie_value = Cookies.get('gq');
//   console.log(cookie_value)
// }, [1000]);
// }

// const api = axios.create({
//   baseURL: `http://localhost:7000/api/`,
//   withCredentials: true,
//   crossDomain: true,
// });



const api = axios.create({
    baseURL: `https://gq-node.herokuapp.com/api/`,
  withCredentials: true,
  crossDomain: true,
  });




// api.interceptors.request.use(function (config) {
//   const {state} = useContext(AuthContext);
//   const token = state.token;
//   config.headers.Authorization =  token;

//   return config;
// });


// const sleepRequest = (milliseconds, originalRequest) => {
//   return new Promise((resolve, reject) => {
//       setTimeout(() => resolve(api(originalRequest)), milliseconds);
//   });
// };

// api.interceptors.response.use(response => {
//   return response;
// }, error => {
//   const { config, response: { status }} = error;
//   const originalRequest = config;

//   if (status === 401) {
//       return sleepRequest(1000, originalRequest);
//   } else {
//       return Promise.reject(error);
//   }
// });





export default api;
