import React, {useContext, useEffect} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../components/LessonLayout';
import {Link, useRouteMatch, useParams} from 'react-router-dom';
import api from '../../utils/api';

const FinishLesson = () => {



return (
<>
<LessonLayout Title='Complete'>
<h1 className="title">Nice job. You're done for today!</h1>
<Link to="/journal" className="button is-primary has-text-weight-bold">Journal About it</Link>
<Link to="/" className="ml-2 button is-primary has-text-weight-bold is-outlined">Go to your personal hub</Link>
</LessonLayout>
</>
    )

}

export default FinishLesson
