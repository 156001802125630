import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import api from '../../../utils/api';
import moment from 'moment';

const Evaluation = ({Slug, Day, State, Dispatch}) => {


const title = `Evaluation`;

const [isLoading, setIsLoading] = useState(false);

const [ui, setUi] = useState({
  saved: false,
  allowFinish: false
});

const history = useHistory();

const [done, setDone] = useState(true);
const [id, setId] = useState(null);

const [data, setData] = useState({
  answers: {
    age: '',
    years: '',
    favourite: '',
    average: '',
    status: '',
    modmean: '',
    cutdown: '',
    guilt: '',
    daystopped: '',
    whystop: '',
    achieve: '',
    challenge: '',
    twoweekshours: '',
    percentage: ''
  }
});

useEffect(() => {
    window.scrollTo(0,0)
    api.get(`/lessons/answers/${Slug}`).then((res) => {
      console.log(res)
      if(res.data.answers){
        setData({
          ...data,
          answers: res.data.answers
        })
      }
    }, (err) => {
      console.log(err)
    });
    api.get(`/lessons/update/${Day}`).then((res) => {
      console.log(res.data);
      setId(res.data._id);
      setDone(res.data.completed);
    }, (err) => {
      console.log(err)
    });
}, []);


const ClickSubmit = async() => {


  setIsLoading(true);


    let answers = {
        answers: data.answers
    }



  await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
    console.log(res)
    setIsLoading(false);
    setUi({
      ...ui,
      saved: true,
      allowFinish: true
    });
  }, (err) => {
    console.log(err)
  });



}

const FinishLesson = () => {

 
  if(done === false){

    let complete = {
      id: id,
      completed: true,
      timestamp: new Date()
    }
    console.log(complete);

    api.post(`/lessons/complete`, complete).then((res) => {
      console.log(res)
        history.push('/lessons/done')

    }, (err) => {
      console.log(err)
    });
    

  } else {
    history.push('/lessons/done')
  }


};


const handleChange = (e) => {

  setUi({
    ...ui,
    saved: false
  })

    if(e.target.name === 'twoweekshours'){
        let hours = parseInt(e.target.value);
        if(hours > 336){
            alert("Oops, there's only 336 hours in 14 days. Please recalculate.");

        } else {
            let perc = hours / 336 * 100;
            console.log(perc)
            // setAnswers({
            //     ...answers,
            //     [e.target.name]: e.target.value,
            //     percentage: perc
            // });
            setData({
              ...data,
              answers: {
                ...data.answers,
                [e.target.name]: e.target.value,
                percentage: perc
              }
              
            })
        }

    } else {
      setData({
        ...data,
        answers: {
          ...data.answers,
          [e.target.name]: e.target.value,
        }
      })
    }
    
};




const content = () => {
    return (

<>

<p>Today you are going to learn about your relationship to gaming by answering a short series of questions. This is an opportunity for you to reflect on the role gaming plays (no pun intended) in your life and it will also help us to better support you throughout the program.</p>
<p>Reminder: All answers are strictly confidential and there are no &#39;right&#39; or &#39;wrong&#39; answers. The questions below will take about 15 minutes to complete.</p>
<blockquote>
<p>Mindfulness exercise: Can you wiggle your toes?</p>
</blockquote>

</>

    )
}

const succ = () => {

    return (
        <>
        <blockquote>Great! Thanks for taking the time to share your answers with us. We will review your answers to ensure the program is optimized to be in alignment with your goals and challenges. Before you proceed can you feel your toes? 😊</blockquote>
        
        <p><strong>Bonus: Calculate your percentage of time spent gaming</strong></p>
   <div className="field">
  <label className="label">How many hours have you spent gaming in the last 14 days?</label>
  <div className="control">
    <input onChange={handleChange} value={data.answers.twoweekshours} name='twoweekshours' className="input" type="number" />
  </div>
</div>
<p>Step 2: Divide your gaming hours by 336</p>
<p>Step 3: Multiply your Step 2 number by 100</p>
    <p>Answer: You have spent <strong>{Math.round(data.answers.percentage)}%</strong> of your time in the last two weeks gaming.</p>
<p>If your answer is above 15% your gaming may be having an impact on your productivity and quality of life. Chances are you may not be thinking about your gaming this way. It just seems like you are being soothed and getting more skilled.</p>

    <button disabled={ui.saved} onClick={ClickSubmit} className={`button has-text-weight-bold is-primary ${isLoading ? `is-loading`: null}`}>{ui.saved ? `Saved` : `Save Answers`}</button>
    <br />
   {
     ui.allowFinish ?
     <button onClick={FinishLesson} className={`mt-4 button has-text-weight-bold is-primary`}>Finish Lesson</button>
     :
     null
   }
        </>
    )
}



return (
<>

<LessonLayout Slug={Day} Title={title}>
   <div className="content content-text">
   {content()}
   </div>
   <div>

   <div className="field">
  <label className="label">Ok let's begin: At what age did you start playing video games?</label>
  <div className="control">
    <input onChange={handleChange} value={data.answers.age} name='age' className="input" type="text" />
  </div>
</div>

<div className="field">
  <label className="label">And how long (in years) have you been (or did you) play video games for?</label>
  <div className="control">
    <input onChange={handleChange} value={data.answers.years} name="years" className="input" type="text" />
  </div>
</div>

<div className="field">
  <label className="label">What are (or were) your favorite games to play?</label>
  <div className="control">
    <input onChange={handleChange} value={data.answers.favourite} name='favourite' className="input" type="text"  />
  </div>
</div>

<div className="field">
  <label className="label">Next, on average, over the past three months, how many hours per day did you play?</label>
  <div className="control">
    <input onChange={handleChange} value={data.answers.average} name='average' className="input" type="text" />
  </div>
</div>


<div className="field">
  <label className="label">Thanks! I'm curious, what is your current gaming status?</label>
  <div className="control">
  <label className="radio">
    <input value='stopped' onChange={handleChange} checked={data.answers.status === 'stopped'} name='status' type="radio"/>
    &nbsp;I've stopped gaming
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='moderation' checked={data.answers.status === 'moderation'} type="radio" name="status" />
    &nbsp;I play in healthy moderation
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='cantstop' checked={data.answers.status === 'cantstop'} type="radio" name="status" />
    &nbsp;I want to stop but can't seem to
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='undecided' checked={data.answers.status === 'undecided'} type="radio" name="status" />
    &nbsp;I haven't decided if I want to stop gaming
  </label>
</div>
</div>



{
    data.answers.status === 'moderation' ?
    <>
    <div className="field">
  <label className="label">What does gaming in healthy moderation mean to you?</label>
  <div className="control">
    <input onChange={handleChange} name='modmean' value={data.answers.modmean} className="input" type="text" />
  </div>
</div>
<div className="field">
  <label className="label">Have you ever felt that you should cut down on your gaming?</label>
  <div className="control">
    <input onChange={handleChange} name='cutdown' value={data.answers.cutdown} className="input" type="text" />
  </div>
</div>
<div className="field">
  <label className="label">Have you ever felt bad or guilty about your gaming?</label>
  <div className="control">
    <input onChange={handleChange} name='guilt' value={data.answers.guilt} className="input" type="text" />
  </div>
</div>
    </>

    :

    <>
    </>
}


{
    data.answers.status === 'stopped' ?
    <>
    <div className="field">
  <label className="label">How many days has it been since you've stopped playing?</label>
  <div className="control">
    <input onChange={handleChange} name='daystopped' value={data.answers.daystopped} className="input" type="text" />
  </div>
</div>
<div className="field">
  <label className="label">Why did you decide to stop gaming? <span className="help">Go into as much detail as you want</span></label>
  <div className="control">
  <textarea onChange={handleChange} name='whystop' className="textarea" value={data.answers.whystop}></textarea>
  </div>
</div>

    </>

    :

    <>
    </>
}


<div className="field">
  <label className="label">What type of relationship do you hope to achieve with gaming? </label>
  <div className="control">
  <label className="radio">
    <input value='quit' onChange={handleChange} checked={data.answers.achieve === 'quit'} name='achieve' type="radio"/>
    &nbsp;Quit gaming for good
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='break' checked={data.answers.achieve === 'break'} type="radio" name="achieve" />
    &nbsp;Take a break from gaming
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='learnmod' checked={data.answers.achieve === 'learnmod'} type="radio" name="achieve" />
    &nbsp;Learn to game in moderation
  </label>
  <br />
  <label className="radio">
    <input onChange={handleChange} value='continue' checked={data.answers.achieve === 'continue'} type="radio" name="achieve" />
    &nbsp;Continue to game in moderation
  </label>
</div>
</div>

<div className="field">
  <label className="label">What is your single biggest challenge right now when it comes to gaming? (Share at least one sentence)</label>
  <div className="control">
  <input onChange={handleChange} name='challenge' value={data.answers.challenge} className="input" type="text" />
  </div>
</div>


{ data.answers.challenge.length > 5 ?
    <div className="content">
    {succ()}
</div>
:
null
}


   </div>
</LessonLayout>

</>
    )
}



export default Evaluation;
