import React from 'react';
import ReactPlayer from 'react-player';
import Img from '../../../static/gq_logo_full.png';

const Audio = ({Symptom}) => {


    const AudioURL = () => {
        
        switch (Symptom) {
            case 'cravings':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/034-how-to-overcome-cravings',
                    'title': 'How to overcome cravings'
                }
                break;
            case 'escapism':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/how-to-stop-escaping-into-video-games',
                    'title': 'How to Stop Escaping into Video Games'
                }
                break;
            case 'boredom':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/032-why-are-new-activities-boring-after-you-quit-gaming',
                    'title': 'Why Are New Activities Boring After You Quit Gaming?'
                }
                break;
            case 'relapse':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/026-the-surprising-benefits-of-relapsing',
                    'title': 'The Surprising Benefits of Relapsing'
                }
                break;
            case 'nostalgia':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/dealing-with-gaming-nostalgia',
                    'title': 'Dealing with Gaming Nostalgia'
                }
                break;
            case 'friends':
                return {
                    'url': 'https://gamingthesystem.transistor.fm/episodes/029-how-to-make-friends-after-you-quit-gaming',
                    'title': 'How To Make Friends After You Quit Gaming'
                }
                break;
        
            default:
                break;
        }

    }



    return (
        <>
            <section className="hero is-medium">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-4">
                                <div className="has-text-centered">
                                    {/* <img style={{width: '150px'}} src={Img} /> */}
                                    <h1 className="title mb-2">{AudioURL().title}</h1>
                                        {/* TODO: Embed Transistor Podcasts */}
                                    <a href={AudioURL().url} target='_blank' className="button is-size-4 is-primary mt-4 has-text-weight-bold">Start Listening</a>
                                    <p className="help">A new tab will open</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Audio;