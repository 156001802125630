import React from 'react';


const LessonsDefaultState = [
    {
        lesson: 1,
        title: "Welcome",
        slug: "welcome",
        active: true,
        breakDay: false,
        started_time: new Date(),
        finished_time: null,
        completed: false,
        sublesson: [
            {
                lesson: 1.1,
                title: "Evaluation",
                slug: 'evaluation',
                completed: false,
                answers: []
            }
        ],
        answers: []
    },
    {
        lesson: 2,
        title: "Expectations",
        slug: "expectations",
        active: false,
        breakDay: false,
        started_time: 'timestamp',
        finished_time: null,
        completed: false,
        sublesson: [
            {
                lesson: 2.1,
                title: "Gaming Hobby vs. Gaming Problem",
                slug: 'gaming-hobby-gaming-problem',
                answers: []
            }
        ],
        answers: []
    }
]


export default LessonsDefaultState;


