import React, { useContext, useState } from 'react';
import Logo from '../../static/gq_logo_full.png';

import { AuthContext } from '../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useHistory, Link } from 'react-router-dom';

import api from '../../utils/api';


const Register = () => {


    const [cookies, setCookie, removeCookie] = useCookies(['gq']);

    const history = useHistory();

    const defaultState = {
        email: "",
        username: "",
        password: "",
        password2: ""
    }

    const defaultUIState = {
        isLoading: false,
        isInvalid: false,
        isText: ""
    }

    const [loginUi, setLoginUi] = useState(defaultState)

    const { dispatch } = useContext(AuthContext);

    const [login, setLogin] = useState(defaultState);


    const handleLoginInput = (e) => {
        setLoginUi(defaultUIState)
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    };

    const handleLoginSubmit = (e) => {

        console.log('clicked');
        console.log(login)

        e.preventDefault();

        if (login.password.length && login.password2.length && login.email.length && login.username.length > 0) {

            if (login.password !== login.password2) {
                console.log("Please ensure passwords match")
                setLoginUi({
                    ...loginUi,
                    isInvalid: true,
                    isText: "Please ensure passwords match"
                });
                return;
            } else {
                setLoginUi({
                    ...loginUi,
                    isLoading: true,

                });
                api.post('/newuser', login).then((res) => {
                    dispatch({
                        type: 'SETLOADING',
                        payload: true
                    });
                    setLoginUi({
                        ...loginUi,
                        isLoading: false
                    });
                    console.log(res);
                    setCookie('gq', res.data.data.token, {sameSite: false});
                    localStorage.setItem('gq_tok', res.data.data.token);
                    // dispatch({
                    //     type: 'LOGIN',
                    //     payload: res.data
                    // });

                    history.push('/');

                }, (err) => {

                    setLoginUi({
                        ...loginUi,
                        isInvalid: true,
                        isText: "Email or Username already exist."
                    });
                });
            }
        } else {
            console.log('not everything filled in')
            setLoginUi({
                ...loginUi,
                isInvalid: true,
                isText: "Please ensure all fields are filled in"
            });
        }
    };

    return (
        <>
            <section className="hero is-light is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">

                            <div className="column is-5">

                                <div className="has-text-centered mb-3">
                                    <img style={logoStyle} src={Logo} />
                                </div>

                                <div className="mb-4">
                                    <p className="title has-text-centered is-4">Create an account</p>
                                </div>
                                <div className="box">

                                    <form onSubmit={handleLoginSubmit}>
                                        <div className="field">
                                            <label className="label">Email</label>
                                            <div className="control">
                                                <input name="email" onChange={handleLoginInput} className="input" type="email" value={login.email} />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">Username</label>
                                            <div className="control">
                                                <input name="username" onChange={handleLoginInput} className="input" type="username" value={login.username} />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">Password</label>
                                            <div className="control">
                                                <input name="password" type="password" onChange={handleLoginInput} className="input" value={login.password} />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">Confirm Password</label>
                                            <div className="control">
                                                <input name="password2" type="password" onChange={handleLoginInput} className="input" value={login.password2} />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <div className="control has-text-centered">
                                                <button type="submit" className={`button is-fullwidth is-primary ${loginUi.isLoading ? `is-loading` : ``}`} value="Login">Create an account</button>
                                            </div>
                                        </div>
                                    </form>

                                    {
                                        loginUi.isInvalid ?
                                            <>
                                                <br />
                                                <p className="has-text-weight-bold has-text-danger has-text-centered">{loginUi.isText}</p>
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                    <div className="mt-5">
                                        <Link to="/signin">Already have an account?</Link>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

const logoStyle = {
    maxWidth: "300px"
}

export default Register;