// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';


const MotivationToQuit = ({ Slug, Day }) => {

    const PainsData = [
        {
            'value': '',
            'key': "physical",
            'title': "Physical pain associated with sedentary lifestyle?"
        },
        {
            'value': '',
            'key': "emotional",
            'title': "Emotional pain of anxiety, depression, hopelessness, stuck or something else?"
        },
        {
            'value': '',
            'key': "social",
            'title': "Social pain of feeling isolated and alone and/or disconnected form family?"
        },
        {
            'value': '',
            'key': "romantic",
            'title': "Romantic pain of not connecting deeply with a partner?"
        },
        {
            'value': '',
            'key': "financial",
            'title': "Financial pain of spending a lot or not making enough money?"
        },
        {
            'value': '',
            'key': "growth",
            'title': "Growth pain of not developing through whole hearted living?"
        }

    ]

    const GainsData = [
        {
            'value': '',
            'key': "career",
            'title': "What would your career or school life look like?"
        },
        {
            'value': '',
            'key': "fun",
            'title': "What kind of fun could you have?"
        },
        {
            'value': '',
            'key': "finances",
            'title': "What would happen to your finances?"
        },
        {
            'value': '',
            'key': "physical",
            'title': "How could your physical environment change?"
        },
        {
            'value': '',
            'key': "health",
            'title': "How might it effect your health and wellbeing?"
        },
        {
            'value': '',
            'key': "social",
            'title': "What would change in your social life?"
        },
        {
            'value': '',
            'key': "family",
            'title': "How would you family relationships change?"
        },
        {
            'value': '',
            'key': "romance",
            'title': "What's possible in a romantic relationship?"
        }

    ]




    const history = useHistory();
    const title = 'Motivation to Quit';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            pains: PainsData,
            gains: GainsData,
            oneLine: ''
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleChange = (e) => {
        setUi({
            ...ui,
            saved: false
        });
        let index = e.target.dataset.idx;
        let section = e.target.dataset.section;
        let value = e.target.value;

        if (section === 'pains') {
            let newArr = [...data.answers.pains]
            let current = newArr[index];
            current.value = value;
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    pains: newArr
                }
            })
        };

        if (section === 'gains') {
            let newArr = [...data.answers.gains]
            let current = newArr[index];
            current.value = value;
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    gains: newArr
                }
            });
        };

        if (section === 'oneLine') {

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    oneLine: value
                }
            })

        }


    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>Hello again! Welcome to motivation day. 😊</p>
                <p>At some point in your life you&#39;ve probably asked yourself: Can people actually change? Can they grow or are they stuck being who they are? The simple answer: Yes, people can change. It happens all the time! But many people don&#39;t change; they don&#39;t grow. They get stuck in a cycle. How do they get stuck? By lying to themselves about how painful it is to stay the same.</p>
                <blockquote>
                    <p>To pleasure we make promises. To pain we do obey.</p>
                </blockquote>
                <p>There&#39;s the stick and there&#39;s the carrot. The stick hurts and the carrot is delicious. We&#39;re motivated to change by both pain and pleasure, but it&#39;s most often the pain that starts us on the path. Finding motivation to stop gaming will typically begin with getting honest about how painful it is to compulsively game. Honestly describing the consequences of gaming in your life will put your motivations in stark relief. Remember that there&#39;s no right or wrong answer as long it&#39;s true and personal to you. We all have different motivations.</p>
                <h3 id="pains">Pains</h3>
                <p>What&#39;s the pain that you are ready to outgrow? Look back to your <span className="has-text-link pointer">Life Assessment</span> as needed.</p>

                {
                    data.answers.pains.map((item, index) => (
                        <div key={index} className="field">
                            <label className="label">{item.title}</label>
                            <div className="control">
                                <textarea onChange={handleChange} data-section='pains' data-idx={index} className="textarea" value={item.value} ></textarea>
                            </div>
                        </div>
                    ))
                }

                <h3 id="gains">Gains</h3>
                <p>Now, the flipside of pains: Gains. Let&#39;s look at what motivates you to stop gaming and live your life full out! These are the benefits you&#39;ll feel from not gaming. It&#39;s OK to dream big here. There&#39;s no wrong answer, and try to connect them with tangible outcomes. We&#39;ll use the same categories as we did from <span className="has-text-link pointer">Life Assessment</span>.</p>

                {
                    data.answers.gains.map((item, index) => (
                        <div key={index} className="field">
                            <label className="label">{item.title}</label>
                            <div className="control">
                                <textarea onChange={handleChange} data-section='gains' data-idx={index} className="textarea" value={item.value} ></textarea>
                            </div>
                        </div>
                    ))
                }

                <h3 id="your-one-line">Your One Line</h3>
                <p>Now, finally, look back across your answers and reflect on which are the greatest pains and most compelling gains. Take them and write them into a single sentence that you can use as a personal saying. Ideally, your sentence will be specific. General, non-personal phrases won&#39;t have the same impact, but you can use them as needed. Here&#39;s an example that&#39;s compelling but too general: <em>I am sick and tired of feeling sick and tired, and I&#39;m ready to live life full out.</em> Rather, go with something like: <em>I am done with feeling isolated, alone and anxious around people, and I&#39;m ready to become financially sustainable and excel at school.</em></p>
                <p>OK. What&#39;s your one line that will help you remember your motivation to take a break from gaming?</p>

                <div className="notification">
                    <div className="field">
                        <label className="label">I want to take a break playing games because:</label>
                        <div className="control">
                            <input onChange={handleChange} data-section='oneLine' className="input" value={data.answers.oneLine} />
                        </div>
                    </div>
                </div>


                <button disabled={ui.saved} className="button is-primary has-text-weight-bold mb-3" onClick={ClickSubmit}>Save Changes</button>
                <br />
                {
                    ui.saved ?
                    <>

                    <button className="button is-primary has-text-weight-bold" onClick={FinishLesson}>Finish Lesson</button>
                    </>
                    :
                    null
                }

            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                </div>

            </LessonLayout>
        </>
    )

}

export default MotivationToQuit;