// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';


const Degaming = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'DeGaming';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false,
        oldMode: ''
    });


 const Easy = [
    {
        'title': 'PC',
        'selected': ''
    },
    {
        'title': 'Console, VR',
        'selected': ''
    },
    {
        'title': 'Mobile',
        'selected': ''
    },
    {
        'sec': 'Online Content'
    },
    {
        'title': 'Clear Cache',
        'selected': ''
    },
]

 const Medium = [
    {
        'title': 'PC',
        'selected': ''
    },
    {
        'title': 'Console, VR',
        'selected': ''
    },
    {
        'title': 'Mobile',
        'selected': ''
    },
    {
        'sec': 'Online Content'
    },
    {
        'title': 'Clear Cache',
        'selected': ''
    },
    {
        'title': 'YouTube (delete history)',
        'selected': ''
    },
    {
        'title': 'Block Sites',
        'selected': ''
    },
    {
        'sec': 'Community'
    },
    {
        'title': 'Unsupportive friends',
        'selected': ''
    },
    {
        'title': 'Discord',
        'selected': ''
    },
    {
        'title': 'Steam',
        'selected': ''
    },
    {
        'title': 'Twitch, Mixer',
        'selected': ''
    },
    {
        'title': 'Subreddits',
        'selected': ''
    },
]


 const Hard= [
    {
        'title': 'PC',
        'selected': ''
    },
    {
        'title': 'Console, VR',
        'selected': ''
    },
    {
        'title': 'Mobile',
        'selected': ''
    },
    {
        'title': 'Soundtracks / Music',
        'selected': ''
    },
    {
        'sec': 'Online Content'
    },
    {
        'title': 'Clear Cache',
        'selected': ''
    },
    {
        'title': 'YouTube (delete history)',
        'selected': ''
    },
    {
        'title': 'Block Sites',
        'selected': ''
    },
    {
        'title': 'Netflix',
        'selected': ''
    },
    {
        'sec': 'Community'
    },
    {
        'title': 'Unsupportive friends',
        'selected': ''
    },
    {
        'title': 'Discord',
        'selected': ''
    },
    {
        'title': 'Steam',
        'selected': ''
    },
    {
        'title': 'Twitch, Mixer',
        'selected': ''
    },
    {
        'title': 'Subreddits',
        'selected': ''
    },
    {
        'sec': 'Smartphone'
    },
    {
        'title': 'Gamified Apps',
        'selected': ''
    },
    {
        'title': 'Non-homework/work apps',
        'selected': ''
    },
    {
        'title': 'Social media',
        'selected': ''
    },
    {
        'title': 'Block Apps',
        'selected': ''
    },
]

    const ModeDropdown = [
        {
            'title': 'Games-Free Mode',
            'value': 'easy'
        },
        {
            'title': 'Games and Streams Mode',
            'value': 'medium'
        },
        {
            'title': 'Hard Mode',
            'value': 'hard'
        },
    ];

    const [data, setData] = useState({
        answers: {
            mode: '',
            newMode: '',
            triggers: '',
            checklist: {
                easy: Easy,
                medium: Medium,
                hard: Hard
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    
      api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res);
            setId(res.data._id);
            setDone(res.data.completed);
                    // ONLY FOR THIS LESSON TO GET SELECTED MODE abstinence-type

            if(res.data.completed === false){

                api.get(`/lessons/answers/abstinence-type`).then((res) => {
                    // console.log(res.data)
                    setUi({
                        ...ui,
                        oldMode: res.data.answers.selectedMode
                    });
                    // setData({
                    //     ...data,
                    //     answers: {
                    //         ...data.answers,
                    //         mode: res.data.answers.selectedMode
                    //     }
                    // })
                }, (err) => {
                    console.log(err)
                }
                );
            }
        }, (err) => {
            console.log(err)
        });

    }, []);

    const handleChange = (e) => {
     let section = e.target.dataset.section;
     let value = e.target.value;
    console.log(value);
     if(section === 'triggers'){
         setData({
             ...data,
             answers: {
                 ...data.answers,
                 triggers: value
             }
         })
     }
     if(section === 'dropdown'){
        setData({
            ...data,
            answers: {
                ...data.answers,
                newMode: value
            }
        })
    }
     
    };


    const handleCheckbox = (e) => {
        setUi({
            ...ui,
            saved: false
        });
        let section = e.target.dataset.section;
        let index = e.target.dataset.idx;
        let checked = e.target.checked;
        console.log(section);
        console.log(index);
        console.log(checked);


        if(section === 'easy'){
            let newArr = data.answers.checklist.easy;
            newArr[index].selected = checked;
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    checklist: {
                        ...data.answers.checklist,
                        easy: newArr
                    }
                }
            })
        }
        if(section === 'medium'){
            let newArr = data.answers.checklist.medium;
            newArr[index].selected = checked;

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    checklist: {
                        ...data.answers.checklist,
                        medium: newArr
                    }
                }
            })
        }
        if(section === 'hard'){
            let newArr = data.answers.checklist.hard;
            newArr[index].selected = checked;
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    checklist: {
                        ...data.answers.checklist,
                        hard: newArr
                    }
                }
            })
        }
    }


    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    }

    const EasyChecklist = () => (
        
<>
<p className="title has-text-success">Games-Free Mode</p>
{data.answers.checklist.easy.map((item, index) => (
            <div key={index}>
                {item.sec ? 
                <>
               <hr/>
               <p className='title is-5'>{item.sec}</p>
               <hr/>
                </>
                :
                <label className="checkbox is-size-5">
  <input onChange={handleCheckbox} checked={item.selected} data-section='easy' data-idx={index} type="checkbox"/>
     <span className="ml-3">{item.title}</span>
</label>
            }
            </div>
        ))}
</>
    )



    const MediumChecklist = () => (
        
        <>
        <p className="title has-text-info">Games and Streams Mode</p>
        {data.answers.checklist.medium.map((item, index) => (
                    <div key={index}>
                        {item.sec ? 
                        <>
                       <hr/>
                       <p className='title is-5'>{item.sec}</p>
                       <hr/>
                        </>
                        :
                        <label className="checkbox is-size-5">
          <input data-section='medium' data-idx={index} type="checkbox"/>
             <span className="ml-3">{item.title}</span>
        </label>
                    }
                    </div>
                ))}
        </>
            )


            const HardChecklist = () => (
        
                <>
                <p className="title has-text-primary">Hard Mode</p>
                {data.answers.checklist.hard.map((item, index) => (
                            <div key={index}>
                                {item.sec ? 
                                <>
                               <hr/>
                               <p className='title is-5'>{item.sec}</p>
                               <hr/>
                                </>
                                :
                                <label className="checkbox is-size-5">
                  <input data-section='hard' data-idx={index} type="checkbox"/>
                     <span className="ml-3">{item.title}</span>
                </label>
                            }
                            </div>
                        ))}
                </>
                    )


    const CheckList = () => {

    const newmode = data.answers.newMode;
    const mode = ui.oldMode;

    let current;

    if(newmode !== ''){
        current = newmode
    } else {
        current = mode
    }

    switch (current) {
        case 'easy':
            return <EasyChecklist />
            break;
    
        case 'medium':
            return <MediumChecklist />
            break;

        case 'hard':
            return <HardChecklist />
            break;        
        default:
            break;
    }

 


    //    let FindCheckList = (name) => {

    //     if(name === 'easy'){
    //         return <EasyChecklist />
    //     }

    //    };

    //     if(newmode !== ''){

    //         return FindCheckList(newmode);

    //     } else {
    //         return FindCheckList(mode);
    //     }

    };


    const content = () => {

        return (
            <>

                <p>Welcome back to Respawn. Yesterday you chose your mode of abstinence and today you will take steps to secure your boundaries.</p>
                <p>Gaming is more accessible than ever. It&#39;s available on your devices, it&#39;s free, and there is a large community and culture around it. It can be challenging to fully escape &#39;gaming&#39;. Unless you fully disconnect and move into the woods, you are likely to be exposed to gaming during your 30-day break and that can trigger emotions and cravings.</p>
                <p>If you find yourself feeling triggered at any time, use our Emergency Button.</p>
                <p>Although it can be challenging to avoid triggers, you will set yourself up for success by limiting and reducing your exposure. And that&#39;s what we will do today by setting boundaries around gaming based on your Abstinence Type.</p>
                <blockquote>
                    <p><strong>Beware of the following triggers:</strong></p>
                    <ol>
                        <li>Anything game related — this includes gaming environments, game releases, gaming forums, gaming streams, chatting with your gamer friends on Discord and Steam, gaming commercials, and so on.</li>
                        <li>Stress or anxiety — gaming is often a way for you to escape from these emotions, so be careful anytime you are feeling heightened levels of stress or anxiety. Often it&#39;s in these moments when our Rationalizationscan be strongest.</li>
                        <li>Boredom — gaming is often your go-to activity whenever you are bored, have free time, or when you have a desire for stimulation. If you need new activity ideas, use the Hobby Tool.</li>
                    </ol>
                </blockquote>

                <div className="field">
  <label className="label">What triggers do you need to be most aware of?</label>
  <div className="control">
    <textarea onChange={handleChange} data-section='triggers' className="textarea" value={data.answers.triggers}></textarea>
  </div>
</div>

<p>Next, we are going to take action to set boundaries around gaming and related technology. Yesterday, you chose 
        {ui.oldMode === 'easy' ? <span className="has-text-weight-bold has-text-success"> Games-Free Mode</span> : <> </>}
        {ui.oldMode === 'medium' ? <span className="has-text-weight-bold has-text-info"> Games and Streams Mode</span> : <> </>}
        {ui.oldMode === 'hard' ? <span className="has-text-weight-bold has-text-primary"> Hard Mode</span> : <> </>}
        , so we have preselected this for you. However, feel free to complete a different checklist.
         </p>

         <div className="field">
  <label className="label">Select your mode and complete the checklist below:</label>
  <div className="control">
    <div className="select">
      <select onChange={handleChange} data-section='dropdown' value={data.answers.newMode === '' ? ui.oldMode : data.answers.newMode}>
            {
                ModeDropdown.map((item, index) => (
                    <option key={index} value={item.value}>{item.title}</option>
                ))
            }
      </select>
    </div>
  </div>
</div>

<hr />

{CheckList()}


{
    data.answers.newMode !== '' || ui.oldMode !== '' ?
    ui.saved !== true ?
    <>
 
<hr/>
<blockquote>
                   If you want to quit gaming forever you may also want to delete your accounts. This can feel intense and like a break-up - that's normal and part of the process.
                   </blockquote>

<button onClick={ClickSubmit} className="button is-primary mt-4">Save Changes</button>
    </>
:
null
    :
    <>
    </>
}
<br/>
{
    ui.saved ?
    <>
<p>Ok great. Good job! If at any point along this journey you find yourself feeling triggered by something specific, come back here or use the guides for our other modes.</p>
    <p>Remember, to take a break from gaming is to take a step forward in dealing with your life situation instead of continuing to avoid it. The truth is, when we avoid things we may be able to drown out our situation with gaming, but it doesn’t actually fix why we were feeling the way we were in the first place. It’s only a matter of time before these feelings resurface.</p>
<p>Today you have taken decisive action in setting your break up for success. Pat yourself on the back. You&#39;re doing great.</p>
<h1 id="faq-">FAQ:</h1>
<h3 id="if-i-want-to-get-rid-of-my-gaming-consoles-what-should-i-do-">If I want to get rid of my gaming consoles, what should I do?</h3>
<ul>
<li>Sell them</li>
<li>Give them to someone else to hold for you</li>
<li>Put them in a box and hide them away</li>
<li>Donate them (Gamers Outreach, AbleGamers)</li>
</ul>
<h3 id="what-if-i-want-to-change-my-mode-">What if I want to change my mode?</h3>
<ul>
<li>You may choose your mode at any time, however we recommend for you to complete the 30-day break with your chosen mode first if possible. It is normal for emotions to come up and challenges to appear. These are opportunities for you to learn more about yourself, work through your anxieties, and make overcome challenges to become the best version of yourself.</li>
<li>If you would still like to change your mode, we recommend to return to <a href="https://www.notion.so/Abstinence-Type-380de4cb7695410ea51a80e165aa9c33">Abstinence Type</a> and go through the lesson again.</li>
</ul>
<h3 id="do-you-want-to-talk-to-someone-about-this-process-">Do you want to talk to someone about this process?</h3>
<ul>
<li>Use the live chat button on the bottom right or send us an email (link to <a href="mailto:cam@gamequitters.com">cam@gamequitters.com</a>)</li>
</ul>

<br/>
<button onClick={FinishLesson} className="button is-primary mt-2 has-text-weight-bold">Finish Today</button>

    </>
    : null
}


            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                </div>

            </LessonLayout>
        </>
    )

}

export default Degaming;