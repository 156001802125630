import React, { createContext, useReducer } from 'react';
// import { useCookies } from 'react-cookie';
import api from '../utils/api';


export const AuthContext = createContext();



const initialState = {
    isAuthenticated: false,
    token: null,
    isLoading: true,
    user: {
        agreed: false,
        created_at: "",
        updated_at: "",
        loginType: "",
        email: "",
        username: "",
        firstname: "",
        lastname: "",
        streak: null,
        profile: {
            coverImg: "https://res.cloudinary.com/cinemakers/image/upload/v1591604298/gq/hrb70igvmvcfnrynqdk8.jpg",
            profilepic: {
                small: "https://res.cloudinary.com/cinemakers/image/upload/v1588152942/gq/nl6vlkm3msienkq1jkfk.jpg",
                original: "https://res.cloudinary.com/cinemakers/image/upload/v1588152942/gq/nl6vlkm3msienkq1jkfk.jpg"
            }
        }
}
};


export const AuthReducer = (state, action) => {
    switch (action.type) {

        case "SETLOADING":
            return {
                ...state,
                isLoading: action.payload.loading
            }

        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload.payload,
                token: action.payload.data.token,
            };

        case "LOGOUT":

            console.log("Logout stuff");
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                isLoading: false,
            };

        case "COOKIELOGIN":

            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
                isLoading: false,
            };

        case "UPDATEACCOUNT":

            return {
                ...state,
                user: action.payload,
                isLoading: false,
            };
 
        case "UPDATESTREAK":
            
            return {
                ...state,
                user: {
                    ...state.user,
                    streak: action.payload,
                    isLoading: false,
                }
            }

        default:
            return state;
    }
}

export const AuthContextProvider = (props) => {

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    let AuthData = {
        state,
        dispatch
    }

    return <AuthContext.Provider value={AuthData}>

        {props.children}

    </AuthContext.Provider>

};
