import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import LoggedLayout from '../../components/LoggedLayout';
import Cover from './Cover';
import Profile from './ProfilePic';
import StreakWidget from '../../components/StreakWidget';
import EmergencyWidget from '../../components/EmergencyWidget';
import JournalIndex from '../Journal/JournalIndex';
import JournalTable from '../../components/JournalTable';
import LessonsWidget from '../../components/LessonsWidget';


const Hub = () => {

  const { state, dispatch } = useContext(AuthContext);
  let coverimg = state.user.profile.coverImg || "";
  let profilepic = state.user.profile.profilepic.small || state.user.profile.profilepic.original || ""

  return (
    <>
      <LoggedLayout>
        <Cover CoverImg={coverimg} />
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
            <Profile ProfilePic={profilepic} />
              <div className="columns is-centered">
                <div className="column is-3">
                  <div className="box box-border">
                    <StreakWidget Dispatch={dispatch} Streak={state.user.streak} />
                  </div>
                  <div className="box box-border">
                    <EmergencyWidget />
                  </div>
                </div>
                <div className="column is-9">
                <div className="box box-border">
                   <LessonsWidget Agreed={state.user.agreed} Admin={state.user.admin} />
                  </div>
                  <div className="box box-border">
                    <JournalTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoggedLayout>
    </>
  )
}

export default Hub;