import React from 'react';
import LoggedLayout from '../../components/LoggedLayout';
import JournalIndex from './JournalIndex';
import JournalEdit from './JournalEdit';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';


const Journal = () => {

    let match = useRouteMatch();
    console.log(match);

    return (
        <>
        <LoggedLayout>

        <Switch>
        <Route path={`${match.path}/edit/:id`}>
           <JournalEdit />
        </Route>

        <Route path={`${match.path}/view/:id`}>
           view
        </Route>

        <Route path={match.path}>
           <JournalIndex />
        </Route>
        </Switch>
     

        </LoggedLayout>
        </>
    )
}

export default Journal;