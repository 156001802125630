import React, { useContext, useState, useEffect } from 'react';
// import Logo from '../../static/gq_logo_full.png';
// import Icon from '../../static/gq_logo.png';
import Logo from '../../static/gq_logo_full.png';
import Icon from '../../static/gq_logo.png'
import { Helmet } from "react-helmet";

import { AuthContext } from '../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useHistory, Link } from 'react-router-dom';

import api from '../../utils/api';

const Recover = () => {

    const [ui, setUI] = useState({
        email: '',
        sent: false,
        loading: false,
        disabled: false,

    })


    const handleSubmit = async (e) => {

        e.preventDefault();
        setUI({
            ...ui,
            loading: true,
            disabled: true,

        })

        await api.post('/forgot', { email: ui.email }).then((res, err) => {
            console.log('done')
            setUI({
                ...ui,
                loading: false,
                sent: true,
                disabled: false
            })
        });

    }

    const handleEmailChange = (e) => {
        setUI({
            ...ui,
            email: e.target.value,
            sent: false,
            loading: false,
        });
    };


    return (
        <>
            <section className="hero is-light is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">

                            <div className="column is-5">

                                <div className="has-text-centered mb-3">
                                    <img style={logoStyle} src={Logo} />
                                </div>

                                <div className="mb-4">
                                    <p className="title has-text-centered is-4">Let's help you get your account back</p>
                                </div>
                                <div className="box">

                                    <form onSubmit={handleSubmit}>
                                        <div className="field">
                                            <label className="label">Email</label>
                                            <div className="control">
                                                <input disabled={ui.disabled} onChange={handleEmailChange} name="email" className="input" type="email" placeholder="name@example.com" />
                                            </div>
                                        </div>
                                        <div className="field">

                                            <div className="control has-text-centered">
                                                {
                                                    ui.loading ?
                                                        <button className={`button is-primary is-fullwidth ${ui.loading ? `is-loading` : ``}`}></button>
                                                        :
                                                        <input className={`button is-primary is-fullwidth`} type="submit" value="Request Reset Link" />
                                                }
                                            </div>
                                        </div>
                                    </form>

                                    {
                                        ui.sent ?
                                            <p className="has-text-weight-bold has-text-centered has-text-primary mt-3 ">If you entered a valid email address, you will receive a password reset link in your inbox. For security reasons, the link will expire in 24 hours.</p>
                                            :
                                            null
                                    }

                                    <div className="mt-5">
                                        <Link to="/">back</Link>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )

}

const logoStyle = {
    maxWidth: "300px"
}

export default Recover;