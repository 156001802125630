import React from 'react';
import Navbar from './Navbar';


const LoggedLayout = ({children}) => {

    
    return (
        <>
        <Navbar />
        {children}
        
        </>
    )
}

export default LoggedLayout;