import React, { useState } from 'react';
import logo from '../static/gq_logo_full.png';
import api from '../utils/api';
import initLessons from '../utils/initLessons';
import {useHistory} from 'react-router-dom';


const DisclaimerModal = ({ Dispatch }) => {

    let history = useHistory();
    const initState = {
        isStarted: false,
        option: null,
        agreed: false,
        suggestion: false,
        finished: false,
    };

    const [ui, setUI] = useState(initState);
    const [sugg, setSugg] = useState("")


    const clickStarted = () => {
        setUI({
            ...ui,
            isStarted: true
        });
    };

    const changeSugg = (e) => {
        setSugg(e.target.value);
    }


    const clickAnAgree = (e) => {
        let option = e.target.dataset.option;


        switch (option) {
            case '1':
                setUI({
                    ...ui,
                    option: option,
                    agreed: true
                });
                fireAgreeAPI(true);
                break;

            case '2':
                setUI({
                    ...ui,
                    option: option,
                    agreed: false,
                    suggestion: true
                });
                break;

            case '3':
                setUI({
                    ...ui,
                    option: option,
                    agreed: true,
                    suggestion: true

                });
        }


    }


    const fireAgreeAPI = async (agreed) => {


        let pl = {
            agreed: agreed,
            suggestion: sugg
        }

        console.log(pl)

        api.post('/updateAgreed', pl).then((res) => {
            console.log(res.data);
            setUI({
                ...ui,
                finished: true,
                suggestion: false
            });
            Dispatch({
                type: "UPDATEACCOUNT",
                payload: res.data
            });
            if(pl.agreed === true){
                history.push('/lessons/welcome');
            }
        }, (err) => {
            console.log(err)
        });

    }


    const onSubmitSugg = () => {

        fireAgreeAPI(ui.agreed)

    };


    const Content = () => {
        return (
            <>
            <div className="pl-4 pr-4 pt-4 pb-4">
            <div className="content is-size-5 has-text-left">
                    <h1>Disclaimer</h1>
                    <p>Before you begin the Respawn program, let&#39;s agree on a few basics. </p>
                    <p>For this version of Respawn, we&#39;ve chosen to use this basic agreement. It&#39;s a simple contract between yourself (the &quot;user&quot;) and us (the &quot;creator&quot;). As it&#39;s just a deal between us humans we&#39;ve attempted to write it humanely. It&#39;s not written as a legal document. There&#39;s also the more comprehensive Terms and Conditions of Service for Game Quitters which apply to Respawn.</p>
                    <p><a href="https://gamequitters.com/tos/">Terms and Conditions of Service | Game Quitters</a></p>
                    <p>By proceeding with using Respawn, you&#39;ll be <strong>licensed to use the program</strong>. We&#39;re not selling it to you, but you can use it for yourself as long as you license it.  Please don&#39;t sell your license. </p>
                    <p>As part of this agreement, you&#39;re not allowed to sell, duplicate, reverse engineer, or otherwise <strong>make money off of our hard work.</strong> We made this for you, and charge a fair price so that we can continue to make it. </p>
                    <p>If you agree to our terms but then violate our deal, then <strong>we may cancel your license.</strong> That&#39;s fair. If we think your license should be terminated, then we&#39;ll do the human thing and talk to you about it first.</p>
                    <p>We worked hard on this product, but it&#39;s not perfect. <strong>You&#39;re responsible for how you use the product, and we cannot guarantee any specific results.</strong> Like us, you&#39;re a complex human with more variables for which we couldn&#39;t possibly account. Use our product at your own risk. </p>
                    <p>Our program comes &quot;as is.&quot; <strong>If you&#39;d like a refund, then we&#39;re happy to consider, but please make your request before the end of the program.</strong> We know it&#39;s not for everyone, but if you go all the way to the end, then we assume you&#39;ve enjoyed some value. </p>
                    <p>Please, don&#39;t take our stuff! We made it. All of it. From the graphics to the process and the text. It took a long time. <strong>If you&#39;d like to use it for anything other than your own purposes, then please reach out.</strong> We love collaborating! </p>
                    <p>By using this site, you signify your assent to our <a href="https://gamequitters.com/tos/">Terms and Conditions</a>, including that the information on this site is written for <strong>general informational purposes</strong> and is <strong>not providing medical advice</strong>. We <strong>do not diagnose and prescribe</strong>. You are responsible for using your own judgement when reading this material, and if you are at any point concerned for your health or well-being, please visit your nearest physician or emergency room immediately.</p>
                    <p>If you have any questions, then please email us or use the chat feature. </p>
                </div>

                <p><button onClick={clickAnAgree} data-option={1} className="mt-2 button is-primary is-size-5 has-text-weight-bold is-fullwidth">I agree</button></p>
                <p><button onClick={clickAnAgree} data-option={2} className="mt-2 button is-primary is-size-5 has-text-weight-bold is-fullwidth is-outlined">I'm not sure I agree</button></p>
                <p><button onClick={clickAnAgree} data-option={3} className="mt-2 button is-primary is-size-5 has-text-weight-bold is-fullwidth is-outlined">I agree and I have a suggestion</button></p>


            </div>
            </>

        )
    }


    const Start = () => {

        return (
            <>
                <img style={{ width: '250px' }} src={logo} />
                <h1 className="title mt-4">Welcome to Respawn!</h1>
                <button onClick={clickStarted} className="button is-primary is-size-5 has-text-weight-bold">Get Started</button>
            </>
        )
    };

    const Test = () => {

        return (
            <div className="field">
                <label className="label">Let us know</label>
                <div className="control">
                    <textarea onChange={changeSugg} className="textarea" value={sugg}></textarea>
                </div>
                <button onClick={onSubmitSugg} className="button is-primary mt-3 has-text-weight-bold">
                    Submit
        </button>
            </div>
        )
    }


    return (
        <>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content">
                    <div>
                        <div className="box has-text-centered">
                            {
                                ui.isStarted && ui.option === null ?
                                    Content()
                                    :
                                    ui.suggestion ?
                                        <>
                                            {Test()}
                                        </>
                                        :
                                        ui.finished === true ?
                                            <>
                                                <img style={{ width: '200px' }} src={logo} />
                                                <p className="title is-5 mt-2">Thank you for your interest in Game Quitters. Sorry we cannot accomodate you at this time. Please visit our main website at <a className="has-text-primary" href="https://www.gamequitters.com" target="_blank">https://www.gamequitters.com</a> for more resources.</p>
                                            </>
                                            :
                                            <Start />

                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default DisclaimerModal;