import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import api from '../../../utils/api';
import gamertypes from '../../../static/gamertypes.png';

const Hobbyvsproblem = ({Slug, Day, State, Dispatch}) => {

    const title = 'Gaming Hobby vs. Gaming Problem';
    const history = useHistory();
    const [idx, setIdx] = useState(null); 
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    
    useEffect(() => {
        window.scrollTo(0,0)
        api.get(`/lessons/answers/${Slug}`).then((res) => {
          console.log(res)
          if(res.data.answers){
            setData({
              ...data,
              answers: res.data.answers
            })
          }
        }, (err) => {
          console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            setId(res.data._id)
            setDone(res.data.completed)
          }, (err) => {
            console.log(err)
          });
    }, []);
    

    const [data, setData] = useState({
        answers: {
            thinking: false,
            bored: false,
            increased: false,
            playless: false,
            loseinterest: false,
            continued: false,
            deceptive: false,
            escape: false,
            relationship: false,
            score: 0,
            gamerType: null
        }
    });

    let answers = data.answers;
    
    const [score, setScore] = useState(0);
    const [results, setResults] = useState(false);
    const [type, setType] = useState('')
    
    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        
        setData({
            ...data,
            answers: {
                ...data.answers,
                [name]: value
            }
        });
    
    };
    

    const FinishLesson = async() => {

        if(done === false){

            let complete = {
              id: id,
              completed: true,
              timestamp: new Date()
            }
            console.log(complete);
        
            api.post(`/lessons/complete`, complete).then((res) => {
              console.log(res)
                history.push('/lessons/done')
        
            }, (err) => {
              console.log(err)
            });
            
        
          } else {
            history.push('/lessons/done')
          }
      
      }
    
    const countBools = async() => {
    
    let array = [
        answers.thinking,
        answers.bored,
        answers.increased,
        answers.playless,
        answers.loseinterest,
        answers.continued,
        answers.deceptive,
        answers.escape,
        answers.relationship
    ]
    
    let counted = array.filter(Boolean).length;
    
    if(counted <= 2){
        console.log('casual')
        setType('casual')
    }
    if(counted > 2 && counted < 5){
        console.log('risk')
        setType('risk')
    }
    if(counted >= 5){
        console.log('problem')
        setType('problem')
    } 
    
   await setScore(counted);
    
   await setResults(true);
    
   await setData({
        ...data,
        answers: {
            ...data.answers,
            score: counted
        }
    });

  let payload = {
    answers: data.answers
}

await api.put(`/lessons/answers/${Slug}`, payload).then((res) => {
console.log(res)

}, (err) => {
console.log(err)
});
    
    };
    
    
    const casual = () => {
        return (
            <>
    
    <h2 id="you-may-be-a-casual-gamer">You may be a casual gamer</h2>
    <p>Your score did not meet criteria for a severe gaming problem. You may be playing games for several hours a day but your life is generally in order. You play games as a hobby, but you&#39;re not sacrificing your life for gaming.</p>
    <p>You will still fulfill your responsibilities for the day, whether that&#39;s going to school or going to work, or other chores or basic necessities you may have. You probably still engage at home, have friends you hang out with in the physical world, other hobbies and interests, and take care of your basic hygiene.</p>
    <p>You may want to play more... but being irritable or moody when you can&#39;t play is not common. Gaming isn&#39;t impacting your ability to function as a normal human being. You may feel like you are not reaching your full potential, but as long you keep gaming in control it doesn&#39;t have to be a bad thing.</p>
    <p>If this sounds accurate then just be mindful to watch out for these red flags:</p>
    <ul>
    <li>Being irritable or moody when you can’t play</li>
    <li>Constantly needing to play more and more (“it’s never enough”)</li>
    <li>Losing interest in other activities (e.g. sports, exercise)</li>
    <li>Being deceptive (e.g. stealing money to buy things in game)</li>
    <li>Jeopardizing school, work, and/or relationships (in order to game)</li>
    </ul>
    <p>These are important red flags to be aware of and if you begin to notice them appearing more regularly then you will want to take action to adjust it. Continuing to work through Respawn will help you ensure you have the right tools and habits to ensure gaming remains a fun hobby and not a problem, so stick with us.</p>
    <p>Below you will find a table that shares some examples of a gaming hobby vs. a gaming problem.</p>
    <p>If being a casual gamer does not sound like you, and you feel like your gaming is a problem, then click here.</p>
    
    
            </>
        )
    }
    
    
    const risk = () => {
    
        return (
            <>
            <h2 id="you-may-be-an-at-risk-gamer">You may be an at-risk gamer</h2>
    <p>Your score met criteria for at-risk gaming. You may be playing games for several hours a day but it&#39;s beginning to impact different areas of your life like your school, work, hygiene and/or relationships.</p>
    <p>You tend to want to play more and more while putting less effort into being productive, and when you can&#39;t play you feel irritable and moody. You are beginning to (or have already) lose interest in other hobbies and activities, and are becoming increasingly isolated from physical friends and family.</p>
    <p>Deep down you may feel like you are beginning to lose yourself to the virtual world, and maybe you don&#39;t understand why. Is it just a phase?</p>
    <p>If this sounds accurate and you have been seeing the following red flags for some time, then it&#39;s not a phase. You are seeing a repeated pattern of behavior that is telling you something is wrong. Your gut is usually right!</p>
    <ul>
    <li>Being irritable or moody when you can’t play</li>
    <li>Constantly needing to play more and more (“it’s never enough”)</li>
    <li>Losing interest in other activities (e.g. sports, exercise)</li>
    <li>Being deceptive (e.g. stealing money to buy things in game)</li>
    <li>Jeopardizing school, work, and/or relationships (in order to game)</li>
    </ul>
    <p>If you are reading this and realizing that your gaming is, in-fact, risky, then you are in the right place. In Respawn we will support you to get your life back on track through a carefully crafted series of lessons and exercises to come. Don&#39;t worry, we&#39;ve got your back!</p>
    <p>Below you will find a table that shares some more practical examples of a gaming hobby vs. a gaming problem.</p>
    
            </>
        )
    }
    
    
    const problem = () => {
        return (
            <>
            <h2 id="you-may-be-a-problem-gamer">You may be a problem gamer</h2>
    <p>Your score met criteria for a gaming problem. So you&#39;re probably wondering what that means. The good news is that you are in the right place and Respawn is here to support you in turning things around. Maybe you&#39;ve been in denial and haven&#39;t wanted to face it, but it&#39;s time. You may feel a lot of guilt that you allowed it to get to this point (and that&#39;s normal.)</p>
    <p>Having a gaming problem can be very stressful. You&#39;re having a lot of arguments over gaming with loved ones and finding yourself increasingly isolated from those around you. Maybe you hoped it was just a phase and would eventually correct itself but it has only continued to get worse. I want you to know that many others have been in a similar place before.</p>
    <p>No matter how you feel in this moment, know that improvement is possible and you can get better. It&#39;s not going to be easy and it will take hard work and focus on your end, but you can succeed and we are here to support you through it. Never give up hope.</p>
    <p>Continue to work through the Respawn program and we will help you develop the right tools and habits to live a meaningful life. For today, you can find a table below that shares practical examples of a gaming hobby vs. a gaming problem.</p>
    
            </>
        )
    }
    
    
    const scoreText = () => {
        return (
            <blockquote>
                <p>Great work! Thanks for completing the exercise. Your score is <strong>{score}</strong> which means you:</p>
            </blockquote>
        )
    }
    
    
    const content = () => {
        return (
    
    <>
    <blockquote>
    <p><em>&quot;You can only start from where you are.&quot; -Anonymous</em></p>
    </blockquote>
    <p>Welcome back to Respawn. Today you are going to learn about the state of your gaming. Is it a problem or just a hobby? And how do you spot the difference?</p>
    <p>Gamers fall on a spectrum. Some people play because it&#39;s a fun hobby and others play because they cannot stop. For the latter group of gamers, it&#39;s no longer a hobby, it&#39;s a compulsion. So today we are going to discover more about your gaming and where you fall on the spectrum. We will help you discover that through a series of questions below. We invite you to answer each question honestly. Remember, there is no shame in your answers. They only serve as an opportunity to reflect and inspire you to take positive action forward.</p>
    <p>Let&#39;s begin.</p>
    <blockquote>
    <p>Mindfulness exercise: Sit upright in your chair, close your eyes, and
    find your breath. Give your toes a wiggle. Now re-open your eyes and
    proceed.</p>
    </blockquote>
    
    </>
    
        )
    }
    
    
    const succ = () => {
        return (
            <>
    
        <button onClick={FinishLesson} className="button is-primary">Finish lesson</button>
            </>
        )
    }
    
    
    const GamerType = () => {
    
        return (
            <>
    <blockquote>
    <p>The following is a list of common differences between someone who
    plays video games as a hobby and someone who plays because it’s a
    problem. This is an informal list and is not meant to diagnose a
    disorder. For a proper assessment, please seek the support of a
    professional.</p>
    </blockquote>
    <img src={gamertypes} />
    <blockquote>
    <p>Mindfulness exercise: Sit upright in your chair, close your eyes, and
    find your breath. Give your toes a wiggle. Now re-open your eyes and
    proceed.</p>
    </blockquote>
    <p>Today you have learned the difference between normal gaming and problematic gaming. Whatever the state of your gaming, the key is that you are here and taking steps to improve your situation. Respawn is a shame-free zone. There is no judgement here and our only intention is to support you in making positive progress in your life.</p>
    
            
    
            </>
        )
    }
    



return (
<>

<LessonLayout Slug={Day} Title={title}>


    <div className="content content-text">
        {content()}
        <p>The following set of questions are part of an informal screening process and is not a tool for diagnosis. For a proper assessment, please seek the help of a professional.</p>        

    </div>

    <br />
    <div className="field">
  <label className="label">Mark each answer as a yes by checking the box. If your answer is no, leave it blank.</label>

</div>


<div className="notification">
<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.thinking} name='thinking' type="checkbox" />
 &nbsp; Do you spend a lot of time thinking about games even when you're not playing, or planning when you can play next?
</label>
</div>
<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.bored} name='bored'  type="checkbox" />
 &nbsp; Do you feel restless, irritable, moody, angry, anxious, bored, or sad when you try to cut down or stop gaming, or when you are unable to play?
</label>
</div>

<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.increased} name='increased' type="checkbox" />
 &nbsp; And do you feel the need to play for increasing amounts of time, play more exciting games, or use more powerful equipment to get the same amount of excitement you used to get?
</label>
</div>

<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.playless} name='playless' type="checkbox" />
 &nbsp; Have you felt you should play less, but are unable to cut back on the amount of time you spend playing games?
</label>
</div>

<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.loseinterest} name='loseinterest' type="checkbox" />
 &nbsp; And have you lost interest—or reduced participation—in other recreational activities due to gaming?
</label>
</div>


<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.continued} name='continued' type="checkbox" />
 &nbsp; Have you continued to play a game even though you knew about the negative consequences, such as not getting enough sleep, being late to school/work, spending too much money, having arguments with others, or neglecting important duties?
</label>
</div>

<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.deceptive} name='deceptive' type="checkbox" />
 &nbsp; Have you been deceptive, or lied to family, friends, or others about how much you game? Or tried to keep your family or friends from knowing how much you game?
</label>
</div>


<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.escape} name='escape' type="checkbox" />
 &nbsp; Do you game to escape from or forget about personal problems, or to relieve uncomfortable feelings such as guilt, anxiety, helplessness, or depression?
</label>
</div>

<div className="field">
<label className="checkbox is-size-5">
  <input onChange={handleChange} checked={answers.relationship} name='relationship' type="checkbox" />
 &nbsp; And finally, have you risked or lost significant relationship, job, educational, or career opportunities because of gaming?
</label>
</div>

<br />

<div className="field">
<button onClick={countBools} className="button is-primary">Calculate Score</button>
</div>
</div>

<br />
{
    results ?
    <>
<div className="content content-text">
    {scoreText()}
    </div>
{
    type === 'casual' ?
    <div className="content content-text">
    {casual()}
    </div>
    :
    <>
    </>
}

{
    type === 'risk' ?
    <div className="content content-text">
    {risk()}
    </div>
    :
    <>
    </>
}

{
    type === 'problem' ?
    <div className="content content-text">
    {problem()}
    </div>
    :
    <>
    </>
}

<>
<div className="content content-text">
    {GamerType()}
    </div>
<div>
    <br />
    {succ()}
</div>
  
</>

</>


    :

    <>
    </>

}




<br />





</LessonLayout>
</>
   

    )
}

export default Hobbyvsproblem
