// Just a starter for each lesson

import React, { useContext, useEffect, useState, useRef } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';
import Goals from '../../../components/Goals';


const Threshold = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Threshold';
    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            journals: {
                reservations: ''
            },
            decision: false
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: {
                    ...data.answers.journals,
                    [e.target.name]: e.target.value
                }
            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });

            history.push('/lessons/stumble-ready');

        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                // history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            // history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>Today is the day. The day you decide to stop gaming for 30 days.</p>
                <p>Up until today, you&#39;ve been gathering resources as you prepare to make the decision to stop gaming. You&#39;ve discovered your gaming type, assessed your life, set goals for stopping gaming and identified the benefits of living without games. All this preparation brings you to making a decision to cross the threshold from problematic gaming into living 30 days without gaming.</p>
                <p>You&#39;re also deciding to face trials and challenges. Without gaming you&#39;ll likely feel cravings and urges to watch game streams, isolate from people, and go back to gaming. That&#39;s normal and to be expected. You&#39;ll confront the dark side of your character; the Big Boss at the end of this level of your life. But... it&#39;s time to level up.</p>
                <p>As you make this decision to cross your threshold, you&#39;ll probably want to look towards the finish line, &quot;I can make it 30 days. I can get there. Keep my eyes on the prize.&quot; But as a gamer you know that you can&#39;t be sure of the challenges you&#39;ll experience between now and the end of the level. Take one day, one hour, at a time. Focus on the here; be now and stay with yourself. Embody your life and take one step at a time.</p>
                <p>We&#39;ve all made resolutions to change our behavior. Like New Years Eve where we resolve to return to the gym, quit smoking or eat better. What happens? Most people fail. Their willpower - that executive function of our human being - often doesn&#39;t last more than a few days. But why can&#39;t we push ourselves into change? Because we&#39;re not all powerful; we&#39;re not a god! We&#39;re humans that need allies, resources and connection.</p>
                <p>Now, before you make that decision, take a moment to write out any reservations you might have about stopping gaming. Reservations are conditions under which you think you couldn&#39;t stop for 30 days. Ask yourself what you fear could happen that might justify gaming again.</p>
                <div className="field">
                    <label className="label">Reservations</label>
                    <div className="control">
                        <textarea onChange={handleChange} name='reservations' className="textarea" value={data.answers.journals.reservations}></textarea>
                    </div>
                </div>
                <p>Now, take some time to reconnect with your <a href="/lessons/motivation-to-quit" target="_blank">Motivation to quit</a>, your <a href="/lessons/goals" target="_blank">Goals</a> and your <a href="/lessons/life-assessment" target="_blank">Life Assessment</a>. Find your motivation - you&#39;re purpose for pressing pause.</p>


                {/* <p><span className="tag is-primary">Beta</span> <strong>Add a goal with a due date.</strong> You can add as many as you'd like. You can revisit them on your profie at any time and we will remind you (via email) when you're getting close to the due date.</p>
<div className="ml-3">
<Goals />
</div> */}
<div className="has-text-centered mb-4">
    <p className="title">Time to make a decision</p>
<button onClick={()=>setData({...data, answers: {...data.answers, decision: true}})} className={`button is-primary has-text-weight-bold ${data.answers.decision ? `` : `is-outlined`}`}>Let's do this!</button>
<a href='mailto:cam@gamequitters.com' target='_blank' className="button is-info has-text-weight-bold ml-2 is-outlined">I'm unsure and need advice</a>
</div>

{
data.answers.decision ?
<>
<p>And into the unknown we go! Time for the adventure. Nothing risked, nothing gained.</p>
<p>Now over the next 30 days you may experience many different emotions and challenges. If at any time you are feeling triggered, on the verge of gaming again, or would simply like additional support, use the <strong>Emergency Button</strong>.</p>
<p>Over the coming days we will address challenges you may experience such as identifying signs of stress, developing new passions, navigating gamer friends and much more. Stay tuned!</p>

<button onClick={ClickSubmit} className="button is-primary">Next</button>

</>
:
null
}

            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                </div>

            </LessonLayout>
        </>
    )

}

export default Threshold;