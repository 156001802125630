import React, {useState, useEffect, useRef} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import api from '../utils/api';

const Emergency = () => {

    let defaultState = {
        buttonText: 'Emergency',
        buttonLoading: false,
        bar: false
    };

    let counter = 0;
    let timerinterval = useRef(null);

    const [ui, setUi] = useState(defaultState);

    const [ms, setMs] = useState(counter);

    let history = useHistory();


    const timer = (start) => {
        console.log('tick tock')
        console.log(start)
        if(start === true) {
            timerinterval.current = setInterval(() => {
                // console.log(counter);
                setMs(counter);
                counter += 1;
                if(counter > 300){
                    setUi({
                        ...ui,
                        buttonLoading: true
                    });
                    clearInterval(timerinterval.current);
                    api.get('/panicButton').then((res) => {

                        let alertId = res.data._id;
                        history.push(`/emergency/${alertId}`);

                    }, (err) => {
                        console.log(err)
                    })
                   
                }
            }, [10])
        } else {

            setMs(0)
        }
    };

    const pressingDown = (e) => {
        console.log('start')
        e.preventDefault();
        timer(true);
        setUi({
            ...ui,
            bar: true,
            buttonText: "Keep holding..."
            
        });

    }

    const notPressingDown = (e) => {
        console.log('stop')
        e.preventDefault();
        timer(false);
        clearInterval(timerinterval.current);
        setUi({
            ...ui,
            bar: false,
            buttonText: "Emergency"
        });
        
    }


    return (
        <>
              <p className="is-uppercase">
                    <strong>AVOID A RELAPSE</strong>
                    </p>
                    <button 
                    onMouseDown={pressingDown} 
                    onMouseUp={notPressingDown} 
                    onTouchStart={pressingDown}
                    onTouchEnd={notPressingDown}
                    className={`has-text-weight-bold button is-primary mt-3 is-fullwidth ${ui.buttonLoading ? `is-loading` : ``}`}>{ui.buttonText}</button>
                    <p className="help">Press and hold</p>
                    {ui.bar ?
                    <progress className="progress is-primary mt-4" value={ms} max={300}>15%</progress>
                    :
                    null
                    }
                    
        </>
    )
}

export default Emergency;