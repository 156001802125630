import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams } from 'react-router-dom';


const Expectations = ({ Slug, Day, State, Dispatch }) => {

    const title = 'Expectations';




    const content = () => {
        return (

            <>
                <p>Hello! Welcome to day two of Respawn.</p>
                <p>You&#39;re probably wondering what to expect from the process. Let&#39;s answer some of the obvious questions, and give an overview for what you&#39;ll be doing. Today, we&#39;ll review what you can expect from the program and from your own experience as you work through it.</p>
                <p>FIRST: If you&#39;re still gaming, we&#39;ll be working up to pressing pause on Day 15. We think of Respawn as a guide or ally on the path. We cannot walk the path for you but we&#39;ve been down this road. We can help you open the door to a new life but only you can walk through it. But first, let&#39;s look at how to Respawn.</p>
                <p>Respawn is a <strong>daily process.</strong> Rather than replacing gaming by intensely engaging in Respawn, you&#39;ll take about 20-30 minutes per day of your attention towards not gaming. Rather than crushing, binging or winning at not-gaming, it&#39;s about building new habits through regular action. It&#39;s a journey that takes preparation &amp; deliberate action.</p>
                <p>Pausing gaming is a big change in your life and as you step into the unknown you&#39;ll be faced with difficult challenges. That&#39;s to be expected. We&#39;ll help you get prepared. When you&#39;re really struggling, we&#39;ve got an &quot;Emergency&quot; button on the homepage to help you through that rough spot.</p>
                <p>...</p>
                <p>Respawn is an Alpha which means a work in progress. It&#39;s built on years of research and lived experience. Since you&#39;ve decided to join us at this alpha phase of development, we think of you as a co-creator of the program. We need to learn from you as you work through the program. Don&#39;t hesitate to send us feedback, ask questions, and of course, share your successes.</p>
                <blockquote>
                    <p>You can email us anytime at
&nbsp;<a href="mailto:beta@gamequitters.com">beta@gamequitters.com</a></p>
                </blockquote>

            </>

        )
    }





    return (
        <>

            <LessonLayout Slug={Day} Title={title}>


                <div className="content content-text">
                    {content()}
                </div>

                <br />

                <Link className="button is-primary has-text-weight-bold" to="/lessons/hobbyvsproblem">Proceed</Link>



            </LessonLayout>
        </>


    )
}

export default Expectations
