// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { SchoolCareer, FunRec, Physical, Growth, Money, Health, Friends, Family, Partner } from './assdata';
import api from '../../../utils/api';
import { Radar } from 'react-chartjs-2';

const LifeAss = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Life Assessment';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const scale = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false,
        disableScoreBtn: false,
        disableRadio: false
    });


    let scoresDefault = {
        school: 0,
        fun: 0,
        physical: 0,
        money: 0,
        growth: 0,
        health: 0,
        friends: 0,
        family: 0,
        partner: 0
    }

    const [data, setData] = useState({
        answers: {
            school: SchoolCareer,
            fun: FunRec,
            physical: Physical,
            growth: Growth,
            money: Money,
            health: Health,
            friends: Friends,
            family: Family,
            partner: Partner,
            scores: scoresDefault,
            journal: ''
        },
        
    });



    let maxVal = (arr) => {
        let total = parseInt(arr)*10;
        console.log(total);
        return total;
    };

    let counter = (arr) => {
        let count = 0;
        arr.map((item) => {
            console.log(item.value)
            count = count + parseInt(item.value);
        });
        return count;
    }
    
    let calcPerc = (count, max) => {

        let avg = count / max * 100;
        return avg
    };

    const handleCalculateScore = (e) => {
        e.preventDefault();

        let school = data.answers.school;
        let fun = data.answers.fun;
        let physical = data.answers.physical;
        let money = data.answers.money;
        let growth = data.answers.growth;
        let health = data.answers.health;
        let friends = data.answers.friends;
        let family = data.answers.family;
        let partner = data.answers.partner;

        // EVERY CALC

        let schoolCount = Object.keys(school).length;
        let schoolMax = maxVal(schoolCount);
        let schoolTotal = counter(school);
        let schoolPerc = calcPerc(schoolTotal, schoolMax);

        let funCount = Object.keys(fun).length;
        let funMax = maxVal(funCount);
        let funTotal = counter(fun);
        let funPerc = calcPerc(funTotal, funMax);

        let physicalCount = Object.keys(physical).length;
        let physicalMax = maxVal(physicalCount);
        let physicalTotal = counter(physical);
        let physicalPerc = calcPerc(physicalTotal, physicalMax);

        let moneyCount = Object.keys(money).length;
        let moneyMax = maxVal(moneyCount);
        let moneyTotal = counter(money);
        let moneyPerc = calcPerc(moneyTotal, moneyMax);

        let growthCount = Object.keys(growth).length;
        let growthMax = maxVal(growthCount);
        let growthTotal = counter(growth);
        let growthPerc = calcPerc(growthTotal, growthMax);

        let healthCount = Object.keys(health).length;
        let healthMax = maxVal(healthCount);
        let healthTotal = counter(health);
        let healthPerc = calcPerc(healthTotal, healthMax);

        let friendsCount = Object.keys(friends).length;
        let friendsMax = maxVal(friendsCount);
        let friendsTotal = counter(friends);
        let friendsPerc = calcPerc(friendsTotal, friendsMax);

        let familyCount = Object.keys(family).length;
        let familyMax = maxVal(familyCount);
        let familyTotal = counter(family);
        let familyPerc = calcPerc(familyTotal, familyMax);

        let partnerCount = Object.keys(partner).length;
        let partnerMax = maxVal(partnerCount);
        let partnerTotal = counter(partner);
        let partnerPerc = calcPerc(partnerTotal, partnerMax);


        setData({
            ...data,
            answers: {
                ...data.answers,
                scores: {
                    school: schoolPerc,
                    fun: funPerc,
                    physical: physicalPerc,
                    money: moneyPerc,
                    growth: growthPerc,
                    health: healthPerc,
                    friends: friendsPerc,
                    family: familyPerc,
                    partner: partnerPerc
                }

            }
        });


    }

    const bardata = {
        labels: ["Career or School", "Fun & Recreation", "Money & Finances", "Physical Environtment", "Personal Growth", "Health and Wellbeing", "Friends", "Family", "Significant Other"],
        datasets: [
          {
            label: 'Score in %',
            backgroundColor: '#f4594d6b',
            borderColor: '#e2574c',
            borderWidth: 1,
            // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: '#e2574c',
            data: [data.answers.scores.school, data.answers.scores.fun, data.answers.scores.money, data.answers.scores.physical, data.answers.scores.growth, data.answers.scores.health, data.answers.scores.friends, data.answers.scores.family, data.answers.scores.partner]
            // data: [scores.school, scores.fun, scores.money, scores.physical, scores.growth, scores.health, scores.friends, scores.family, scores.partner]
          },
        ]
      };

      let options = {
        scale: {
            ticks: {
                max: 100,
                min: 0,
                beginAtZero: true

            }
        }
    };



    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);


    const radioClick = (e) => {
        setUi({
            ...ui,
            saved: false
        })
        let index = e.target.dataset.idx;
        let section = e.target.dataset.section;
        let value = e.target.value;

        if(section === 'school'){
            let newArr = [...data.answers.school]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    school: newArr
                }
            })
        };
        if(section === 'fun'){
            let newArr = [...data.answers.fun]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    fun: newArr
                }
            })
        }
        if(section === 'physical'){
            let newArr = [...data.answers.physical]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    physical: newArr
                }
            })
        }
        if(section === 'growth'){
            let newArr = [...data.answers.growth]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    growth: newArr
                }
            })
        }
        if(section === 'money'){
            let newArr = [...data.answers.money]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    money: newArr
                }
            })
        }
        if(section === 'health'){
            let newArr = [...data.answers.health]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    health: newArr
                }
            })
        }
        if(section === 'friends'){
            let newArr = [...data.answers.friends]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    friends: newArr
                }
            })
        }
        if(section === 'family'){
            let newArr = [...data.answers.family]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    family: newArr
                }
            })
        }
        if(section === 'partner'){
            let newArr = [...data.answers.partner]
            let current = newArr[index];
            current.value = parseInt(value);
            console.log(newArr)
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    partner: newArr
                }
            })
        }
    }

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>Hello again! Today we&#39;ll take a top-level snapshot of the quality of your life. It&#39;s a simple process that creates a meaningful picture that identifies areas of your life you&#39;d like to change.</p>
                <p>As gamers, we all get to a place where our lives are unbalanced. If they were balanced, then we wouldn&#39;t need to pause gaming! Finding the parts of your life that could use attention provides a roadmap for how you&#39;d like to grow.</p>
                <blockquote>
                    <p>Mindfulness exercise</p>
                </blockquote>
                <h3 id="life-assessment">Life Assessment</h3>
                <p>This assessment reviews 9 areas that, together, represent one way of describing a whole life. This exercise measures your level of satisfaction and range of expression in these areas as a snapshot in time. As you work through this assessment you will find areas where you can acknowledge yourself for the success you have created and areas where you may want to improve your level of satisfaction. Rank each of the statements below on a scale from 1 (Highly Disagree) to 10 (Highly Agree), then total your scores for each area.</p>


            </>
        )
    };


    const handleTextInput = (e) => {
        setUi({
            ...ui,
            saved: false
        })
        setData({
            ...data,
            answers: {
                ...data.answers,
                journal: e.target.value
            }
        })
    };

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>
                <div className="content content-text">
                    {content()}
                    <div className="notification">
                        <p className="title is-4">Career or School</p>
                        {data.answers.school.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='school' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>
                    <div className="notification">
                        <p className="title is-4">Fun & Recreation</p>
                        {data.answers.fun.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='fun' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>

                    <div className="notification">
                        <p className="title is-4">Money & Finances</p>
                        {data.answers.money.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='money' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>

                    <div className="notification">
                        <p className="title is-4">Physical Environment</p>
                        {data.answers.physical.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='physical' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>
                    <div className="notification">
                        <p className="title is-4">Personal Growth</p>
                        {data.answers.growth.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='growth' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>

                    <div className="notification">
                        <p className="title is-4">Health & Wellbeing</p>
                        {data.answers.health.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='health' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>
                    <div className="notification">
                        <p className="title is-4">Friends</p>
                        {data.answers.friends.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='friends' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>
                    <div className="notification">
                        <p className="title is-4">Family</p>
                        {data.answers.family.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='family' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>

                    <div className="notification">
                        <p className="title is-4">Significant Other</p>
                        {data.answers.partner.map((item, index) => (
                                <div key={index} className="field">
                                    <label className="label is-size-5">{item.title} </label>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                scale.map((numb, idx) => (
                                                    <label key={idx} className="radio is-size-4">
                                                        <input disabled={ui.disableRadio} data-idx={index} data-section='partner' onChange={radioClick} type="radio" name={item.key} value={numb} checked={item.value === numb} />
                                                        <span className="ml-3">{numb}</span>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <br />
                    </div>

                    <button disabled={ui.disableScoreBtn} onClick={handleCalculateScore} className="button is-primary">Calculate Score</button>
                    
                    <Radar options={options} data={bardata} />
                    <blockquote className="mt-4">
                    Great! You've completed the life assessment. How was that? Any insights or major take-aways? Take a minute to write about this experience and your insights:
                    </blockquote>

                    <textarea onChange={handleTextInput} className="textarea" value={data.answers.journal}></textarea>
                    
                    <button disabled={ui.saved} onClick={ClickSubmit} className="button is-primary mt-3">Save Changes</button>
                    <br/>
                    {ui.saved ? 
                    <button onClick={FinishLesson} className="button is-primary mt-3">Finish</button>
                        :
                        null
                }

                </div>
            </LessonLayout>
        </>
    )

}

export default LifeAss;