import React, { createContext, useReducer } from 'react';
import initLessons from '../utils/initLessons';

export const LessonsContext = createContext();

const LessonsDefaultState = [
    {
        day: 1,
        title: "Welcome",
        slug: "welcome",
        breakDay: false,
        available_from: new Date(),
        completed: false,
    },

]


export const LessonsReducer = (state, action) => {
    switch (action.type) {

        case "GETLESSONS":

            return {
                ...state,
                isLoading: false,
                lessons: action.payload
            }

        case "UPDATEANSWERS":

        // return console.log(state)

            return state.map((item, index) => {
                if(item.slug !== action.payload.slug) {
                    console.log(false);
                    return item;
                } else {
                    
                    return {
                        ...item,
                        completed: action.payload.completed,
                        answers: action.payload.answers,
                        
                    }
                }
            })

        default:
            return state;
    }
}

export const LessonsContextProvider = (props) => {

    const [state, dispatch] = useReducer(LessonsReducer, LessonsDefaultState);

    let LessonsData = {
        state,
        dispatch
    }

    return <LessonsContext.Provider value={LessonsData}>

        {props.children}

    </LessonsContext.Provider>

};
