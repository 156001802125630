// Just a starter for each lesson

import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import api from '../../../utils/api';


const Whyyouplay = ({Slug, Day}) => {
    const history = useHistory();
    const title = 'Why You Play (reasons played)';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            'whyyouplay': ''
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
          }, (err) => {
            console.log(err)
          });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
               [e.target.name]: e.target.value
            }
        });
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    };

    const FinishLesson = () => {

        if(done === false){

            let complete = {
              id: id,
              completed: true,
              timestamp: new Date()
            }
            console.log(complete);
        
            api.post(`/lessons/complete`, complete).then((res) => {
              console.log(res)
                history.push('/lessons/done')
        
            }, (err) => {
              console.log(err)
            });
            
        
          } else {
              console.log('ok');
            history.push('/lessons/done')
          };

    }


    const content = () => {

        return (
            <>
<p>Welcome back to Respawn. In order to improve your relationship with gaming you need to understand the underlying motivations for why you play.</p>
<p>In our research we have discovered two core aspects of why people play and today we will dive deeper into both:</p>
<h1 id="gaming-fulfills-emotional-needs">Gaming Fulfills Emotional Needs</h1>
<p>We all have needs as humans. We have basic needs like food, water, safety and security; we have psychological needs like love, belonging and relationships; and we have self-fulfillment needs like being creative and reaching one&#39;s potential. There&#39;s nothing good or bad about these needs, they are just part of our human experience and what allow us to live healthy lives. We are always trying to fulfill our needs, and sometimes that occurs through conscious effort, and other times it&#39;s unconscious — the latter of which can be a potential problem.</p>
<p>When we&#39;re unconscious of our needs we might reflexively <em>act out</em> to meet those needs without considering the consequences of our action. If it&#39;s unconscious, then you&#39;re not truly making a choice! It&#39;s just happening. In Respawn you will be challenged to confront your unconscious needs and motivations to make the invisible visible, and bring more choice into your life.</p>
<p>So what does this have to do with games? Well, games are sophisticated in fulfilling many of these needs and that&#39;s one reason why you can find yourself drawn to games instead of focusing on more important activities like your school or work.</p>
<p>Games fulfill four main needs: Temporary Escape, Social Connection, Measurable Progress, and Structured Challenge. This means games allow you to have a break from stress in your life, most (if not all) of your friends play, you receive feedback and progress through instant gratification, and you feel a sense of purpose with a mission and goal(s) to work towards.</p>
<h2 id="-gaming-warps-your-perception-of-effort-and-reward-"><strong>Gaming warps your perception of effort and reward</strong></h2>
<p>When you put effort into a video game, you improve. You see measurable progress with a score flashing across the screen, a leaderboard, and a mission completed. Games are explicit in their expectations and consistent in their rewards—many of which you receive through instant gratification. Real life doesn’t work the same way, with actions and outcomes often having no linear relationship. This inconsistency can cause you to feel less motivated in the physical world.</p>
<p>Ariel, a gamer who was struggling to find employment, shared that “in games you know you have to complete a task to make progress toward your goal, on the other hand in the job market it’s a gamble. You could send out literally a hundred resumes and only hear back from one employer for an interview, and they still might not even hire you.”</p>
<p>Why pursue work that requires effort, with no guarantee of a reward, when you could simply play video games instead? Gaming provides more control over your experience and the results you achieve, regardless of whether they are respected in the physical world or not.</p>
<p>Jane McGonigal, a video game scholar and game designer, has shared that “games provide a sense of waking in the morning with one goal: I’m trying to improve this skill. There is a routine and daily progress that does a good job at replacing traditional work.” Many modern games are also designed to have no end. You can continue to play them indefinitely, one more mission—and day full of procrastination—at a time.</p>
<h2 id="-gaming-is-a-safe-place-to-fail-"><strong>Gaming is a safe place to fail</strong></h2>
<p>For example, if you apply for a job and don’t get it, you experience rejection and that hurts. On the contrary, if you die in a video game you just press restart and try again. There is little-to-no risk in gaming. Sure, it may take you longer to progress than you would like, but there are very few consequences to failure within the game itself — whereas failure in the physical world can often be permanent or irreversible. This level of comfort that gaming provides helps to explain why you would spend more time in the virtual world than the physical world.</p>
<p>Ariel was actively trying to get a job, but turned to games as a crutch to escape feelings of rejection. “I was really, really getting frustrated with submitting resumes and applications to companies and never hearing anything back, so the resentment grew and grew, and I would heal that resentment by playing video games.”</p>
<p>Another member reports that “there was a safety in computer games that I could not replicate with anything else: the safety of the new game or reload button, where if things didn’t go exactly how I wanted them to go I could just redo it, and nothing of the previous failures would remain.”</p>
<h1 id="your-brain-likes-to-game">Your Brain Likes to Game</h1>
<p>The point is that gaming is just an activity you do, but it’s the reasons why you play (the needs gaming fulfills for you) that causes you to continue to play, even if you don&#39;t want to.</p>
<p>In order to have a healthier relationship with gaming — whether moving on for good or simply taking a break— you need to know why you were so drawn to them in the first place and then be intentional with how you fulfill your needs in the future. Otherwise you can find yourself in a cycle of stopping and then returning to gaming.</p>
<h2 id="gaming-is-a-virtual-sugar-rush-gaming-is-candy-to-the-brain">Gaming is a virtual sugar rush/gaming is candy to the brain</h2>
<p>Games are fully immersive and provide rapid dopamine release. When you play a game you have zombies coming at you, scores flashing across the screen, people screaming on the mic, music and explosions and other carefully crafted sounds, and much more. This level of hyper-stimulation is like candy for your brain. It also explains why you may find other activities to be boring compared to games. That doesn’t mean other activities are actually boring or that you don’t enjoy them, it just means that the type of stimulation in games is different than the type of stimulation in other activities.</p>
<p>Overexposure to this hyper-stimulation and game design can cause structural changes to your brain. There are three of them:</p>
<ol>
<li>Numbed pleasure response — every day activities no longer satisfy you</li>
<li>Hyper-reactivity to gaming — gaming is really exciting and everything else is boring</li>
<li>Willpower erosion — even if you wanted to &quot;quit&quot; you might struggle to have the willpower.</li>
</ol>
<p>The good news is that these brain changes are reversible, but they will take some time, hence why we are recommending for you to take a 30 day break from gaming as part of Respawn. Just know that no matter how you feel right now, or how daunting the prospect of taking a break from gaming may be, you will feel better over time and things will get easier.</p>

<blockquote><p>Often it can take up to 90 days or more for these brain changes to reverse. The timing has less to do with a &#39;specific amount of time&#39; and more about steady improvement over time. For example, boredom is often felt most in the first three weeks and then lessens gradually - but not fully (you will still experience boredom, but less so because you are &#39;withdrawing&#39; from gaming.) This is why we recommend to start with a 30 day break and then re-evaluate.</p>
</blockquote>
<blockquote><p>Ok, today has been a lot of reading. Let&#39;s take a breath and let it all go. Wiggle your toes, stretch your arms, and when you are ready, proceed to your writing exercise.</p>
</blockquote>


            </>
        )
    }

    return (
        <>
        <LessonLayout Slug={Day} Title={title}>

        <div className="content content-text">
        {content()}

        <div className="field">
  <label className="label">Write down the reasons why you play / played: <span className="help">Write at least once sentence</span></label>
  <div className="control">
    <textarea name='whyyouplay' className="textarea" onChange={handleChange} value={data.answers.whyyouplay} ></textarea>
  </div>
</div>

{
    data.answers.whyyouplay.length > 10 ?
    <>
    <button onClick={ClickSubmit} className="button is-primary has-text-weight-bold">Save Changes</button>
    {
        ui.saved === true ?
        <>
        <blockquote className="mt-4">Good job.</blockquote>
        <button onClick={FinishLesson} className="button is-primary has-text-weight-bold ">Finish Lesson</button>
        </>
        : null
    }
    </>
    :
    <>
    </>
}

    </div>

        </LessonLayout>
        </>
    )

}

export default Whyyouplay;