import React from 'react';
import ReactPlayer from 'react-player';

const Video = ({Symptom}) => {


    const VideoURL = () => {
        
        switch (Symptom) {
            case 'cravings':
                return 'https://www.youtube.com/watch?v=PjLmwn_0GWM'
                break;
            case 'escapism':
                return 'https://www.youtube.com/watch?v=Agb4h_s61e8'
                break;
            case 'boredom':
                return 'https://www.youtube.com/watch?v=oQV9aPzsUCw'
                break;
            case 'relapse':
                return 'https://www.youtube.com/watch?v=K0G5PRm2EoQ'
                break;
            case 'nostalgia':
                return 'https://www.youtube.com/watch?v=JBJeuyo8p_s'
                break;
            case 'friends':
                return 'https://www.youtube.com/watch?v=4kX2seRVUSY'
                break;
        
            default:
                break;
        }

    }



    return (
        <>
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-8">
                                <div className="has-text-centered">
                                    <h1 className="title mb-2">Watch this video</h1>
                                </div>
                            <ReactPlayer 
                                    url={VideoURL()}
                                    width={'100%'}
                                    height={'500px'}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Video;