import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";
import api from './api';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import Landing from '../pages/Landing/Landing';
import Hub from '../pages/Hub/Hub';
import Settings from '../pages/Settings/Settings';
import Journal from '../pages/Journal/Journal';
import DisclaimerModal from '../components/DisclaimerModal';
import LessonsRouting from '../pages/Lessons/LessonsRouting';
import Recover from '../pages/Recover/recover';
import Reset from '../pages/Recover/reset';
import Icon from '../static/gq_logo.png';
import NotFound from '../pages/404';
import Emergency from '../pages/Emergency/Emergency';
// import Profile from '../Pages/Profile/Profile';
import * as rax from 'retry-axios';
// const interceptorId = rax.attach();




const Router = () => {

    const { dispatch, state } = useContext(AuthContext);
    const [cookies, setCookie, removeCookie] = useCookies(['gq']);
    api.defaults.headers.common['Authorization'] = cookies['gq'] || null;


    const Logout = () => {

        removeCookie(['gq']);

        dispatch({
            type: 'LOGOUT'
        });

        return (
            <Redirect to='/' />

        )
    };


    const getit = async () => {

        const interceptorId = rax.attach();
        await api.get('/userData').then((res) => {
            console.log(res);
            dispatch({
                type: 'COOKIELOGIN',
                payload: {
                    token: cookies['gq'],
                    user: res.data.user
                }
            });
            dispatch({
                type: 'SETLOADING',
                payload: {
                    loading: false
                }
            });
        }, (err) => {
            // dispatch({
            //     type: 'LOGOUT',
            // });
            dispatch({
                type: 'SETLOADING',
                payload: {
                    loading: false
                }
            })
        })

    };

    useEffect(() => {
        dispatch({
            type: 'SETLOADING',
            payload: {
                loading: true
            }
        })
        const CheckCookie = async () => {
            if (cookies['gq']) {
                getit();

            } else {
                // dispatch({
                //     type: 'LOGOUT',
                // });
                dispatch({
                    type: 'SETLOADING',
                    payload: {
                        loading: false
                    }
                })

            }
        }

        CheckCookie();  

    }, [cookies]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Game Quitters</title>

            </Helmet>
            {
                state.isLoading ?
                    <>
                        loading....
                    </>
                    :
                    <Switch>

                        {/* <Route path="/@:username">
                        </Route> */}


                        
                        
                        <Route path="/reset/:secret">
                            <Reset />
                        </Route>

                        <Route exact path="/recover">
                            <Recover />
                        </Route>

                        <Route exact path="/signin">
                            {
                                state.isAuthenticated ?
                                    <Redirect to="/" />
                                    :
                                    <Login />

                            }
                        </Route>


                        <Route exact path="/signup">
                            {
                                state.isAuthenticated ?
                                    <Redirect to="/" />
                                    :
                                    <Register />
                            }
                        </Route>

                        <Route exact path="/settings">
                            <Settings />
                        </Route>
                        
                        <Route exact path="/emergency/:id">
                            <Emergency />
                        </Route>

                        <Route exact path="/logout">
                            <Logout />
                        </Route>

                        <Route path="/journal">
                            <Journal />
                        </Route>


                        <Route path="/lessons">
                            <LessonsRouting State={state} />
                        </Route>



                        <Route exact path="/">
                            {
                                state.isAuthenticated === true && state.user.agreed === true ?
                                    <>
                                        <Hub />
                                    </>
                                    :
                                    state.isAuthenticated === true && state.user.agreed === false ?
                                        <>
                                            <DisclaimerModal Dispatch={dispatch} />
                                            <Hub />
                                        </>
                                        :
                                        <>
                                            <Landing />
                                        </>
                            }
                        </Route>
                        
                        <Route component={NotFound} />

                    </Switch>
            }


        </>
    )
}


export default Router;
