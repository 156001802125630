import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import Logo from '../../static/gq_logo_full.png';


const IndexPage = () => {

  return (

    <>

<section className="hero is-medium">
  <div className="hero-body">
    <div className="container">

      <div className="has-text-centered">
      <img style={logoStyle} src={Logo} />
      </div>

      <h1 className="title has-text-centered">It’s time to take a break from gaming</h1>

        <div className="columns is-centered">

            <div className="column is-2">
            <Link className="button is-fullwidth is-size-4" to="/signup">Create an account</Link>
            </div>

            <div className="column is-2">
            <Link className="button is-fullwidth is-primary is-size-4" to="/signin">Sign in</Link>
            </div>

        </div>

    </div>
  </div>
</section>



     
      
      <br />
     
    </>

  )
}
const logoStyle = {
    maxWidth: "300px"
  }

export default IndexPage
