import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';


const Inventories = () => {

    let inventoryData = [
        {
            title: '💢 What did I struggle with today? ',
            content: `<p><em>Name the people, places, things &amp; situations which were challenging today. Leave out judgment and criticism to focus on reporting the difficult news of the day.</em></p>
            <p><strong>Why this works</strong>: Naming your struggles provides meaningful distance so that you can face your challenges. Practicing self-reflection creates the psychological space to make choices.</p>
            <p>Example: Today I found it hard to stay offline, get out of the house, be honest about my pressing pause, etc.</p>
            `
        },
        {
            title: '💥 Did I experience cravings today? ',
            content: `<p><em>Cravings are expected. Secret urges are dangerous. As an old saying reminds us: &quot;We&#39;re only as sick as our secrets.&quot; Name the cravings &amp; the things that you noticed triggered them.</em></p>
            <p>Reflecting on cravings may re-trigger you. That&#39;s ok. Use the <button class="button is-primary has-text-weight-bold">Emergency Button</button> on your home screen.</p>
            <p><strong>Why this works</strong>: Not noticing cravings increases the risk of relapse. Writing cravings down gets them out of your head, which is where they can hurt you.</p>
            
            
            `
        },
        {
            title: '📖 What did I learn today?',
            content: `<p><em>About myself, others, life, gaming, etc. Include learnings from your whole day and not just Respawn.</em></p>
            <p><strong>Why this works</strong>: Writing about what you learned today helps transition it from short term into long term memory.</p>
            `
        },
        {
            title: '🎉 What am I proud of accomplishing today?',
            content: `<p><em>Celebrate your successes! One more day without gaming!? A major accomplishment. Each day is another opportunity to grow, achieve &amp; help each other.</em></p>
            <p><strong>Why this works:</strong> Noticing your accomplishments leads directly to continued motivation.</p>
            `
        },
        {
            title: '🙏 What am I grateful for today? ',
            content: `<p><em>Name at least 3 things &amp; vary them daily.</em></p>
            <p><strong>Why this works:</strong> Daily gratitude has been shown to change mindset, widen perspective &amp; improve health.</p>
            `
        },
    ];


    return (
        <>
            {
                inventoryData.map((item, index) => (
                    <div className="mt-4" key={index}>
                        <h2 className="title is-4">{item.title}</h2>
                        <div className="notification">
                            <div className="content content-text">
                                <div dangerouslySetInnerHTML={{__html: item.content}} />
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default Inventories;

