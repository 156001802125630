// Just a starter for each lesson

import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import api from '../../../utils/api';
import EmergencyWidget from '../../../components/EmergencyWidget';


const StumbleReady = ({Slug, Day}) => {
    const history = useHistory();
    const title = 'Stumble Ready';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: []
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
          }, (err) => {
            console.log(err)
          });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: {
                    ...data.answers.journals,
                    [e.target.name]: e.target.value
                }
            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if(done === false){

            let complete = {
              id: id,
              completed: true,
              timestamp: new Date()
            }
            console.log(complete);
        
            api.post(`/lessons/complete`, complete).then((res) => {
              console.log(res)
                history.push('/lessons/done')
        
            }, (err) => {
              console.log(err)
            });
            
        
          } else {
              console.log('ok');
            history.push('/lessons/done')
          };

    }


    const content = () => {

        return (
            <>
<p>As you begin to take a break from gaming, we want to take this moment to share with you our Emergency Action Tool. You can find it by clicking the Emergency button in the sidebar at any time if you are struggling and need additional support. It will help you work through cravings, escapism, boredom, loneliness, nostalgia, and relapse. As always, if you need additional support, say hi👋🏼 using the live chat icon on the bottom right.</p>

<button onClick={FinishLesson} className="button is-primary">Finish Lesson</button>



{/* <h3 id="cravings">Cravings</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=PjLmwn_0GWM"></a><a href="https://www.youtube.com/watch?v=PjLmwn_0GWM">https://www.youtube.com/watch?v=PjLmwn_0GWM</a></p>
<p><strong>Audio:</strong> <a href="https://gamingthesystem.transistor.fm/episodes/034-how-to-overcome-cravings"></a><a href="https://gamingthesystem.transistor.fm/episodes/034-how-to-overcome-cravings">https://gamingthesystem.transistor.fm/episodes/034-how-to-overcome-cravings</a></p>
<p><strong>Prompt:</strong> Answer the following:</p>
<ul>
<li>What is going on? Describe your cravings. Where do you feel them in your body?</li>
<li>When did they start? Was there something that happened that you feel triggered them?</li>
<li>Ok great, now let&#39;s take a deep breath. Inhale for a count of 3 and when you exhale for a count of 3, feel your cravings leaving your body.</li>
<li>Now what is something you can do to shift the energy in your body right now? Can you go for a walk? Do some push-ups or jumping jacks? Put on your favorite song and dance it out? Call a friend? Choose something to shift your state.</li>
<li>What have you learned from this experience?</li>
</ul>
<h3 id="escapism">Escapism</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=Agb4h_s61e8"></a><a href="https://www.youtube.com/watch?v=Agb4h_s61e8">https://www.youtube.com/watch?v=Agb4h_s61e8</a></p>
<p><strong>Audio:</strong> <a href="https://www.dropbox.com/s/ec47e6ab9ekjddm/039%20-%20Overcoming%20escapism.mp3?dl=0"></a><a href="https://www.dropbox.com/s/ec47e6ab9ekjddm/039">https://www.dropbox.com/s/ec47e6ab9ekjddm/039</a> - Overcoming escapism.mp3?dl=0</p>
<p><strong>Prompt:</strong> Answer the following:</p>
<ul>
<li>What&#39;s going on? What are you feeling in this moment?</li>
<li>Did something trigger your desire to escape?</li>
<li>By escaping what do you hope to achieve?</li>
<li>Will escaping into games cause other problems in your life?</li>
<li>How can you get a break from stress and escape without gaming?</li>
<li>What have you learned from this experience?</li>
</ul>
<h3 id="boredom">Boredom</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=oQV9aPzsUCw"></a><a href="https://www.youtube.com/watch?v=oQV9aPzsUCw">https://www.youtube.com/watch?v=oQV9aPzsUCw</a></p>
<p><strong>Audio:</strong> <a href="https://gamingthesystem.transistor.fm/episodes/032-why-are-new-activities-boring-after-you-quit-gaming"></a><a href="https://gamingthesystem.transistor.fm/episodes/032-why-are-new-activities-boring-after-you-quit-gaming">https://gamingthesystem.transistor.fm/episodes/032-why-are-new-activities-boring-after-you-quit-gaming</a></p>
<p><strong>Prompt:</strong> Answer the following:</p>
<ul>
<li>What&#39;s going on? What are you feeling in this moment?</li>
<li>If you were to describe your boredom, what would it be? Is it a desire for more stimulation? Is it a frustration that new activities aren&#39;t as &quot;fun&quot; as gaming?</li>
<li>Ideally, how would you want to feel right now?</li>
<li>How can you achieve that feeling without gaming?</li>
<li>What have you learned from this experience?</li>
</ul>
<h3 id="relapse">Relapse</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=K0G5PRm2EoQ"></a><a href="https://www.youtube.com/watch?v=K0G5PRm2EoQ">https://www.youtube.com/watch?v=K0G5PRm2EoQ</a></p>
<p><strong>Audio:</strong> <a href="https://gamingthesystem.transistor.fm/episodes/026-the-surprising-benefits-of-relapsing"></a><a href="https://gamingthesystem.transistor.fm/episodes/026-the-surprising-benefits-of-relapsing">https://gamingthesystem.transistor.fm/episodes/026-the-surprising-benefits-of-relapsing</a></p>
<p><strong>Prompt:</strong> Have you relapsed? [Yes] [Not yet]</p>
<p>If yes:</p>
<p>Ok that&#39;s alright, first, don&#39;t be too hard on yourself, it happens. Now answer the following:</p>
<ul>
<li>What happened? Were you bored? Stressed out? Feeling cravings or anxiety? Did friends invite you to play? Get it all out.</li>
<li>If the same trigger(s) happen in the future, how can you respond to them differently?</li>
<li>What have you learned from this experience to help you in the future?</li>
</ul>
<p>If no:</p>
<p>Ok great, so you haven&#39;t relapsed but maybe you feel on the verge. Now answer the following:</p>
<ul>
<li>What is happening? What emotions are you experiencing?</li>
<li>Did something trigger these emotions?</li>
<li>What do you hope to achieve by relapsing? How will it make you feel differently?</li>
<li>How can you achieve your desired state without gaming? What can you do instead?</li>
<li>What have you learned from this experience so far?</li>
</ul>
<h3 id="nostalgia">Nostalgia</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=JBJeuyo8p_s"></a><a href="https://www.youtube.com/watch?v=JBJeuyo8p_s">https://www.youtube.com/watch?v=JBJeuyo8p_s</a></p>
<p><strong>Audio:</strong> <a href="https://www.dropbox.com/s/ki3dajgpj6sw3xa/Overcoming%20gaming%20nostalgia.mp3?dl=0"></a><a href="https://www.dropbox.com/s/ki3dajgpj6sw3xa/Overcoming">https://www.dropbox.com/s/ki3dajgpj6sw3xa/Overcoming</a> gaming nostalgia.mp3?dl=0</p>
<p><strong>Prompt:</strong> Answer the following:</p>
<ul>
<li>What&#39;s happening? What are you feeling nostalgic about?</li>
<li>Write down three things that you are grateful about your time gaming for</li>
<li>Now write down three things that you are grateful for in your life without gaming</li>
<li>Ideally how would you like to feel right now?</li>
<li>What is something you can do other than gaming to achieve that feeling?</li>
<li>What have you learned from this experience?</li>
</ul>
<h3 id="missing-friends">Missing Friends</h3>
<p><strong>Video:</strong> <a href="https://www.youtube.com/watch?v=4kX2seRVUSY"></a><a href="https://www.youtube.com/watch?v=4kX2seRVUSY">https://www.youtube.com/watch?v=4kX2seRVUSY</a></p>
<p><strong>Audio:</strong> <a href="https://gamingthesystem.transistor.fm/episodes/020-what-if-your-friends-are-making-you-relapse"></a><a href="https://gamingthesystem.transistor.fm/episodes/029-how-to-make-friends-after-you-quit-gaming">https://gamingthesystem.transistor.fm/episodes/029-how-to-make-friends-after-you-quit-gaming</a></p>
<p><strong>Prompt:</strong> Answer the following:</p>
<ul>
<li>What&#39;s happening? How are you feeling right now?</li>
<li>Did something trigger this experience? (e.g. a friend inviting you to play?)</li>
<li>Ideally, how would you like to feel right now?</li>
<li>What can you do to achieve that feeling without going back to games? (e.g. is there a friend you can call?)</li>
<li>What have you learned from this experience?</li>
</ul> */}

            </>
        )
    }

    return (
        <>
        <LessonLayout Slug={Day} Title={title}>

        <div className="content content-text">
        {content()}

    </div>

        </LessonLayout>
        </>
    )

}

export default StumbleReady;