// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';
import Inventories from './components/inventories';


const Inventory = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Inventory';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false,
        doneJournal: true
    });

    const QnA = [
        {
            name: 'struggle',
            title: '💢 What did I struggle with today?',
            value: ''
        },
        {
            name: 'cravings',
            title: '💥 Did I experience cravings today?',
            value: ''

        },
        {
            name: 'learn',
            title: '📖 What did I learn today?',
            value: ''

        },
        {
            name: 'proud',
            title: '🎉 What am I proud of accomplishing today?',
            value: ''

        },
        {
            name: 'grateful',
            title: '🙏 What am I grateful for today?',
            value: ''

        },

    ];

    const decision = [
        {
            text: `Yes, I've decided to inventory every day. Let's do this!`,
            value: 'yes'
        },
        {
            text: `I'm not sure I can do this.`,
            value: 'no'
        },
        {
            text: `No, I don't like this practice or I don't think I need it. `,
            value: 'unsure'
        },
    ]

    const [answers, setAnswers] = useState({
        invent: QnA,
        decision: '',
        whynot: '',
        reminder: null

    });

    const handleChange = (e) => {

        let newArr = [...answers.invent];
        let current = newArr[e.target.dataset.idx];
        console.log(current)

        current.value = e.target.value;
        console.log(current)
        setAnswers({
            ...answers,
            invent: [...answers.invent]
        })
    };

    const handleDropdown = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setAnswers(res.data.answers)
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);



    const ClickSubmit = async () => {

        setIsLoading(true);

        let payload = {
            answers: answers
        }

        await api.put(`/lessons/answers/${Slug}`, payload).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    };




    const content = () => {

        return (
            <>

                <p>Today we&#39;ll introduce you to the powerful tool of taking a daily inventory.</p>
                <p>Whether you&#39;re already taking daily stock of your life or you&#39;ve never journaled a day in your life, taking a regular inventory while working through Respawn will create the self-reflective habit necessary to meet the challenges of pressing pause on gaming. It&#39;s a very simple tool, but powerful when practiced regularly.</p>
                <p>&quot;All of life is a lesson in learning oneself.&quot;</p>
                <h2 id="how-inventory-works">How Inventory Works</h2>
                <p>Basically, daily inventory takes stock of your day. This inventory is there to keep track of what&#39;s going on in your life, and to help improve the quality of your days through self-reflection.</p>
                <p>You will receive a reminder from Respawn each day to complete the inventory, which involves answering a few questions that will help you reflect on what went well, and what went not so well. It should take no more than 20 minutes.</p>
                <blockquote>
                    <p>Mindfulness exercise</p>
                </blockquote>
                <h2 id="the-five-questions">The Five Questions</h2>
                <p>These five questions have been proven by experience to effectively reflect upon the important parts of your day. Each question includes an explanation of why they work, and put together, provide a strong foundation for a daily self-reflection practice.</p>
                <Inventories />
                <h2 id="inventory-ally">Inventory Ally</h2>
                <p>If you practice regularly, this daily reflection through self-inventory will become a powerful ally. It becomes a safe place to return over and over; a touchpoint in your day that you can trust; the mortar in a solid foundation to build through the challenges ahead. Ready to start? Give the daily inventory a try now, and then Respawn will prompt you again in the future.</p>

            </>

        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">

                    {content()}

                    {
                        answers.invent.map((item, index) => (
                            <div key={index} className="field">
                                <label className="label">{item.title}</label>
                                <div className="control">
                                    <textarea onChange={handleChange} data-idx={index} name={item.name} className="textarea" value={item.value}></textarea>
                                </div>
                            </div>
                        ))
                    }

                    {
                        ui.doneJournal ?
                            <>
    
                            <blockquote>
                            <p>Ok! You've tried the daily inventory process and you have a decision: Would you like to commit to taking inventory for every day of Respawn? We highly recommend it. There's no penalty for not inventorying (other than a missed opportunity to reinforce your personal foundation).</p>

                            </blockquote>

                            <div className="field">
                        <div className="control is-fullwidth">
                            <div className="select is-fullwidth">
                                <select name='decision'  onChange={handleDropdown} value={answers.decision}>
                                    <option disabled value=''>Choose one</option>
                                    {
                                        decision.map((item, index) => (
                                            <option key={index} value={item.value}>{item.text}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>



                    {
                        answers.decision !== '' ?
                        <>
                        {
                            answers.decision === 'no' || answers.decision === 'unsure' ?
                            <>
                            <blockquote>
                            Would you tell us a bit about why you're concerned you cannot use the inventory tool or why you don't like/need the tool?
                            </blockquote>
                            <div className="field">
                                <div className="control">
                                    <textarea onChange={handleDropdown} name="whynot" className="textarea" value={answers.whynot}></textarea>
                                </div>
                            </div>
                            </>
                            :
                           <>
                           <h3>Reminders?</h3>
                            <blockquote>
                            As an ally, Respawn can check-in with you. When you miss a day of inventory, Respawn notices and will check-in with you. But that's an opt-in feature: Would you like reminders?
                            </blockquote>
                           <p> <button onClick={handleDropdown} value='true' name='reminder' className={`button is-primary has-text-weight-bold ${answers.reminder === 'true' ? `` : `is-outlined`} `}>Yes, please</button> <button onClick={handleDropdown} value='false' name='reminder' className={`button is-primary ml-2 has-text-weight-bold ${answers.reminder === 'false' ? `` : `is-outlined`}`}>No thanks.</button></p>
                           </>
                        }
                        </>
                        :
                        null
                    }
    
                                </>
                            :
                            null
                    }



                    <br/>

                    {
                        ui.saved === false ?
                            <button onClick={ClickSubmit} className="button is-primary has-text-weight-bold">Save Changes</button>
                            :
                            <>
                                <blockquote>
                                    Great stuff!
                        </blockquote>
                                <button onClick={FinishLesson} className="button is-primary has-text-weight-bold">Finish Lesson</button>
                            </>
                    }
                </div>
            </LessonLayout>
        </>
    )

}

export default Inventory;