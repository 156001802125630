import React from 'react';
import Helmet from 'react-helmet';

const LessonLayout = ({children, Title}) => {

    return (
        <>
        <Helmet>
    <title>{`${Title} | Game Quitters`}</title>
</Helmet>
        <div className="container">
           <div className="section">
               <h1 className="title has-text-centered">{Title}</h1>
                    <div className="columns is-centered">
                        <div className="column is-10">
                        <div className="box">
                <div className="pl-4 pr-4 pt-4 pb-4">
                {children}
                </div>
            </div> 
                        </div>

                    </div>
           </div>
        </div>
        </>
    )

}

export default LessonLayout;