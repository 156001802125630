import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from '../utils/api';
import Loader from 'react-loader-spinner'
import * as rax from 'retry-axios';

const StreakWidget = ({ Streak, Dispatch }) => {


    const defaultState = {
        days: null,
        startDate: new Date()
    }

    const [loading, setLoading] = useState(true);
    const [payload, setPayload] = useState(null);
    const [streak, setStreak] = useState(defaultState);
    // const [days, setDays] = useState(null);
    // const [startDate, setStartDate] = useState(new Date());


    useEffect(() => {
        if (payload !== null) {

            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(payload.timestamp);
            const secondDate = new Date();

            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

            setStreak({
                ...streak,
                days: diffDays
            });
            setLoading(false)

        } else {
            setStreak({
                ...streak,
                days: null
            });
            setLoading(false)
        };
    }, [payload]);


    useEffect(() => {

        if(payload === null){
            setLoading(true);
            const interceptorId = rax.attach();
            api.get('/updateStreak').then((res) => {
                console.log(res.data);
                setPayload(res.data);
                setLoading(false);
            }, (err) => {
                console.log(err);
            })
        };
    }, [])

    const handlePicker = (date) => {

        setStreak({
            ...streak,
            startDate: date
        })

    };

    const handleSubmitStreak = () => {

        const data = {
            timestamp: streak.startDate
        };

        setLoading(true);

        api.post('/updateStreak', data).then((res) => {

            console.log(res)

            setPayload(res.data)
            setLoading(false);

        }, (err) => {
            console.log(err)
        })

    };

    const handleResetStreak = () => {

        let pl = {
            id: payload._id,
        }

        api.put('/updateStreak', pl).then((res) => {
            setPayload(null);
        }, (err) => {
            setPayload(null);
        });
    };


    return (
       loading === false ?

        <>

            <p className="is-uppercase">
                <strong>Game-Free Streak</strong>
            </p>

            {
                streak.days > 0 ?
                    <>
                        <div className="subtitle">
                            <span>{streak.days} days streak</span>
                        </div>

                        <div>
                            <p>Did you game today?</p>
                            <p onClick={handleResetStreak} className="has-text-weight-bold has-text-primary pointer">Reset the counter</p>
                        </div>
                    </>
                    :

                    streak.days === null ?
                        <>
                            <div>
                                <p className="has-text-weight-bold">Let's start your streak counter</p>
                                <p className="help">Start by picking the day you stopped gaming. If you only stopped today, we've preselected today for you.</p>
                                <DatePicker className="input" selected={streak.startDate} onChange={handlePicker} />

                                <button onClick={handleSubmitStreak} className="button is-primary mt-4">Start your journey</button>

                            </div>
                        </>
                        :
                        streak.days === 0 ?
                            <div className="subtitle">
                                <span>This is your first day.</span>
                            </div>
                            : null
            }

            {

            }

        </>
        :
        <>
 <Loader
        type="TailSpin"
        color="#e2574c"
        height={50}
        />
        </>
    )

}

export default StreakWidget;