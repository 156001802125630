import React, { createContext, useReducer } from 'react';
// import { useCookies } from 'react-cookie';
import api from '../utils/api';


export const JournalContext = createContext();



const initialState = {

    isLoading: true,
    journals: null

};


export const JournalReducer = (state, action) => {
    switch (action.type) {


        case "GETENTRIES":





            return {
                ...state,
                isLoading: false,
                journals: action.payload
            }

        default:
            return state;
    }
}

export const JournalContextProvider = (props) => {

    const [state, dispatch] = useReducer(JournalReducer, initialState);

    let JournalData = {
        state,
        dispatch
    }

    return <JournalContext.Provider value={JournalData}>

        {props.children}

    </JournalContext.Provider>

};
