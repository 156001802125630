import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import Logo from '../static/gq_logo.png';
import TextLogo from '../static/gq_text.png';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faEnvelope, faPencilAlt, faDoorClosed } from '@fortawesome/free-solid-svg-icons';



const Navbar = () => {

  const { dispatch } = useContext(AuthContext);

  const [dropdown, setDropdown] = useState(false);

  const triggerDropdown = () => {
    setDropdown(!dropdown)
  }




  return (
    <nav className="navbar is-dark borderShadow" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">

          <Link className="navbar-item hov-trans-bg" to='/'>
            <img className="navLogo" src={Logo} />
          </Link>
          <div className="navbar-item"><img style={{ marginLeft: '-17px', maxHeight: '3rem' }} src={TextLogo} /></div>


          <a onClick={triggerDropdown} role="button" className={`navbar-burger burger ${dropdown ? `is-active` : ``}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${dropdown ? `is-active` : ``}`}>
          <div className="navbar-start">

          </div>

          <div className="navbar-end">
            <Link to='/' className="navbar-item is-size-4">
              <FontAwesomeIcon icon={faHome} />
            </Link>

            <Link to='/journal' className="navbar-item is-size-4">
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>

            <Link to='/settings' className="navbar-item is-size-4">
              <FontAwesomeIcon icon={faCog} />
            </Link>



            <a href="mailto:beta@gamequitters.com" className="has-text-white navbar-item is-size-4"><FontAwesomeIcon icon={faEnvelope} /></a>

            <Link to='/logout' className="navbar-item is-size-6">
              Logout
      </Link>

          </div>
        </div>
      </div>
    </nav>

  )

}

export default Navbar;