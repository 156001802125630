import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import Img from '../../../static/gq_logo_full.png';
import {useParams} from 'react-router-dom';
import api from '../../../utils/api';


const Journal = ({Symptom}) => {

    const params = useParams();


    const [ui, setUi] = useState({
        'showGreat': false,
        'done': false,
    })
    const [data, setData] = useState({
        relapsed: ''
    });
    
    let HandleChanges = (e) => {
        console.log(e.target.value + e.target.name)
        let value = e.target.value;
        let name = e.target.name;

        setData({
            ...data,
            [name]: value
        });

        // setUi({
        //     ...ui,
        //     showGreat: false
        // });

    };

    let SavetoDB = () => {

        let payload = {
            id: params.id,
            prompts: data
        }

        api.post('/panicButton', payload).then((res) => {
            setUi({
                ...ui,
                done: true
            });
        }, (err) => {
            console.log(err)
        });

    }


    const Escapism = () => {

        let questions = [
            {
                key: `feeling`,
                title: `What's going on? What are you feeling in this moment?`
            },
            {
                key: `trigger`,
                title: `Did something trigger your desire to escape?`
            },
            {
                key: `achieve`,
                title: `By escaping what do you hope to achieve?`
            },
            {
                key: `problems`,
                title: `Will escaping into games cause other problems in your life?`
            },
            {
                key: `stress`,
                title: `How can you get a break from stress and escape without gaming?`
            },
            {
                key: `learned`,
                title: `What have you learned from this experience?`
            },
        ]

        return (
            <>
            {
               questions.map((item, index) => (
                <div key={index} className="field">
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }

<button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button>
            </>
        )
    }


    const Boredom = () => {

        let questions = [
            {
                key: `feeling`,
                title: `What's going on? What are you feeling in this moment?`
            },
            {
                key: `fun`,
                title: `If you were to describe your boredom, what would it be? Is it a desire for more stimulation? Is it a frustration that new activities aren't as "fun" as gaming?`
            },
            {
                key: `now`,
                title: `Ideally, how would you want to feel right now? `
            },
            {
                key: `achieve`,
                title: `How can you achieve that feeling without gaming? `
            },
            {
                key: `learn`,
                title: `What have you learned from this experience?`
            },
            
        ]

        return (
            <>
            {
               questions.map((item, index) => (
                <div key={index} className="field">
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }

<button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button>
            </>
        )
    }


    const Cravings = () => {



        return (
            <>

        <div className="field">
  <label className="label">What is going on? Describe your cravings. Where do you feel them in your body?</label>
  <div className="control">
    <textarea disabled={ui.done} onChange={HandleChanges} name='describe' className="textarea"></textarea>
  </div>
</div>

<div className="field">
  <label className="label">When did they start? Was there something that happened that you feel triggered them?</label>
  <div className="control">
    <textarea disabled={ui.done} onChange={HandleChanges} name='start' className="textarea"></textarea>
  </div>
</div>

<div className="notification is-primary has-text-weight-bold">
Ok great, now let's take a deep breath. Inhale for a count of 3 and when you exhale for a count of 3, feel your cravings leaving your body.
Now what is something you can do to shift the energy in your body right now? Can you go for a walk? Do some push-ups or jumping jacks? Put on your favorite song and dance it out? Call a friend? Choose something to shift your state.
</div>

<div className="field">
  <label className="label">What have you learned from this experience?</label>
  <div className="control">
    <textarea disabled={ui.done} onChange={HandleChanges} name='learn' className="textarea"></textarea>
  </div>
</div> 
<button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button>


    </>


        )
    };




    const Relapse = () => {

        let yes = [
            {
                msg: `Ok that's alright, first, don't be too hard on yourself, it happens. Now answer the following:`
            },
            {
                key: `what`,
                title: `What happened? Were you bored? Stressed out? Feeling cravings or anxiety? Did friends invite you to play? Get it all out.`
            },
            {
                key: `triggers`,
                title: `If the same trigger(s) happen in the future, how can you respond to them differently? `
            },
            {
                key: `learned`,
                title: `What have you learned from this experience to help you in the future?`
            },
            
        ]

        let no = [
            {
                msg: `Ok great, so you haven't relapsed but maybe you feel on the verge. Now answer the following:`
            },
            {
                key: `what`,
                title: `What is happening? What emotions are you experiencing?`
            },
            {
                key: `triggers`,
                title: `Did something trigger these emotions? `
            },
            {
                key: `achieve`,
                title: `What do you hope to achieve by relapsing? How will it make you feel differently?`
            },
            {
                key: `desire`,
                title: `How can you achieve your desired state without gaming? What can you do instead?`
            },
            {
                key: `learned`,
                title: `What have you learned from this experience so far?`
            },
            
        ]

        return (
            <>
        
            <h1 className="title">Have you relapsed?</h1>

            <button  name='relapsed' onClick={HandleChanges} value={true} className={`button is-primary has-text-weight-bold`}>Yes</button> <button onClick={HandleChanges} value={false}  name='relapsed' className={`ml-2 button is-primary has-text-weight-bold`}>No</button>
            <br />
{/* <button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button> */}
{
    data.relapsed === `true` ?
    <>
    <hr/>
 {
               yes.map((item, index) => (
                item.msg ?
                <p className="has-text-weight-bold is-size-5" key={index}>{item.msg}</p>
                :
                <div key={index} className={`field ${index === 1 ? `mt-4` : `` }`}>
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }
            <button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button> 
    </>
    :
    null
}
{
    data.relapsed === `false` ?
    <>
       <hr/>
 {
               no.map((item, index) => (
                item.msg ?
                <p className="has-text-weight-bold is-size-5" key={index}>{item.msg}</p>
                :
                <div key={index} className={`field ${index === 1 ? `mt-4` : `` }`}>
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }
            <button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button> 
    </>
    :
    null
}
            </>
        )
    }


    const Nostalgia = () => {

        let questions = [
            {
                key: `feeling`,
                title: `What's happening? What are you feeling nostalgic about?`
            },
            {
                key: `grateful-gaming`,
                title: `Write down three things that you are grateful about your time gaming for`
            },
            {
                key: `grateful-non-gaming`,
                title: `Now write down three things that you are grateful for in your life without gaming`
            },
            {
                key: `feeling`,
                title: `Ideally how would you like to feel right now?`
            },
            {
                key: `achieve`,
                title: `What is something you can do other than gaming to achieve that feeling?`
            },
            {
                key: `learned`,
                title: `What have you learned from this experience?`
            },
            
        ]

        return (
            <>
            {
               questions.map((item, index) => (
                <div key={index} className="field">
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }

<button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button>
            </>
        )
    }


    const Friends = () => {

        let questions = [
            {
                key: `feeling`,
                title: `What's happening? How are you feeling right now?`
            },
            {
                key: `trigger`,
                title: `Did something trigger this experience? (e.g. a friend inviting you to play?)`
            },
            {
                key: `ideally`,
                title: `Ideally, how would you like to feel right now?`
            },
            {
                key: `achieve`,
                title: `What can you do to achieve that feeling without going back to games? (e.g. is there a friend you can call?)`
            },
            {
                key: `learned`,
                title: `What have you learned from this experience?`
            },
            
        ]

        return (
            <>
            {
               questions.map((item, index) => (
                <div key={index} className="field">
                <label className="label">{item.title}</label>
                <div className="control">
                  <textarea disabled={ui.done} onChange={HandleChanges} name={item.key} className="textarea"></textarea>
                </div>
              </div>
               )) 
            }

<button disabled={ui.done} onClick={SavetoDB} className="button is-primary has-text-weight-bold">Submit</button>
            </>
        )
    }

    const JournalURL = () => {
        
        switch (Symptom) {
            case 'cravings':
                return Cravings();
                break;
            case 'escapism':
                return Escapism();
                break;
            case 'boredom':
                return Boredom();
                break;
            case 'relapse':
                return Relapse();
                break;
            case 'nostalgia':
                return Nostalgia();
                break;
            case 'friends':
                return Friends();
                break;
        
            default:
                break;
        }

    }



    return (
        <>
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-6">
                                <div className="has-text-centered">
                                    {/* <img style={{width: '150px'}} src={Img} /> */}
                                    <h1 className="title mb-4">Answer the following: </h1>
                                    
                                        {JournalURL()}
                                        {
    ui.done ?
    <>
    <br/>
    <p>Saved! Thank you! </p>
    </>
    :
    null
}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Journal;