import LoggedLayout from '../../components/LoggedLayout';
import React, {useState, useContext} from 'react';
import Account from './parts/Account';
import ProfileSettings from './parts/ProfileSettings';
import {AuthContext} from '../../context/AuthContext';
import {Link} from 'react-router-dom';

const Settings = () => {

    const {state, dispatch} = useContext(AuthContext);

    const [tab, setTabs] = useState('profile');

    return (
        <LoggedLayout>
                    {
            state.isAuthenticated ?
            <div className="container">

                <div className="section">

                    <div className="columns is-centered">

                        <div className="column is-6">

                            <div>
                               <p className="has-text-right"><Link to='/' className="delete has-text-primary"></Link></p>
                            </div>


                        <div className="tabs is-centered">
                        <ul>
                            <li onClick={e => setTabs('account')} className={`${tab === `account` ? `is-active` : ``}`}><a className="has-text-weight-bold">Account</a></li>
                            <li onClick={e => setTabs('profile')} className={`${tab === `profile` ? `is-active` : ``}`}><a className="has-text-weight-bold">Profile</a></li>
                            <li onClick={e => setTabs('danger')} className={`${tab === `danger` ? `is-active` : ``}`}><a className="has-text-weight-bold">Danger</a></li>
                            {/* <li onClick={e => setTabs('help')} className={`${tab === `help` ? `is-active` : ``}`}><a>Help</a></li> */}
                        </ul>
                    </div>

                    {
                        tab === 'account' ?
                        <Account State={state} Dispatch={dispatch} /> : 
                        tab === 'profile' ? 
                        <ProfileSettings State={state} Dispatch={dispatch} /> : 
                        
                        tab === 'danger' ?
                        <>
                            <div className="has-text-left">
                            <p className="has-text-weight-bold">Want to change your password?  <Link to='/recover' className="has-text-weight-bold has-text-primary">Use the Password Recovery tool</Link></p>
                            <p className="has-text-weight-bold">Want to change your Email?  <a className="has-text-weight-bold has-text-primary">Please send an email to support@gamequitters.com and we'll guide you through it.</a></p>
                            <hr/>
                            <p className="has-text-weight-bold">Delete Account <span className="tag is-warning">WIP</span></p>
                            <p className="has-text-weight-bold mt-2">Download my data <span className="tag is-warning">WIP</span></p>
                            </div>
                        </>
                        :
                        null
                        
                    }

                        </div>

                    </div>

                </div>

            </div>
            :
            <>
            </>
        }

        </LoggedLayout>
    )
}

export default Settings;
