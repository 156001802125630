import React, { useContext, useState, useEffect } from 'react';
// import Logo from '../../static/gq_logo_full.png';
// import Icon from '../../static/gq_logo.png';
import Logo from '../../static/gq_logo_full.png';
import Icon from '../../static/gq_logo.png'
import { Helmet } from "react-helmet";

import { AuthContext } from '../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useHistory, Link, Redirect } from 'react-router-dom';

import api from '../../utils/api';


function Login() {

  const [cookies, setCookie, removeCookie] = useCookies(['gq']);

  const history = useHistory();

  const defaultState = {
    email: "",
    password: ""
  }

  const [loginUi, setLoginUi] = useState({
    isLoading: false,
    isInvalid: false,
    isIncomplete: false,
    isDisabled: false
  });

  const { dispatch } = useContext(AuthContext);

  const [login, setLogin] = useState({
    email: "",
    password: ""
  });


  const handleLoginInput = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  };

  const handleLoginSubmit = async(e) => {
    setLoginUi({
      ...loginUi,
      isLoading: true,
      isInvalid: false,
      isIncomplete: false,
      isDisabled: true
    });
    e.preventDefault();
    if (login.email.length && login.password.length > 0) {
      api.post('/login', login).then((res) => {
        
        console.log(res);

     setLoginUi({
          ...loginUi,
          isLoading: true
        });
        console.log(res);
        setCookie('gq', res.data.data.token, {sameSite: false, });
        localStorage.setItem('gq_tok', res.data.data.token);
        dispatch({
          type: 'LOGIN',
          payload: res.data
        });
  
        return (
          <Redirect push to="/" />
        )


        // setTimeout(() => {
        //   history.push('/');
        // }, [2000])



      }, (err) => {
        console.log(err);
        setLoginUi({
          ...loginUi,
          isInvalid: true,
          isDisabled: false
        });
      })
    } else {
      setLoginUi({
        ...loginUi,
        isIncomplete: true,
        isDisabled: false
      });
    }
  };

  return (

    <>
      <section className="hero is-light is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">

              <div className="column is-5">

                <div className="has-text-centered mb-3">
                  <img style={logoStyle} src={Logo} />
                </div>

                <div className="box">

                  <form onSubmit={handleLoginSubmit}>
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input disabled={loginUi.isDisabled} name="email" onChange={handleLoginInput} className="input" type="email" value={login.email} />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Password</label>
                      <div className="control">
                        <input disabled={loginUi.isDisabled}  name="password" type="password" onChange={handleLoginInput} className="input" value={login.password} />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-text-centered">
                        <button type="submit" className={`button is-fullwidth is-primary ${loginUi.isLoading ? `is-loading` : ``}`} value="Login">Sign In</button>
                      </div>
                    </div>
                  </form>

                  {
                    loginUi.isInvalid ?
                      <>
                        <br />
                        <p className="has-text-weight-bold has-text-danger has-text-centered">Login details not recognised</p>
                      </>
                      :
                      <>
                      </>
                  }

                  {
                    loginUi.isIncomplete ?
                      <>
                        <br />
                        <p className="has-text-weight-bold has-text-danger has-text-centered">Please enter a valid email address and password</p>
                      </>
                      :
                      <>
                      </>
                  }

                  <div className="mt-5">
                    <Link to="/recover">Forgot your password?</Link>
                    <br />
                    <Link to="/signup">Don't have an account?</Link>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </section>

    </>

  )

}

const logoStyle = {
  maxWidth: "300px"
}


export default Login;
