import React from 'react';
import './App.scss';
import Router from './utils/routing';
import {AuthContextProvider} from './context/AuthContext';
import {JournalContextProvider} from './context/JournalContext';
import {LessonsContextProvider} from './context/LessonsContext';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function App() {
  return (
    <AuthContextProvider>
      <LessonsContextProvider>
      <JournalContextProvider>
      <Router />
      </JournalContextProvider>
      </LessonsContextProvider>
    </AuthContextProvider>
  );
}

export default App;

navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) {
    registration.unregister()
    document.location.reload()
} })