import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const JournalEditor = ({Value, SetValue}) => {

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
          ],
    };

    const formats = 
        [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
          ]
    

    return (
        <>
            <ReactQuill 
            name='content' 
            onChange={SetValue} 
            theme="snow" 
            value={Value}
            modules={modules}
            formats={formats}
            />
        </>
    )
}

export default JournalEditor