// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';


const ProCon = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Pro and Cons';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const ProGaming = [
        {
            'key': "problems",
            'title': "I don't have to deal with my problems",
            'value': false
        },
        {
            'key': "confident",
            'title': "I feel more confident",
            'value': false
        },
        {
            'key': "bored",
            'title': "I have something to do when I am bored",
            'value': false
        },
        {
            'key': "friends",
            'title': "I fit in with my friends",
            'value': false
        },
        {
            'key': "fun",
            'title': "I have more fun",
            'value': false
        },
        {
            'key': "relax",
            'title': "It helps me calm down and relax",
            'value': false
        },
        {
            'key': "goals",
            'title': "I am able to achieve my goals",
            'value': false
        },
        {
            'key': "motivation",
            'title': "I feel motivated / a greater sense of purpose",
            'value': false
        },
        {
            'key': "community",
            'title': "I feel a part of a community",
            'value': false
        },
        {
            'key': "important",
            'title': "I feel like I am someone / I feel like I matter / I feel like I am important(valued)",
            'value': false
        },
    ];

    const ConGaming = [
        {
            'key': "problems",
            'title': "My problems keep piling up",
            'value': false
        },
        {
            'key': "guilty",
            'title': "I feel guilty or ashamed",
            'value': false
        },
        {
            'key': "feel",
            'title': "I don't like the way I feel after gaming for a long time",
            'value': false
        },
        {
            'key': "friends",
            'title': "It causes problems with my family or friends.",
            'value': false
        },
        {
            'key': "money",
            'title': "I run out of money",
            'value': false
        },
        {
            'key': "relax",
            'title': "It helps me calm down and relax",
            'value': false
        },
        {
            'key': "anxious",
            'title': "I feel anxious and depressed when not gaming",
            'value': false
        },
        {
            'key': "health",
            'title': "It harms my health",
            'value': false
        },
        {
            'key': "gaming",
            'title': "I don't like who I am when I am gaming",
            'value': false
        },
        {
            'key': "procrastinate",
            'title': "I procrastinate and don't get my work/school done",
            'value': false
        },
        {
            'key': "present",
            'title': "I'm less present with my family/relationships",
            'value': false
        },
        {
            'key': "motivation",
            'title': "I don't have any motivation to do anything but play games",
            'value': false
        },
        {
            'key': "stopping",
            'title': "I keep saying I will stop but I don't/can't",
            'value': false
        },
    ];

    const ProPause = [
        {
            'key': "control",
            'title': "I will feel more control in my life",
            'value': false
        },
        {
            'key': "good",
            'title': "I will feel good about myself",
            'value': false
        },
        {
            'key': "proud",
            'title': "I will feel proud of myself",
            'value': false
        },
        {
            'key': "family",
            'title': "It will improve my relationship with my family",
            'value': false
        },
        {
            'key': "money",
            'title': "I will have more money",
            'value': false
        },
        {
            'key': "time",
            'title': "I will have more time to do better at school or work",
            'value': false
        },
        {
            'key': "easier",
            'title': "It will make it easier to do things",
            'value': false
        },
        {
            'key': "goals",
            'title': "I will accomplish my real goals",
            'value': false
        },
        {
            'key': "support",
            'title': "I will make new / supportive friends",
            'value': false
        },
        {
            'key': "confidence",
            'title': "I will have more confidence",
            'value': false
        },
        {
            'key': "motivation",
            'title': "I will have more motivation",
            'value': false
        },
        {
            'key': "sleep",
            'title': "My sleep schedule will improve",
            'value': false
        },
        {
            'key': "partner",
            'title': "I will have time for a partner / relationship",
            'value': false
        },
        {
            'key': "career",
            'title': "I will be more likely to advance in my career",
            'value': false
        },
        {
            'key': "health",
            'title': "I will be healthier",
            'value': false
        }
    ]

    const ConPause = [
        {
            'key': "depressed",
            'title': "I will feel more depressed and/or anxious",
            'value': false
        },
        {
            'key': "bored",
            'title': "I won't have anything to do when I'm bored",
            'value': false
        },
        {
            'key': "relax",
            'title': "I won't have any way to relax",
            'value': false
        },
        {
            'key': "social",
            'title': "I will have to change my social life / I will lose my friends",
            'value': false
        },
        {
            'key': "friends",
            'title': "I won't fit in with some friends / I won't have anything to talk to my friends about",
            'value': false
        },
        {
            'key': "change",
            'title': "I don't know if I can change",
            'value': false
        },
        {
            'key': "gaming",
            'title': "I'm only good at gaming",
            'value': false
        },
        {
            'key': "time",
            'title': "I don't have anything else to do with my time",
            'value': false
        },
        {
            'key': "life",
            'title': "Real life sucks",
            'value': false
        }
    ]




    const [data, setData] = useState({
        answers: {
            progaming: {
                procon: ProGaming,
                whatelse: ''
            },
            congaming: {
                procon: ConGaming,
                whatelse: ''
            },
            propause: {
                procon: ProPause,
                whatelse: ''
            },
            conpause: {
                procon: ConPause,
                whatelse: ''
            },
            pulltogether: ''
            
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleProGaming = (e) => {

        const newArr = [...data.answers.progaming.procon]
        const item = newArr[e.target.dataset.idx]

        item.value = !item.value;

        setData({
            ...data,
            answers: {
                ...data.answers,
                progaming: {
                    ...data.answers.progaming,
                    procon: newArr
                }
            }
        });
    };


    const handleConGaming = (e) => {

        const newArr = [...data.answers.congaming.procon]
        const item = newArr[e.target.dataset.idx]

        item.value = !item.value;

        setData({
            ...data,
            answers: {
                ...data.answers,
                congaming: {
                    ...data.answers.congaming,
                    procon: newArr
                }
            }
        });
    };


    const handleCheckbox = (e) => {


        const section = e.target.dataset.section;


        if (section === 'progaming') {

            const newArr = [...data.answers.progaming.procon]
            const item = newArr[e.target.dataset.idx]

            item.value = !item.value;

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    progaming: {
                        ...data.answers.progaming,
                        procon: newArr
                    }
                }
            });
        }

        if (section === 'congaming') {

            const newArr = [...data.answers.congaming.procon]
            const item = newArr[e.target.dataset.idx]

            item.value = !item.value;

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    congaming: {
                        ...data.answers.congaming,
                        procon: newArr
                    }
                }
            });
        }

        if (section === 'propause') {

            const newArr = [...data.answers.propause.procon]
            const item = newArr[e.target.dataset.idx]

            item.value = !item.value;

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    propause: {
                        ...data.answers.propause,
                        procon: newArr
                    }
                }
            });
        }
        if (section === 'conpause') {

            const newArr = [...data.answers.conpause.procon]
            const item = newArr[e.target.dataset.idx]

            item.value = !item.value;

            setData({
                ...data,
                answers: {
                    ...data.answers,
                    conpause: {
                        ...data.answers.conpause,
                        procon: newArr
                    }
                }
            });
        }

    };


    const handleTextEditor = (e) => {

        let section = e.target.dataset.section;
        let name = e.target.name;
        let value = e.target.value;

        switch (section) {
            case 'progaming':

                setData({
                    ...data,
                    answers: {
                        ...data.answers,
                        progaming: {
                            ...data.answers.progaming,
                            [name]: value
                        }
                    }
                });
                break;


            case 'congaming':

                setData({
                    ...data,
                    answers: {
                        ...data.answers,
                        congaming: {
                            ...data.answers.congaming,
                            [name]: value
                        }
                    }
                });
                break;

            case 'propause':

                setData({
                    ...data,
                    answers: {
                        ...data.answers,
                        propause: {
                            ...data.answers.propause,
                            [name]: value
                        }
                    }
                });
                break;

            case 'conpause':

                setData({
                    ...data,
                    answers: {
                        ...data.answers,
                        conpause: {
                            ...data.answers.conpause,
                            [name]: value
                        }
                    }
                });
                break;



                case 'pulltogether':

                    setData({
                        ...data,
                        answers: {
                            ...data.answers,
                            pulltogether: value
                        }
                    });
                    break;
        }

    }

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    };






    const content = () => {

        return (
            <>

                <p>Ok! Today is the day to look at the positives &amp; negatives of gaming. The age old tool of looking at the pros and cons helps you weigh your options. Identifying the advantages and disadvantages of gaming will set you up for the decision to press pause.</p>
                <p>The following lists are broken into four lists within two categories. First you&#39;ll look at the pro and con of continuing to game, and then the pro and con of pausing gaming for 30 days. Each list has quick checkboxes that we&#39;ve collected from other gamers who&#39;ve weighted their option. After each list there&#39;s an open text box where you can add your own pro and con.</p>
                <p>There&#39;s no right or wrong answer to pro and con. What matters is that it&#39;s true for you!</p>


                <h3>What's the pro and con of continuing to game?</h3>
                <div className="notification">

                    <h4>Pro of Gaming</h4>
                    {data.answers.progaming.procon.map((item, index) => (

                        <div key={index}>
                            <label className="checkbox">
                                <input data-section='progaming' onChange={handleCheckbox} data-idx={index} checked={item.value} type="checkbox" />
                                <span className='ml-2 has-text-weight-bold'> {item.title}</span>
                            </label>
                            <br />
                        </div>

                    ))}
                    <div className="field mt-2">
                        <label className="label is-size-5">What else? </label>
                        <div className="control">
                            <textarea onChange={handleTextEditor} data-section='progaming' name="whatelse" className="textarea" value={data.answers.progaming.whatelse}></textarea>
                        </div>
                    </div>
                </div>

                <div className="notification">
                    <h4>Con of Gaming</h4>
                    {data.answers.congaming.procon.map((item, index) => (

                        <div key={index}>
                            <label className="checkbox">
                                <input data-section='congaming' onChange={handleCheckbox} data-idx={index} checked={item.value} type="checkbox" />
                                <span className='ml-2 has-text-weight-bold'> {item.title}</span>
                            </label>
                            <br />
                        </div>

                    ))}
                    <div className="field mt-2">
                        <label className="label is-size-5">What else? </label>
                        <div className="control">
                            <textarea onChange={handleTextEditor} data-section='congaming' name="whatelse" className="textarea" value={data.answers.congaming.whatelse}></textarea>
                        </div>
                    </div>
                </div>
                <h3>What's the pro and con of pausing gaming for 30 days?</h3>
                <div className="notification">
                    <h4>Pro of Pausing</h4>
                    {data.answers.propause.procon.map((item, index) => (

                        <div key={index}>
                            <label className="checkbox">
                                <input data-section='propause' onChange={handleCheckbox} data-idx={index} checked={item.value} type="checkbox" />
                                <span className='ml-2 has-text-weight-bold'> {item.title}</span>
                            </label>
                            <br />
                        </div>

                    ))}
                    <div className="field mt-2">
                        <label className="label is-size-5">What else? </label>
                        <div className="control">
                            <textarea onChange={handleTextEditor} data-section='propause' name="whatelse" className="textarea" value={data.answers.propause.whatelse}></textarea>
                        </div>
                    </div>
                </div>

                <div className="notification">
                    <h4>Con of Pausing</h4>
                    {data.answers.conpause.procon.map((item, index) => (

                        <div key={index}>
                            <label className="checkbox">
                                <input data-section='conpause' onChange={handleCheckbox} data-idx={index} checked={item.value} type="checkbox" />
                                <span className='ml-2 has-text-weight-bold'> {item.title}</span>
                            </label>
                            <br />
                        </div>

                    ))}
                    <div className="field mt-2">
                        <label className="label is-size-5">What else? </label>
                        <div className="control">
                            <textarea onChange={handleTextEditor} data-section='conpause' name="whatelse" className="textarea" value={data.answers.conpause.whatelse}></textarea>
                        </div>
                    </div>
                </div>


            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                    <h3>Pulling it together</h3>
                    <div className="field mt-2">
                       <p>Now, take a few moments to look back through your lists. What do you notice? Did anything jump out at you? Which items do you think will be particularly challenging? Use the text box below to write yourself some notes.</p>
                        <div className="control">
                            <textarea onChange={handleTextEditor} data-section='pulltogether' name="pulltogether" className="textarea" value={data.answers.pulltogether}></textarea>
                        </div>
                    </div>
                    <button onClick={ClickSubmit} className="button is-primary has-text-weight-bold">Save Changes</button>
                    {
                        ui.saved?
                        <>
                        <blockquote className="mt-2">Great! You've completed the pro and con tool. You're one step closer to making a decision to press pause fo 30 days. Here comes a new life!</blockquote>
                        
                        <button onClick={FinishLesson} className="button is-primary mt-2">Finish Lesson</button>
                        </>
                        :
                        <p className="help">Write at least one sentence</p>
                    }
                </div>

            </LessonLayout>
        </>
    )

}

export default ProCon;