// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { Warrior, Healer, Ranger, Athlete, Engineer, Spellcaster, Rogue } from './types';
import api from '../../../utils/api';


const GamerArchetypes = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Gamer Archetypes';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            slideData: {
                warrior: 0,
                healer: 0,
                ranger: 0,
                athlete: 0,
                engineer: 0,
                spellcaster: 0,
                rogue: 0
            },
            text: ''

        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
        }, (err) => {
            console.log(err)
        });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
                [e.target.name]: e.target.value
            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                // history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            // history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>👋 Welcome to Gaming Archetyping day!</p>
                <p>Maybe you think of yourself as &#39;a gamer&#39;. Many of us use that label. But what does &#39;gamer&#39; really mean? Respawn looks past the &#39;gamer&#39; label to what actually motivates you to play, and why your gaming can become problematic. Like games, people are complicated. So, we&#39;ve designed Respawn to go beyond &#39;gamer&#39; to look at different types of gamers and the person within. Those types provide a powerful lens to see ourselves.</p>
                <p>For example, some people will think of Respawn as a journey or an adventure, while others might consider it a challenge, mission or battle. You may want to conquer your problematic game play, or perhaps you&#39;d prefer to outgrow compulsive gaming through personal transformation. All of these approaches (and many more) make sense, and Respawn meets you on your personal path to recovery and growth.</p>
                <p>&quot;All mortals tend to turn into the things they are pretending to be.&quot; - C.S. Lewis</p>
                <h2 id="what-is-an-archetype-">What is an archetype?</h2>
                <p>Archetypes are universal symbols that represent characters, themes, dynamics, and circumstances. Think of them as metaphors; they&#39;re abstract images common for all people. Look at any of your favorite games, and you&#39;ll see common archetypes. For example, the common role of good vs. evil in many (most!?) game themes. Just as common is the hero&#39;s journey and ultimate battle against a villain. Ever wonder why all cultures across the world seem to have heroes and villains? Answer: they&#39;re archetypes.</p>
                <p>And games provide an amazing ability to choose your archetypal character and play them inside an archetypal world. Your avatar (whether embodied or abstract) is a manifestation of the archetypal symbols arising for you.</p>
                <p>But enough theory. Let&#39;s get into archetyping!</p>
                <h2 id="gaming-archetypes">Gaming Archetypes</h2>
                <p>In Respawn, we&#39;ve created a map of seven different archetypes. As you look through them, consider which ones you relate with. In the next section, you&#39;ll identify some of your archetypes. Remember, you&#39;ll likely identify with multiple. The following list is a brief overview of each type.</p>
                <p>As you work through each type, use the sliding scale (&#39;not me at all&#39; → &#39;I completely identify&#39;) to indicate how much you identify with each.</p>
                <Warrior Value={data} SetValue={setData} />
                <hr />
                <Ranger Value={data} SetValue={setData} />
                <hr />
                <Healer Value={data} SetValue={setData} />
                <hr />
                <Athlete Value={data} SetValue={setData} />
                <hr />
                <Engineer Value={data} SetValue={setData} />
                <hr />
                <Spellcaster Value={data} SetValue={setData} />
                <hr />
                <Rogue Value={data} SetValue={setData} />

                <hr />

                <blockquote>
                <p>Great! You now have a deeper understanding of how and why you game. It also points towards how you grow more fully into yourself. As you work through Respawn, we’ll refer back to these types and utilize many of their more important characteristics.</p>
                </blockquote>

                <p>Now, take a few minutes to jot down some notes about how you identify with the archetypes. What was surprising? Did you think there’s a difference between how you game and how you are in your life?</p>


                <div className="field">
  <div className="control">
    <textarea onChange={handleChange} name='text' className="textarea" value={data.answers.text}></textarea>
  </div>
</div>

<button onClick={ClickSubmit} className="button is-primary has-text-weight-bold">
    Save Changes
</button>
<br />
{ui.saved ?
    <button onClick={FinishLesson} className="button is-primary has-text-weight-bold mt-4">
    Finish Lesson
</button>
:
null
}

            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                </div>

            </LessonLayout>
        </>
    )

}

export default GamerArchetypes;