import React, {useState, useContext, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faImage } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';



const Cover = ({CoverImg}) => {

    const [uploadBtn, setUploadBtn] = useState(false);

    
    useEffect(() => {

        if(CoverImg === null || CoverImg === undefined || CoverImg === ""){

            setUploadBtn(true);
            console.log(true);
    
        } 

    }, [])

    


    return (


        <>
        {
    uploadBtn ? 
    <div className="mb-6 has-text-centered mt-6">
        <p className="title">Welcome to your Personal Hub</p>
        <p className="subtitle">Lets customize your Hub, by uploading a Profile Picture and Cover Image.</p>
        <Link to='/settings' className="button is-primary">Upload</Link>
            <br/>
        <p className="help mt-4">To remove this message, upload a cover and profile picture</p>
 
    </div>
    :
    <div style={{backgroundImage: `url(${CoverImg || null})`}} className="cover-pic">



    </div>
}


</>

    )

}

export default Cover;