import React, {useContext, useEffect} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams} from 'react-router-dom';
import api from '../../../utils/api';

const Welcome = ({Day, Slug}) => {


let lesson = 1;
let match = useRouteMatch();
const title = `Welcome`;
let pars = useParams();

useEffect(() => {

    api.get('/lessons/start').then((res) => {
        console.log(res)
    }, (err) => {
        console.log(err)
    })


}, []);


const content = () => {
    return (

<>
<p>Hey! Welcome to Respawn. I&#39;m not sure how you found yourself here, but you are <em>here,</em> and we are glad that you are. Respawn is an opportunity to make a positive change in your life and that&#39;s pretty cool, so no matter, pat yourself on the back.</p>
<p>My name is Cam and growing up I loved to play video games. Starcraft (Zerg), CS 1.6 and World of Warcraft were my jams. Games were a fun place for me to escape to, they were a world where I didn&#39;t have to worry about anything else and I&#39;m a competitive person so I loved that aspect of them too.</p>
<p>I don&#39;t think gaming is inherently <em>bad</em>, but over the years — especially after I experienced intense bullying when I was 13 — they stopped being &#39;fun&#39; and started becoming a problem for me. I dropped out of school and played up to 16 hours a day. Long story short, I got to a point where I knew if I didn&#39;t stop gaming I would never do anything else with my life. I would never pursue my dream of being a DJ, or learn how to surf, or get a job and move out of my parent&#39;s house, or travel and see the world... I would just game and game and game.</p>
<p>So I made a decision to stop gaming and it&#39;s been about 10 years now — my life is in a better place than it once was. Maybe you feel similar about gaming and have other ambitions in life, or maybe you just want to figure out how to have a healthy relationship with gaming where it doesn&#39;t stop you from functioning as a normal human being. Ultimately, that&#39;s for you to decide and through Respawn we will guide you through it. </p>
<p>Speaking of we, I&#39;m not the only one here to help you! My partner on this adventure is Jamison Wiggins. He&#39;s a psychologist which is a fancy way of saying he understands the clinical underpinnings of psychology. Jamison is someone who leads by example in his tech use. He&#39;s offline by a specific time each night and a few weekends ago when I sent him a message he replied with a photo of his feet laying in a hammock in the middle of a forest in Thailand. Like me, he&#39;s not against technology, gaming, or being &#39;connected&#39; online, instead, he&#39;s fiercely passionate about living in harmony with it — using technology as the great tool it is, but not being used <em>by</em> it.</p>
<p>So that&#39;s a bit about me and Jamison, but there is one final partner on this adventure with us. YOU! As an Alpha tester, you are one of the first people to experience our new Respawn program and we can&#39;t thank you enough for your participation. You&#39;re helping us co-create a program that will be effective and useful for people around the world who need this help. If you come across any bugs, struggles, obstacles, epiphanies, wins, or other surprises, reach out. We want to hear from you! You can do that by messaging us directly using the chat feature on the bottom right or by sending us an email. We will get back to you asap.</p>
<p>So how does this program work exactly? It&#39;s a journey broken into three main stages, with each stage building on the last. The first stage is preparation. In it we learn more about your relationship to gaming, its history, and what you hope to achieve in the future.</p>
<p>Next, we invite you to take an intentional pause from gaming while refocusing your efforts on life in the physical world. We support you to build new habits, connect with purpose, and integrate new beliefs about yourself and the world.</p>
<p>Finally, we incorporate your learnings into a meaningful life. What a meaningful life means to you will be different than what it means to me, but the program will guide you to gain clarity on exactly that, while we navigate any challenges that come up along the way.</p>
<p>The program is built on the backbone of evidence-informed research, transformation best practices, and the lived experience of thousands of people just like yourself.</p>
<p>So that&#39;s a bit about the program and how it works. We don&#39;t want to spoil too much right now but you can feel confident knowing that this program is designed for you to learn, grow, and succeed. </p>
<p>As I wrap this introduction up, I want to share that it&#39;s common to be feeling a wide range of emotions right now. Maybe you’re excited about the new potential for your life, or maybe you&#39;re feeling uncertain and wondering whether you’ll be able to succeed <em>this time</em>, unlike other attempts you’ve made in the past. Whatever you are feeling at this moment I want you to know that it is ok. It&#39;s normal to experience a range of emotions during a transition and we are here to support you through it.</p>
<p>This journey may not be easy, but it&#39;s worth it.</p>
<p>Be proud of yourself. Your life is worth fighting for.</p>
<p>-Cam &amp; Jamison</p>
</>

    )
}


return (
<>
<LessonLayout Slug={Day} Title={title}>
   <div className="content content-text">
   {content()}
   </div>
   <div>
       <Link className="button is-primary has-text-weight-bold is-size-4" to="/lessons/evaluation">Proceed</Link>
   </div>
</LessonLayout>
</>
    )

}

export default Welcome
