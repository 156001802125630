import React, {useContext, useState, useEffect} from 'react';
import {LessonsContext} from '../context/LessonsContext';
import Loader from 'react-loader-spinner'
import {Link} from 'react-router-dom';
import api from '../utils/api';


const LessonsWidget = ({Admin, Agreed}) => {

    const {state, dispatch} = useContext(LessonsContext);

    console.log(state);

    const [lessons, setLessons] = useState(null);
    const [today, setToday] = useState(null);
    const [loading, setLoading] = useState(true);
    const [breakday, setBreakDay] = useState(false);
    const [breakTomorrow, setBreakTomorrow] = useState(false);
    console.log(Admin)

    const updateLessons = async() => {
       await api.get('/lessons/add');
    };

    const getLessons = async() => {

       await api.get('/lessons').then((res) => {
            let response = res.data;
            console.log(response)
            let count = response.length;
            

            if(count > 0){
                //DEBUG LESSONS LIST
                setLessons(response)
                // let less = response.filter((item) => { return new Date(item.available_from) < new Date()});
                // setLessons(less)




                // FIND TODAY's LESSON
                let fil = response.find((item) => { return new Date(item.available_from) < new Date()});
                
                console.log(fil)
                setToday(fil)
                
                if(fil.breakDay === true){
                    setBreakDay(true)
                };
                let today = fil.day;
                try{
                    let tmrw = response.find((item)=> { return item.day === today + 1});
                console.log(tmrw);
                if(tmrw.breakDay === true){
                    setBreakTomorrow(true)
                }
                } catch {

                }
                setLoading(false);
                //DEBUG FIL

                
            } else {
                setToday(state.find((item) => { return new Date(item.available_from) < new Date()}))
                setLessons(state)
                setLoading(false);
            }
        }, (err) => {
            alert(err)
        })

    };

    useEffect(() => {

        updateLessons();
        getLessons();

    }, [])




    return (
        <>
        {
            loading === true ?
            <>
        <Loader
        type="TailSpin"
        color="#e2574c"
        height={50}
        />
            </>
            :
            <>
                {
        today.completed === false && today.breakDay === false ?
        <h1 className="title">Today's Game Plan: <Link className="has-text-primary" to={`lessons/${today.slug}`}>{today.title}</Link></h1>
        :
        today.completed === true ?
        <>
            {
                breakTomorrow === true ?
                <>
                          <h1 className="subtitle is-size-5">
                You're done for today and tomorrow is a break day. <br /> We recommend to take the day off to be present and integrate your learnings so far into your life. Feel free to use the journaling area or emergency button. If you would still like to review past content, you can do so.
                </h1>
                </>
                :
                <h1 className="title is-size-4 has-text-weight-bold">
                You're done for today. Come back tomorrow for new content.
                </h1>
            }
        </>
        :
        today.breakDay === true?
        <>

      <p className="is-size-5 has-text-weight-bold">Today is break day. <br /> We recommend to take the day off to be present and integrate your learnings so far into your life. Feel free to use the journaling area or emergency button. If you would still like to review past content, you can do so.</p>


        </>
        :
        null

    }

        {
        Admin ?
        <>
            <hr/>
        <table className="table is-fullwidth">

        <thead>
            <tr>
                <th>Lesson</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            {
                lessons.length > 0 ?
                <>
                {lessons.map((item, index) => (
                   item.breakDay !== true ?
                   <tr key={index}>
                   <td><Link className="has-text-primary has-text-weight-bold is-size-5" to={`lessons/${item.slug}`}>{item.title}</Link></td>
                     <td>{item.completed === true ? <span className="tag is-success has-text-weight-bold">Complete</span> : <span className="tag is-primary has-text-weight-bold">Incomplete</span>}</td>
               </tr>
               :
               null
                ))}
                </>
            :
            null
            }
        </tbody>

    </table>
    </>
    :
    null
    }
    </>
    
            
        }
        </>
//         <>
// {
//     today !== null && loading !== true && today.breakDay !== true ?
//     <>
//     {
//         today.completed !== true ?
//         <h1 className="title">Today's Game Plan: <Link className="has-text-primary" to={`lessons/${today.slug}`}>{today.title}</Link></h1>
//         :
//         <>
//         <h1 className="subtitle has-text-weight-bold">
//         You're done for today. Come back tomorrow for new content.
//         </h1>
//         </>
//     }
  

       
//     </>

//         :
//         <Loader
//         type="TailSpin"
//         color="#e2574c"
//         height={50}
//      />
   
// }
//         </>
    )

}

export default LessonsWidget;