import React, {useState, useEffect} from 'react';

 const ProfilePic = ({ProfilePic}) => {
    const [showCover, setShowCover] = useState(true);

    useEffect(() => {

        if(ProfilePic === null || ProfilePic === undefined || ProfilePic === ""){

            setShowCover(false);
 
    
        } 

    }, [])
    return (
        <>
       {showCover ?
       <div className="profile-pic" style={{backgroundImage: `url(${ProfilePic})`}}>

</div>
:
null}
        </>
    )
}

export default ProfilePic ;
