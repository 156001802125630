import React, { useEffect, useState, useContext } from 'react';
import { JournalContext } from '../context/JournalContext';
import api from '../utils/api';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import * as rax from 'retry-axios';

const JournalTable = () => {

    const { state, dispatch } = useContext(JournalContext);

    const getData = async () => {
        const interceptorId = rax.attach();
        let call = await api.get('/journal').then((res) => {
            return res.data
        }, (err) => {
            return null
        });

        return call

    };


    useEffect(() => {

        getData().then((res) => {
            dispatch({
                type: 'GETENTRIES',
                payload: res
            })
        }, (err) => {
            console.log(err)
        });
    }, []);

    

    return (
        <>
            {
                state.isLoading ?
                    <>
                        loading...
                    </>
                    :
                    <>

                    {
                        state.journals ?
                        <>
                                                {state.journals.length > 0 ?
                            <>
                 
                                <table className="table is-fullwidth is-striped">

                                    <thead>
                                        <tr>
                                            <th>Entry</th>
                                            <th>Last modified</th>
                                            <th>Shared</th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            state.journals.map((item, index) => (
                                                <tr key={index} className="is-size-6">
                                                    <td><Link className="has-text-primary has-text-weight-bold is-size-5" to={`/journal/edit/${item.key}`}>{item.title}</Link></td>
                                                    <td>{moment(item.timestamp).format('l')}</td>
                                                    <td>{item.public === false ? `Private` : `Public`}</td>
                                                    {/* <td><Link to={`/journal/edit/${item.key}`} className='pointer tag is-link'>Edit</Link> <span className='pointer tag is-primary ml-1'>Delete</span></td> */}

                                                    {/* <td><Link to={`/journal/edit/${item.key}`}>Edit</Link></td> */}
                                                </tr>

                                            ))
                                        }

                                    </tbody>

                                </table>
                            </>
                            :
                            <>
                                <div className="has-text-centered mt-6">
                                    <p className="title">You don't have any journals yet.</p>
                                    <Link to={`/journal/edit/${uuidv4()}`} className="button is-primary">Start writing</Link>
                                </div>
                            </>
                        }
                        </>
                        :
                        <>
                        </>
                    }

                    </>
            }
        </>
    )
}

export default JournalTable