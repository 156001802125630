// Just a starter for each lesson

import React, { useContext, useEffect, useState } from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import api from '../../../utils/api';


const Goals = ({ Slug, Day }) => {
    const history = useHistory();
    const title = 'Goals';

    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false,
        clickedGoal: false
    });

    const caseStudies = [
        {
            'url':`https://gamequitters.com/case-study-andrew/`,
            'title': `The EPIC Story of Andrew, a 17 Year Old Who Quit Gaming, Found His Dream Job, and Traveled the World.`
        },
        {
            'url':`https://gamequitters.com/body-transformation-six-pack/`,
            'title': `Body Transformation | Overweight to 6-Pack After Quitting Gaming`
        },
        {
            'url':`https://gamequitters.com/case-study-gerard/`,
            'title': `From Gaming for Hours Everyday to Skydiving, and 65,000 Subscribers on YouTube: Gerard’s Story.`
        },
        {
            'url':`https://gamequitters.com/444-days-without-gaming-discovered-new-passion-music-released-first-ep/`,
            'title': `444 Days Without Gaming: Discovered a New Passion for Music, and Released My First EP!`
        },
    ]

    const SMARTDEFAULT = [
        {
            'selected': false,
            'key': "Goal 1",
            'title': "Play less video games so I can spend time to get a job that pays a good salary",
        },
        {
            'selected': false,
            'key': "Goal 2",
            'title': "Complete proper flip turns at my next swim meet. I will complete five correct flip turns at each practice and my coach will keep count. I already do two flip turns during practice, so I can reach this goal.",
        },
        {
            'selected': false,
            'key': "Goal 3",
            'title': "Get into a University program of my choice. It is something I've wanted since I was a child.",
        },
        {
            'selected': false,
            'key': "Goal 4",
            'title': "Get through this program. This is a prerequisite for my eventual success and I want to be successful.",
        }

    ]

    const SMART = [
        {
            'selected': false,
            'key': "Goal 1",
            'title': "Play less video games so I can spend time to get a job that pays a good salary",
        },
        {
            'selected': false,
            'key': "Goal 2",
            'title': "Complete proper flip turns at my next swim meet. I will complete five correct flip turns at each practice and my coach will keep count. I already do two flip turns during practice, so I can reach this goal.",
        },
        {
            'selected': false,
            'key': "Goal 3",
            'title': "Get into a University program of my choice. It is something I've wanted since I was a child.",
        },
        {
            'selected': false,
            'key': "Goal 4",
            'title': "Get through this program. This is a prerequisite for my eventual success and I want to be successful.",
        }

    ]

    const journalboxes = [
        {
            'title': `Pick one of the non-SMART goals from the previous question and transform it into a smart goal. Your answer doesn’t have to match exactly, but it should have all the components of a SMART goal.`,
            'value': ''
        },
        {
            'title': `Spend a few minutes writing down as many goals as you can, including goals for your life, career, and health goals. While brainstorming, just think of the positives. Just get all your ideas down, written in any order. Remember, your growth mindset! If you believe in something and are willing to work towards it, you can achieve any goal!`,
            'value': ''
        },
        {
            'title': `Order your goals from above in order of least important to most important below:`,
            'value': ''
        },
        {
            'title': `From the list you made in the last question, select three goals related to your journey with Respawn and paste them below.`,
            'value': ''
        },
        {
            'title': `Expand on each of these goals and transform each of them into a SMART goal:`,
            'value': ''
        },
    ];

    const [data, setData] = useState({
        answers: {
            smart: SMART,
            correct: true,
            selected: null,
            journals: journalboxes
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
            setUi({
                ...ui,
                saved: res.data.completed
            })
        }, (err) => {
            console.log(err)
        });
    }, []);


    const goalBtn = (e) => {
        setUi({
            ...ui,
            clickedGoal: true,
            saved: false
        });
        let index = parseInt(e.target.dataset.idx);
        let goal = parseInt(e.target.dataset.goal);

        if (goal === 2) {
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    correct: true,
                    selected: index
                }
            })
        } else {
            setData({
                ...data,
                answers: {
                    ...data.answers,
                    correct: false,
                    selected: index
                }
            })
        }
    }



    const handleChange = (e) => {
        setUi({
            ...ui,
            saved: false
        })
        let index = e.target.dataset.idx;
        let value = e.target.value;
        let newArr = [...data.answers.journals]
        let current = newArr[index];
        current.value = value;

        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: newArr
            }
        })
    };



    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if (done === false) {

            let complete = {
                id: id,
                completed: true,
                timestamp: new Date()
            }
            console.log(complete);

            api.post(`/lessons/complete`, complete).then((res) => {
                console.log(res)
                history.push('/lessons/done')

            }, (err) => {
                console.log(err)
            });


        } else {
            console.log('ok');
            history.push('/lessons/done')
        };

    }


    const content = () => {

        return (
            <>
                <p>Yesterday you uncovered your motivation to take a break from gaming, so today we are going to set clear and achievable goals for your break.</p>
                <p>The purpose of taking a break from gaming isn&#39;t just to &#39;stop gaming&#39;, but to discover more about yourself and focus on other areas of your life that you may have been neglecting due to gaming. Identifying these areas as you have done in <strong>Life Assessment</strong> is an important step, and now we want to make our insights focused, structured, and actionable.</p>
                <blockquote>
                    <p>“Most people overestimate what they can do in a day, and underestimate what they can do in a month. We overestimate what we can do in a year, and underestimate what we can accomplish in a decade.&quot;</p>
                </blockquote>
                <p>It&#39;s easy to overcomplicate goal-setting. We either set too many goals or the wrong kind. So what&#39;s the right type of goal?</p>
                <h2 id="s-m-a-r-t-goals">S.M.A.R.T Goals</h2>
                <ul>
                    <li><strong>S = Specific</strong></li>
                    <li><strong>M = Measurable</strong></li>
                    <li><strong>A = Attainable</strong></li>
                    <li><strong>R = Realistic</strong></li>
                    <li><strong>T = Timely</strong></li>
                </ul>
                <h3 id="-specific-"><strong>Specific</strong></h3>
                <p>Goals should be clear, not vague. Being specific means:</p>
                <ul>
                    <li>What are you doing to do?</li>
                    <li>Why is this important?</li>
                    <li>How are you doing to do it (by when?)</li>
                </ul>
                <p>An example would be: Instead of setting a goal to lose weight or be healthier, set a specific goal to lose 2cm off your waistline or to complete a half marathon run.</p>
                <h3 id="measurable">Measurable</h3>
                <p>Choose a goal with measurable progress, so you can see the change occur. It&#39;s similar to why you often like to play video games: you see a scoreboard or your character <em>level up</em>.</p>
                <p>An example would be “I will learn these three songs on my guitar” instead of &quot;I will learn guitar&quot; which is not as measurable.</p>
                <p>When you measure your progress, it&#39;s easier to stay on track, and you experience the victory of achievement. Remember, &quot;what gets measured, gets managed.&quot;</p>
                <h3 id="-attainable-"><strong>Attainable</strong></h3>
                <p>When you identify goals that are most important to you, you begin to figure out ways you can make them come true. You develop that attitudes, abilities, skills, and financial capacity to reach them.</p>
                <p>If your goal is too far out of reach, you will lose motivated to pursue it. A goal needs to push you outside your comfort zone while remaining attainable. For instance, if you aim to lose 20lbs in one week, we all know that isn’t achievable. But setting a goal to loose 1lb and when you’ve achieved that, aiming to lose a further 1lb, will keep it achievable for you.</p>
                <p>Achieving smaller goals provides the motivation you need over time to achieve bigger ones.</p>
                <h3 id="-realistic-"><strong>Realistic</strong></h3>
                <p>Realistic means it&#39;s do-able. It may be challenging and take effort, but through consistent effort you can get there. The goal needs to be realistic for where you are at the moment.</p>
                <p>For example, committing to taking a break from gaming for 30 days instead of <em>never playing games ever again in your entire life.</em> Or performing the three songs you will learn on guitar at an open mic night in your city instead of going on a world tour with a band.</p>
                <p>Set goals that you can achieve with effort, and which lead you to larger goals over time. Make them difficult enough that you feel a sense of satisfaction when accomplished, but not so difficult you lose hope of ever being successful.</p>
                <h3 id="-timely-"><strong>Timely</strong></h3>
                <p>Goals need accountability. Set a deadline to accomplish your goal. We recommend to start with the next 30 days. And remember, goals can always change as well. I know mine have and continue to all the time. The key isn’t to identify the one single goal you have right now but really just three goals you’re excited about. Start there, you can always change them later.</p>
                <p>Now it&#39;s your turn:</p>

                <h3>Which goal is S.M.A.R.T.?</h3>

                <div>
                    {data.answers.smart.map((item, index) => (
                        <div key={index}>
                            <p className="pt-4"><button onClick={goalBtn} data-idx={index} data-goal={index + 1} className={`button is-primary has-text-weight-bold ${index === data.answers.selected ? `` : `is-outlined`}`}>Goal {index + 1}</button> <span>{item.title}</span></p>
                        </div>
                    ))}
                </div>

                {
                    ui.clickedGoal ?
                        <>
                            <div className="notification is-primary has-text-weight-bold mt-4">
                                {
                                    data.answers.correct === true ?
                                        <div >
                                            <p>That is correct! Good job!</p>
                                        </div>
                                        :
                                        <p>Oops, that is incorrect, please try again.</p>
                                }

                            </div>
                        </>
                        :
                        <>
                            {
                                data.answers.correct === true ?
                                    <div className="notification is-primary has-text-weight-bold mt-4">
                                        <p>That is correct! Good job!</p>
                                    </div>
                                    : null

                            }
                        </>
                }

                {
                    data.answers.correct === true ?
                        <>
                            {
                                data.answers.journals.map((item, index) => (
                                    <div key={index} className="field">
                                        <label className="label is-size-5">{item.title}</label>
                                        <div className="control">
                                            <textarea onChange={handleChange} data-idx={index} className="textarea" value={item.value}></textarea>
                                        </div>
                                    </div>
                                ))
                            }
                            <>
                                <button disabled={ui.saved} onClick={ClickSubmit} className="button is-primary">Save Changes</button>
                                <br />
                                {ui.saved ?
                                    <>
                                    <hr />
                                        <p>Great job. Now that you have identified your goals, you will want to spend time considering what your next steps are. Is that to schedule time each day to work on your goal(s)? How often will you check-in on your progress?

                                        We recommend to purchase a whiteboard for your room if you don't have one already. Write your goals on your whiteboard so you can see it each day. And you can track the action steps you need to take.

As we wrap up today, here are a few case studies of other people who have achieved new goals after they stopped gaming.</p>

<blockquote>
{
    caseStudies.map((item, index) => (
        <div className="mt-2 mb-2" key={index}>
            <p><a className="has-text-weight-bold has-text-primary" href={item.url} target="_blank">{item.title}</a></p>
        </div>
    ))
}
</blockquote>



                                        <button onClick={FinishLesson} className="button is-primary mt-2">Finish Lesson</button>
                                    </>
                                    :
                                    null
                                }
                            </>

                        </>
                        : null

                }




            </>
        )
    }

    return (
        <>
            <LessonLayout Slug={Day} Title={title}>

                <div className="content content-text">
                    {content()}

                </div>

            </LessonLayout>
        </>
    )

}

export default Goals;