// Just a starter for each lesson

import React, {useContext, useEffect, useState} from "react";
import Helmet from 'react-helmet';
import LessonLayout from '../../../components/LessonLayout';
import {Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import JournalEditor from '../../../components/JournalEditor';
import api from '../../../utils/api';
import Study from './studyData';


const BenefitsOfNotGaming = ({Slug, Day}) => {
    const history = useHistory();
    const title = 'Benefits of Not Gaming';


    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(true);
    const [id, setId] = useState(null);
    const [ui, setUi] = useState({
        saved: false,
        allowFinish: false
    });

    const [data, setData] = useState({
        answers: {
            journals: 
                {
                    benefits: '',
                    cost: ''
                },
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`/lessons/answers/${Slug}`).then((res) => {
            console.log(res)
            if (res.data.answers) {
                setData({
                    ...data,
                    answers: res.data.answers
                })
            }
        }, (err) => {
            console.log(err)
        });
        api.get(`/lessons/update/${Day}`).then((res) => {
            console.log(res)
            setId(res.data._id)
            setDone(res.data.completed)
          }, (err) => {
            console.log(err)
          });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            answers: {
                ...data.answers,
                journals: {
                    ...data.answers.journals,
                    [e.target.name]: e.target.value
                }
            }
        })
    };

    const ClickSubmit = async () => {


        setIsLoading(true);


        let answers = {
            answers: data.answers
        }

        await api.put(`/lessons/answers/${Slug}`, answers).then((res) => {
            console.log(res)
            setIsLoading(false);
            setUi({
                ...ui,
                saved: true,
                allowFinish: true
            });
        }, (err) => {
            console.log(err)
        });

    }

    const FinishLesson = () => {

        if(done === false){

            let complete = {
              id: id,
              completed: true,
              timestamp: new Date()
            }
            console.log(complete);
        
            api.post(`/lessons/complete`, complete).then((res) => {
              console.log(res)
                history.push('/lessons/done')
        
            }, (err) => {
              console.log(err)
            });
            
        
          } else {
              console.log('ok');
            history.push('/lessons/done')
          };

    }


    const content = () => {

        return (
            <>
            <p>Welcome back to Respawn.</p>
<p>Does taking a break from gaming improve your life? We wanted to know so we asked 300 gamers to take a break from gaming for 90 days and then evaluated the impact on their quality of life.</p>
<h2 className="has-text-weight-bold">Results of our study:</h2>
<div className="columns is-centered is-multiline">
{
    Study.map((item, index) => (

            <div key={index} className="column is-4">
                <div className="has-text-centered">
                <h1 className="has-text-weight-bold is-size-3 has-text-success">
       {item.percentage}
      </h1>
      <p className="is-size-1">{item.emojis}</p>
      <h2 className="is-size-5">
        {item.title}
      </h2>
      </div>
    </div>

    ))
}
</div>
<h2 id="what-we-discovered">What we discovered</h2>
<p>Those who completed a 90-day break from gaming saw a 2x improvement to their overall well-being. This was evaluated across 12 different areas including emotional health, physical health, time management, their ability to focus, their relationships, and even their appearance. I joke that if you stop gaming for 90 days you will <em>literally</em> become better looking. It certainly worked for me. 🤪</p>
<p>Joking aside, it makes sense, right? When you&#39;re not gaming you&#39;re more likely to be outside, getting fresh air, exercising, eating better, and spending more time with friends in-person - all conditions that will optimize your health and well-being.</p>
<p>Consider when you <em>are</em> gaming: you&#39;re more likely to be isolated in a room, with no sunlight, sedentary in a chair eating junk food and staring at a screen all day. No wonder after a long gaming session you look in the mirror and wonder how you&#39;ve suddenly transformed into a real-life zombie.</p>
<p>Whether you find gaming is a huge problem for you or just a hobby you want to keep in check, taking a break is beneficial. You may not need to quit forever, but a break can be helpful. Although our study asked participants to take a break for 90 days, as we shared in <em>Expectations</em>, during Respawn we will invite you to take a 30-day break, which is the period where participants tend to experience the most benefit, and going back to gaming after the break is always an option.</p>
<h2 id="case-study-jaroslaw">Case Study: Jaroslaw</h2>
<p>Jaroslaw is a former gamer from Canada who began gaming at the age of 5 with Final Fantasy 1 and Star Tropics. He liked to play because he experienced a sense of achievement and games helped him escape from reality — he wasn&#39;t happy and games allowed him to drown his sorrows.</p>
<p>Unfortunately, 6 years ago he discovered he had a problem:</p>
<blockquote>
<p>I was so miserable and felt like, with all that I had suffered through, the world owed me something. I decided I didn’t like the direction my life was going and knew I needed to do something about it. When I used to play video games, I’d wake up feeling groggy because I didn’t get nearly enough sleep and go to school or work. I’d usually come home and immediately play games until 2 or 3 am, if not later. I would only take breaks to go to the washroom or eat – the basics. My relationships with my family were not good. I hardly had anything to talk about with any of my extended family and I had no social circle whatsoever. My health was deteriorating, both in terms of my weight and my ability to do basic tasks. To top it off my finances were a complete mess.</p>
</blockquote>
<p>He decided he couldn&#39;t continue to go on like this and committed to quit for 90 days. &quot;Just to try it.&quot;</p>
<p>After work he would eat a healthy dinner and spend time working out at the gym, reading, hiking, hanging out with friends and watching Netflix. He focused on being more willing to try new things, improving his social skills and feeling more confident in himself with visualization. The time he used to spend gaming was now spent on the things that mattered most, his family and his goals.</p>
<blockquote>
<p>&quot;Pretty much every conceivable thing that could be better, is better. As long as you don’t give up on yourself, you always have a chance to succeed.”</p>
</blockquote>
<h2 id="experiencing-it-for-yourself">Experiencing it for yourself</h2>
<p>Jaroslaw is one example of many who have found improvement in their life from either quitting gaming or at least taking a break. As we&#39;ve shared before, every person is different and what will work best for you may be different than what worked for Jaroslaw. But taking a break from gaming will give you a chance to experience it for yourself, and that will provide important information for you to make a more informed decision on the type of relationship you want to have with gaming.</p>
<p>You don&#39;t have to quit forever, but giving yourself a break can help. 30 days isn&#39;t a lot of time in the grand scheme of life and if you haven&#39;t spent time away from gaming before (or for a long time) it can be beneficial to experience something new.</p>
<p>Ultimately, you have to consider your reasons for taking a break from gaming which we will discuss in more depth in an upcoming day.</p>
<p><strong>Complete the following journaling exercise:</strong></p>
<div className="field">
  <label className="label is-size-4">What benefits are you hoping to receive by taking a break from gaming for 30 days?</label>
  <div className="control">
  <textarea id="0" onChange={handleChange} name='benefits' data-title="What benefits are you hoping to receive by taking a break from gaming for 30 days?" className="textarea" value={data.answers.journals.benefits}></textarea>
  </div>
</div>

<div className="field">
  <label className="label is-size-4">What is gaming costing you?</label>
  <div className="control">
  <textarea id="1" onChange={handleChange} name='cost' data-title="What is gaming costing you?" className="textarea" value={data.answers.journals.cost}></textarea>
  </div>
</div>



            </>
        )
    }

    return (
        <>
        <LessonLayout Slug={Day} Title={title}>

        <div className="content content-text">
        {content()}

        {
            data.answers.journals.benefits.length && data.answers.journals.cost.length > 10?
            <>

          {
              ui.saved === false ?
              <button onClick={ClickSubmit} className="button is-primary has-text-weight-bold">Save Changes</button>
              :
                <>
                      <blockquote>
                    <p>Great job! Tomorrow is a break day. Use it as an opportunity to reflect on what you've learned so far and rest for the days to come. </p>
                </blockquote> 
                       <button onClick={FinishLesson} className="button is-primary has-text-weight-bold">Finish Lesson</button>
                </>
          }


     

            </>
            :
            <>
            <p className="help">Write at least one sentence in each textbox to continue</p>
            </>
        }

    </div>

        </LessonLayout>
        </>
    )

}

export default BenefitsOfNotGaming;